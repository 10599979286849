export const getNextSlideIndex = (channel, areaIndex, slideIndex) => {
  //start loop over if at the end of this area's slides
  return slideIndex + 1 > channel.selectedSlides[areaIndex].SlideIDs.length - 1
    ? 0
    : slideIndex + 1;
};

export const getSlideIndexSynchronized = (channel, areaIndex, time) => {
  //A JavaScript date is fundamentally specified as the number of milliseconds that have elapsed since midnight on January 1, 1970, UTC.
  //get the slide index that should be showing if the slides started playing at that time.
  let slides = channel.selectedSlides[areaIndex].SlideIDs;
  let totalDuration = 0;
  for (let slide of slides) {
    slide.startTime = totalDuration;
    slide.endTime = totalDuration + slide.duration;
    totalDuration += slide.duration;
  }
  //console.log(slides);
  //console.log(`Total duration of the slides in this area: ${totalDuration}.`);
  let secondsIntoLoop = (time / 1000) % totalDuration;
  //console.log(`We should be ${secondsIntoLoop} seconds into this loop.`);

  for (let i = 0; i < slides.length; i++) {
    if (
      slides[i].startTime <= secondsIntoLoop &&
      slides[i].endTime >= secondsIntoLoop
    ) {
      return i;
    }
  }
};

//map the numbers in the database to something more readable
export const slideTypes = {
  rte: { index: 0, friendlyName: "Rich Text" }, //done?
  weather: { index: 1, friendlyName: "Weather" }, //done?
  image: { index: 2, friendlyName: "Image" }, //done?
  rss: { index: 3, friendlyName: "RSS" }, //done except get content from URL type which isn't in use
  flash: { index: 4, friendlyName: "Flash" }, //done
  video: { index: 5, friendlyName: "Video" }, //done
  interestrate: { index: 6, friendlyName: "Interest Rate" }, //done
  time: { index: 7, friendlyName: "Time" }, //done
  template: { index: 8, friendlyName: "Template" }, //done
  wpRss: { index: 9, friendlyName: "Ve-Branch XML" }, //done except for non-main content types that haven't been used so I have nothing to compare them to
  animated: { index: 10, friendlyName: "Animated" },
  new: { index: 11, friendlyName: "Unknown" },
};

export const getFriendlyName = (typeIn) => {
  return Object.values(slideTypes).find((type) => type.index === typeIn)
    .friendlyName;
};
