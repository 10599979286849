import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import GlobalProperties from "./GlobalProperties";
import ImageProperties from "./ImageProperties";
import RTEProperties from "./RTEProperties";
import WeatherProperties from "./WeatherProperties";
import RSSProperties from "./RSSProperties";
import VideoProperties from "./VideoProperties";
import InterestRateProperties from "./InterestRateProperties";
import TimeProperties from "./TimeProperties";
import { slideTypes } from "../../utils/slideHelpers";
import TemplateProperties from "./TemplateProperties";
import WpRssProperties from "./WpRssProperties";
import axios from "axios";

function EditSlide(props) {
  const auth = useSelector((state) => state.auth);
  const selectedArea = useSelector((state) => state.selection.area);
  const selectedSlide = useSelector((state) => state.selection.slide);

  const selectedPortal = useSelector(
    (state) => state.selection.portal || auth.user.PortalId
  );
  const tempType = useSelector((state) => state.selection.slideType); //can't call useSelector conditionally

  const selectedType = selectedSlide
    ? Object.values(slideTypes).find((type) => {
        return type.index === selectedSlide.type;
      })
    : tempType;
  const defaultSlide = {
    type: selectedType ? selectedType.index : null,
    PortalId: auth.user.isSuperUser ? selectedPortal : auth.user.PortalId,
    video_html5: selectedType === slideTypes.video ? true : undefined,
  };

  if (selectedSlide && !selectedSlide.PortalId) {
    selectedSlide.PortalId = defaultSlide.PortalId;
  }

  const [slide, setSlide] = useState(
    selectedSlide ? selectedSlide : defaultSlide
  );
  const [loading, setLoading] = useState(false);
  const [goBackAfterSave, setGoBackAfterSave] = useState(false);
  const [addAnotherAfterSave, setAddAnotherAfterSave] = useState(false);
  const [message, setMessage] = useState("");
  const [validationErrors, setValidationErrors] = useState([]);

  const [slidePropertiesComponent, setSlidePropertiesComponent] =
    useState(null);

  const goBack = useCallback(() => {
    props.history.push("/dashboard");
  }, [props.history]);

  useEffect(() => {
    const addAnother = () => {
      //setSlide(defaultSlide);
      console.log("Trying to add another slide based on...");
      console.log(slide);
      setSlide({ ...slide, _id: undefined });
      setAddAnotherAfterSave(false);
    };
    if (!loading && validationErrors.length === 0) {
      if (goBackAfterSave) goBack();
      if (addAnotherAfterSave) addAnother();
    }
  }, [loading, addAnotherAfterSave, goBack, goBackAfterSave, defaultSlide]);

  const updateSlide = useCallback(
    (property, value) => {
      console.log("Updating slide...");
      console.log(slide);
      let newSlide = { ...slide };
      newSlide[property] = value;
      console.log(newSlide);
      setSlide(newSlide);
    },
    [slide]
  );

  const scrollToTop = () =>
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  const saveSlide = () => {
    const validationErrors = getValidationErrors();
    if (validationErrors.length === 0) {
      setValidationErrors([]);
      setLoading(true);
      if (slide._id) {
        axios
          .patch("api/slides/updateSlide", {
            _id: slide._id,
            PortalId: slide.PortalId,
            update: {
              ...slide,
            },
          })
          .then((res) => {
            setLoading(false);
            setMessage(`Slide "${slide.name}" successfully updated.`);
            scrollToTop();
            if (addAnotherAfterSave) console.log("TODO");
          });
      } else {
        axios
          .post("api/slides/add", { area: selectedArea, slide })
          .then((res) => {
            setSlide(res.data);
            setLoading(false);
            setMessage(
              `Slide "${slide.name}" successfully saved. ${
                addAnotherAfterSave
                  ? `NOTE: You are now editing a new slide, with fields filled out based on the previous slide to save you time.`
                  : ``
              }`
            );
            if (goBackAfterSave) goBack();
            if (addAnotherAfterSave) console.log("TODO");
          });
      }
    } else {
      setValidationErrors(validationErrors);
      scrollToTop();
    }
  };

  const getValidationErrors = () => {
    let validationErrors = [];
    if (!slide.name) validationErrors.push("The slide needs a name.");
    if (!slide.duration) validationErrors.push("The slide needs a duration.");
    if (
      (!slide.scheduleStart && !slide.scheduleEnd && !slide.alwaysDisplay) ||
      (slide.scheduleStart &&
        !slide.scheduleEnd &&
        !slide.alwaysDisplayAfterStartDate)
    )
      validationErrors.push(
        "You must fill in start/end date, check 'always display', or fill in start date and check 'display from start date'."
      );
    if (slide.type === slideTypes.image.index && !slide.image_path) {
      validationErrors.push("You must upload an image.");
    }
    if (slide.type === slideTypes.video.index && !slide.video_path) {
      validationErrors.push("You must upload a video.");
    }
    return validationErrors;
  };

  useEffect(() => {
    if (selectedType) {
      switch (selectedType.index) {
        case slideTypes.image.index:
          setSlidePropertiesComponent(
            <ImageProperties
              slide={slide}
              updateSlide={updateSlide}
              setMessage={setMessage}
            />
          );
          break;
        case slideTypes.rte.index:
          setSlidePropertiesComponent(
            <RTEProperties slide={slide} updateSlide={updateSlide} />
          );
          break;
        case slideTypes.weather.index:
          setSlidePropertiesComponent(
            <WeatherProperties slide={slide} updateSlide={updateSlide} />
          );
          break;
        case slideTypes.rss.index:
          setSlidePropertiesComponent(
            <RSSProperties
              slide={slide}
              updateSlide={updateSlide}
              setSlide={setSlide}
            />
          );
          break;
        case slideTypes.flash.index:
          setSlidePropertiesComponent(
            <div>
              <div className="addslidesectiontopbar">Content</div>
              <div className="addslidesection">
                <span>
                  Flash is{" "}
                  <a href="https://www.adobe.com/ca/products/flashplayer/end-of-life.html">
                    no longer supported
                  </a>
                  , so Flash slides are no longer supported.
                </span>
              </div>
            </div>
          );
          break;
        case slideTypes.video.index:
          setSlidePropertiesComponent(
            <VideoProperties
              slide={slide}
              updateSlide={updateSlide}
              setMessage={setMessage}
            />
          );
          break;
        case slideTypes.interestrate.index:
          setSlidePropertiesComponent(
            <InterestRateProperties
              slide={slide}
              updateSlide={updateSlide}
              setMessage={setMessage}
              setSlide={setSlide}
            />
          );
          break;
        case slideTypes.time.index:
          setSlidePropertiesComponent(
            <TimeProperties
              slide={slide}
              updateSlide={updateSlide}
              setMessage={setMessage}
              setSlide={setSlide}
            />
          );
          break;
        case slideTypes.template.index:
          setSlidePropertiesComponent(
            <TemplateProperties
              slide={slide}
              updateSlide={updateSlide}
              setMessage={setMessage}
              setSlide={setSlide}
            />
          );
          break;
        case slideTypes.wpRss.index:
          setSlidePropertiesComponent(
            <WpRssProperties
              slide={slide}
              updateSlide={updateSlide}
              setMessage={setMessage}
              setSlide={setSlide}
            />
          );
          break;
        default:
          break;
      }
    }
  }, [selectedType, slide, updateSlide]);

  return selectedArea && selectedType ? (
    <div>
      <h3>
        <span id="lblSlideType" className="addslide_header">
          {selectedSlide ? "Edit" : "Create"} {selectedType.friendlyName} slide
        </span>
      </h3>
      <span id="lblUploadMessage" style={{ color: "#F00" }}>
        {message}
      </span>
      {validationErrors.length > 0 ? (
        <div className="validationErrors">
          <img
            alt=""
            id="ValidationImage"
            src="/img/exclamation-point-icon.jpg"
            style={{ borderWidth: "0px", width: "64px" }}
          ></img>
          <span
            className="addslide_header"
            style={{
              verticalAlign: "top",
              position: "relative",
              top: "12px",
              left: "12px",
            }}
          >
            There were some validation errors with your slide:
          </span>
          <ul>
            {validationErrors.map((item, idx) => {
              return <li key={idx}>{item}</li>;
            })}
          </ul>
        </div>
      ) : null}
      <GlobalProperties
        slide={slide}
        updateSlide={updateSlide}
      ></GlobalProperties>
      {slidePropertiesComponent}
      <input
        className="button_lightred"
        type="button"
        onClick={() => saveSlide()}
        value="Save Slide"
      />

      <input
        className="button_lightred"
        type="button"
        onClick={() => {
          setGoBackAfterSave(true);
          saveSlide();
        }}
        value="Save Slide and go back"
      />

      <input
        className="button_lightred"
        type="button"
        onClick={() => {
          setAddAnotherAfterSave(true);
          saveSlide();
        }}
        value="Save & add another"
      />

      <input
        className="button_lightred"
        type="button"
        onClick={() => goBack()}
        value="Cancel"
      />
    </div>
  ) : (
    <Redirect to="/dashboard"></Redirect>
  );
}

export default EditSlide;
