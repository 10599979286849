import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import {
  GET_ERRORS,
  SET_CURRENT_USER,
  USER_LOADING,
  AUTHENTICATION_PASSWORD_RESET_HASH_CREATED,
  AUTHENTICATION_PASSWORD_RESET_HASH_FAILURE,
} from "./types"; // Register User
export const registerUser = (userData, history) => (dispatch) => {
  axios
    .post("/api/users/register", userData)
    .then((res) => history.push("/login")) // re-direct to login on successful register
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
}; // Login - get user token
export const loginUser = (userData) => (dispatch) => {
  axios
    .post("/api/users/login", userData)
    .then((res) => {
      // Save to localStorage// Set token to localStorage
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
}; // Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
}; // User loading
export const setUserLoading = () => {
  return {
    type: USER_LOADING,
  };
}; // Log user out
export const logoutUser = () => (dispatch) => {
  // Remove token from local storage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};

export const passwordResetHashCreate = () => {
  return { type: AUTHENTICATION_PASSWORD_RESET_HASH_CREATED };
};
export const passwordResetHashFailure = (error) => {
  return { type: AUTHENTICATION_PASSWORD_RESET_HASH_FAILURE, payload: error };
};

export const createHash = (userName) => (dispatch) => {
  var getUrl = window.location;
  var baseURL = getUrl.protocol + "//" + getUrl.host;
  axios
    .post("/api/users/saveresethash", {
      userName: userName,
      baseURL,
    })
    .then((res) => {
      console.log(res);
      if (res.data.success) {
        return dispatch(passwordResetHashCreate());
      } else {
        return dispatch(
          passwordResetHashFailure("Something went wrong. Please try again.")
        );
      }
    })
    .catch((error) => dispatch(passwordResetHashFailure(error)));
};
