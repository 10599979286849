import React, { useState, useEffect, useCallback } from "react";
import ContentLibraryHeader from "./ContentLibraryHeader";
import axios from "axios";
import { Link } from "react-router-dom";
import ContentLibraryHome from "./ContentLibraryHome";
import * as QueryString from "query-string";

function ContentLibrary(props) {
  const params = QueryString.parse(props.location.search);

  const categoryID = params.category;
  const [category, setCategory] = useState(null);
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [content, setContent] = useState([]);
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const perPageOptions = [5, 10, 15, 20, 50, "All"];

  useEffect(() => {
    var cont = [];
    if (categoryID) {
      axios.post("/api/categories/findOne", { _id: categoryID }).then((res) => {
        setCategory(res.data);
      });
    } else setCategory(null);

    axios
      .post("/api/images/find", categoryID ? { category: categoryID } : null)
      .then((res) => {
        setImages(res.data);
        setPage(0);

        //keep track of whether the content is image or video even though we're adding them to the same array for display purposes.
        res.data.forEach((element, index) => {
          res.data[index].type = "image";
        });

        cont = res.data;

        axios
          .post(
            "/api/videos/find",
            categoryID ? { category: categoryID } : null
          )
          .then((res) => {
            setVideos(res.data);

            res.data.forEach((element, index) => {
              res.data[index].type = "video";
            });

            cont.push(...res.data);
            console.log(cont);
            setContent(cont);
          });
      });
  }, [categoryID]);

  const getVisibleContent = useCallback(() => {
    if (perPage === -1) return content;

    const start = 0 + perPage * page;
    const end = perPage + perPage * page;
    return content
      .map((content, idx) => {
        if (idx === 0) content.first = true;
        if (idx === content.length - 1) content.last = true;
        return content;
      })
      .slice(start, end);
  }, [perPage, page, content]);

  const getPageLinks = () => {
    var pages = [];

    for (var i = 0; i < Math.ceil(images.length / perPage); i++) {
      pages.push(i);
    }

    const firstPage = 0;
    const lastPage = perPage === -1 ? 0 : pages[pages.length - 1];
    return (
      <div style={{ textAlign: "center", fontSize: "18px" }}>
        <div>
          Display Num{" "}
          <select
            value={perPage}
            onChange={(e) => {
              setPerPage(parseInt(e.target.value));
            }}
          >
            {perPageOptions.map((option, idx) => (
              <option key={idx} value={option === "All" ? -1 : option}>
                {option}
              </option>
            ))}
          </select>
          Page {page + 1} of {lastPage + 1}
        </div>
        {perPage === -1 ? null : (
          <div>
            <div
              className="cl-pagination-link"
              onClick={() => setPage(firstPage)}
            >
              Start
            </div>
            <div
              className="cl-pagination-link"
              onClick={() => setPage(Math.max(page - 1, firstPage))}
            >
              Prev
            </div>
            {pages.map((page, idx) => {
              return (
                <div
                  key={idx}
                  className="cl-pagination-link"
                  style={{}}
                  onClick={() => setPage(page)}
                >
                  {page + 1}
                </div>
              );
            })}

            <div
              className="cl-pagination-link"
              onClick={() => setPage(Math.min(page + 1, lastPage))}
            >
              Next
            </div>
            <div
              className="cl-pagination-link"
              onClick={() => setPage(lastPage)}
            >
              End
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <ContentLibraryHeader />
      <div
        style={{
          maxWidth: "1000px",
          margin: "50px auto",
        }}
      >
        {category ? (
          <>
            <h1>{category.name}</h1>
            <div
              className="grid-fr-3"
              style={{
                padding: "50px",
                columnGap: "3%",
                rowGap: "20px",
              }}
            >
              {getVisibleContent().map((content, idx) => (
                <div key={idx} style={{ display: "inline-block" }}>
                  <a href={content.path} download>
                    {content.type === "image" ? (
                      <img
                        alt={content.name}
                        style={{ width: "100%" }}
                        src={content.path}
                      />
                    ) : null}

                    {content.type === "video" ? (
                      <video id={content._id} width="250px" autoPlay muted loop>
                        <source
                          src={content.path}
                          type={`video/${content.path.match(/[^/.]+$/, "")}`}
                        />
                        Your browser does not support the video tag.
                      </video>
                    ) : null}
                  </a>
                  <div
                    style={{
                      fontSize: "20px",
                      lineHeight: "20px",
                      color: "#3a3838",
                      marginBottom: "15px",
                      display: "inline-block",
                    }}
                  >
                    {content.name}
                  </div>
                  <div className="cl-buttons">
                    <div>
                      <Link
                        className="cl-button"
                        to={`/contentlibrary/${content.type}/${content._id}`}
                      >
                        View slide
                      </Link>
                    </div>
                    <div>
                      <a className="cl-button" href={content.path} download>
                        Download
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {getPageLinks()}
          </>
        ) : (
          /*category not found yet; if not waiting for the category from db, show something for a homepage*/ <>
            {categoryID ? null : <ContentLibraryHome images={images} />}
          </>
        )}
      </div>
    </div>
  );
}

export default ContentLibrary;
