const dayStrings = [
  `Sunday`,
  `Monday`,
  `Tuesday`,
  `Wednesday`,
  `Thursday`,
  `Friday`,
  `Saturday`,
];

export const parseWeather = (weatherXML) => {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(weatherXML, `text/xml`);

  const entries = Array.from(xmlDoc.getElementsByTagName(`entry`));
  var weekday = new Array(7);
  weekday[0] = "Sunday";
  weekday[1] = "Monday";
  weekday[2] = "Tuesday";
  weekday[3] = "Wednesday";
  weekday[4] = "Thursday";
  weekday[5] = "Friday";
  weekday[6] = "Saturday";

  const currentConditionsEntry = entries.find(
    (entry) =>
      entry.textContent.includes(`Current Conditions`) ||
      entry.textContent.includes(weekday[new Date().getDay()])
  );
  console.log(currentConditionsEntry.textContent);
  const inputString = currentConditionsEntry.textContent;

  const humidityMatches = inputString.match(
    /(?<=<b>Humidity:<\/b>)(.*?)(?=<br\/>)/
  );
  const windMatches = inputString.match(/(?<=<b>Wind:<\/b>)(.*?)(?=<br\/>)/);
  const currentHumidity = humidityMatches ? humidityMatches[0] : null;
  const currentWind = windMatches ? windMatches[0] : null;

  const currentConditionsSplit = currentConditionsEntry
    ? currentConditionsEntry.textContent.split(`\n`)
    : [];
  const currentConditionsLine = currentConditionsSplit.find(
    (item) =>
      item.includes(`Current`) || item.includes(weekday[new Date().getDay()])
  );
  console.log(currentConditionsSplit);

  const [currentCondition, currentTemperature] = currentConditionsLine
    .split(/: |, /)
    .slice(1); // discard the first item which should always be the string `Current Conditions`

  //create an array of strings with the names of the next 3 days
  const todayNumeric = new Date().getDay();
  const forecastDaysNumeric = [
    //add 1, 2, 3 to the current day, and if it's past the end of the week subtract 7 to get the correct day
    todayNumeric + 1 <= 6 ? todayNumeric + 1 : todayNumeric + 1 - 7,
    todayNumeric + 2 <= 6 ? todayNumeric + 2 : todayNumeric + 2 - 7,
    todayNumeric + 3 <= 6 ? todayNumeric + 3 : todayNumeric + 3 - 7,
  ];

  const forecastDays = [
    dayStrings[forecastDaysNumeric[0]],
    dayStrings[forecastDaysNumeric[1]],
    dayStrings[forecastDaysNumeric[2]],
  ];

  let days = [];
  forecastDays.forEach((day) => {
    let entry = entries.find((entry) => entry.textContent.includes(day));
    //find the line that includes the day then split it into day, condition, high and pop, finally, remove day
    let lines = entry.textContent
      .split(`\n`)
      .find((item) => item.includes(day))
      .split(/: |\. /)
      .slice(1);
    let dayObj = {
      //abbreviate day name
      name: day.substring(0, 3),
      condition: lines[0],
      high: lines[1],
      pop: lines[2],
    };
    days.push(dayObj);
  });

  return {
    currentCondition,
    currentTemperature,
    currentWind,
    currentHumidity,
    forecastDays: days,
  };
};

export const getConditionImageURL = (condition, suffix = ``) => {
  //need to use global option to replace all spaces
  //the split is to cut off stuff like "high plus 3" if that's passed in
  condition = condition.toLowerCase().replace(/ /g, ``).split(".")[0];
  //console.log(condition);

  if ([`clear`, `sunny`, `mainlyclear`].includes(condition))
    return `/img/weather/Sunny${suffix}.png`;

  if (
    [`fog`, `shallowfog`, `mist`, `fogpatches`, `fogdissipating`].includes(
      condition
    )
  )
    return `/img/weather/Fog${suffix}.png`;
  if ([`mainlysunny`, `clearing`].includes(condition))
    return `/img/weather/PartSunny${suffix}.png`;

  if (
    [
      `afewclouds`,
      `amixofsunandcloud`,
      `partlycloudy`,
      `overcast`,
      `increasingcloudiness`,
    ].includes(condition)
  )
    return `/img/weather/SunWithCloud${suffix}.png`;

  if ([`cloudy`, `mostlycloudy`, `mainlycloudy`].includes(condition))
    return `/img/weather/Cloud${suffix}.png`;

  if (
    [
      `chanceofflurries`,
      `flurries`,
      `periodsofsnow`,
      `lightsnow`,
      `snow`,
      `heavysnow`,
      `snowgrains`,
      `afewflurries`,
      `driftingsnow`,
      `periodsoflightsnow`,
      `periodsofwetsnow`,
      `snowattimesheavy`,
      `flurriesattimesheavy`,
      `snowattimesheavyandblowingsnow`,
      `periodsofsnoworicepellets`,
      `periodsofsnowmixedwithicepellets`,
      `snowmixedwithicepellets`,
      `afewflurriesmixedwithicepellets`,
    ].includes(condition)
  )
    return `/img/weather/Snow${suffix}.png`;

  if (
    [
      `periodsofrainmixedwithsnow`,
      `periodsofsnowmixedwithrain`,
      `periodsofrainorsnow`,
      `periodsofsnoworrain`,
      `rainshowersorflurries`,
      `flurriesorrainshowers`,
      `chanceofflurriesorrainshowers`,
      `chanceofrainshowersorflurries`,
      `rainorsnow`,
      `snoworrain`,
      `snowattimesheavyorrain`,
      `afewflurriesorrainshowers`,
      `snowmixedwithrain`,
      `freezingrainattimesheavymixedwithicepellets`,
    ].includes(condition)
  )
    return `/img/weather/RainAndSnow${suffix}.png`;

  if (
    [
      `lightrain`,
      `rain`,
      `periodsofrain`,
      `chanceofshowers`,
      `showers`,
      `chanceoflightshowers`,
      `distantprecipitation`,
      `lightrainshower`,
      `periodsofrainordrizzle`,
      `afewshowers`,
      `lightdrizzle`,
      `chanceofshowersorthunderstorms`,
    ].includes(condition)
  )
    return `/img/weather/Rain${suffix}.png`;

  if ([`heavyrain`].includes(condition))
    return `/img/weather/HeavyRain${suffix}.png`;
  if ([`lightfreezingdrizzle`, `lightfreezingrain`].includes(condition))
    return `/img/weather/FeezingRain${suffix}.png`;
  if ([`lightning`].includes(condition))
    return `/img/weather/Lightning${suffix}.png`;
  if (
    [`thundershower`, `thunderstorms`, `chanceofthunderstorms`].includes(
      condition
    )
  )
    return `/img/weather/ThunderShower${suffix}.png`;

  return `/img/weather/Sunny${suffix}.png`;
};
