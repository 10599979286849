import React, { useState, useEffect, useCallback, useRef } from "react";
import { Link } from "react-router-dom";

function ContentLibraryHome({ images }) {
  const slideshowTimer = 5000;
  const numImagesToDisplay = 6;
  const [slideshowWidth, setSlideshowWidth] = useState(1000);

  function reportWindowSize() {
    setSlideshowWidth(window.innerWidth * 0.5);
  }

  window.onresize = reportWindowSize;

  const [slideshowImages, setSlideshowImages] = useState([]);
  const [activeSlide, setActiveSlide] = useState(0);
  const timer = useRef(null);

  useEffect(() => {
    if (images.length > 0) {
      const imageCount = images.length;
      let imagesToDisplay = [];
      for (let i = 0; i < numImagesToDisplay; i++) {
        let random = Math.floor(Math.random() * imageCount);
        //keep trying until you get a number that isn't already in the array, if there are enough unique images to display the desired amount
        while (
          imagesToDisplay.includes(random) &&
          images.length > numImagesToDisplay
        ) {
          random = Math.floor(Math.random() * imageCount);
        }
        imagesToDisplay.push(random);
      }
      setSlideshowImages(imagesToDisplay);
      timer.current = setTimeout(
        () => showSlides(0, imagesToDisplay),
        slideshowTimer
      );
      return () => {
        clearTimeout(timer.current);
      };
    }
  }, [images]);

  const showSlides = useCallback(
    (slideIndex, imagesToDisplay) => {
      slideIndex++;
      if (slideIndex >= imagesToDisplay.length) {
        slideIndex = 0;
        setActiveSlide(0);
      } else setActiveSlide(slideIndex);
      timer.current = setTimeout(
        () => showSlides(slideIndex, imagesToDisplay),
        slideshowTimer
      );
    },
    [activeSlide]
  );

  return (
    <>
      <h1>Content Library</h1>
      <p>
        Choose an image from the random selection below or choose a category
        above to find more images.
      </p>
      <div className="cl-slideshow" style={{ maxWidth: `${slideshowWidth}px` }}>
        {slideshowImages.map((item, idx) => {
          const pos = slideshowWidth * idx - slideshowWidth * activeSlide;
          return (
            <div
              key={idx}
              style={{ left: `${pos}px` }}
              className={`cl-slide ${idx === activeSlide ? "active" : ""}`}
            >
              <Link
                to={`/contentlibrary/image/${images[item]._id}`}
                style={{
                  display: "block",
                  height: "100%",
                }}
              >
                <img
                  alt={images[item].name}
                  style={{ width: `${slideshowWidth}px` }}
                  src={images[item].path}
                />
              </Link>
            </div>
          );
        })}
      </div>
      <div>
        {slideshowImages.map((item, idx) => {
          return (
            <div
              key={idx}
              style={{
                display: "inline-block",
                width: `${100 / numImagesToDisplay}%`,
                padding: "5px",
                boxSizing: "border-box",
              }}
            >
              <Link to={`/contentlibrary/image/${images[item]._id}`}>
                <img
                  src={images[item].path}
                  style={{
                    width: `100%`,
                    cursor: "pointer",
                  }}
                />
              </Link>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default ContentLibraryHome;
