import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";

import "./App.scss";
import { DisplayChannel } from "./Components/DisplayChannel";
import { PreviewSlide } from "./Components/slides/PreviewSlide";
import axios from "axios";
import Navbar from "./Components/layout/Navbar";
import Landing from "./Components/layout/Landing";
import Login from "./Components/auth/Login";
import ResetPassword from "./Components/auth/ResetPassword";

import PrivateRoute from "./Components/private-route/PrivateRoute";
import Dashboard from "./Components/dashboard/Dashboard";
import ContentLibrary from "./Components/content-library/ContentLibrary";
import ContentLibraryAdmin from "./Components/content-library/ContentLibraryAdmin";
import ContentLibraryImage from "./Components/content-library/ContentLibraryImage";
import ContentLibraryVideo from "./Components/content-library/ContentLibraryVideo";

import { Provider } from "react-redux";
import store from "./store";

/*
TO COPY database to local
On the server:
cd desktop\mongodb\bin
mongodump --db ve-media2 --out /var/backups/mongobackups
transfer the files to local /var folder
On local: 
cd C:\Program Files\MongoDB\Server\4.4\bin
mongorestore --db ve-media2 --drop "C:/var/ve-media2"
add <CopyPasswords/> to this page somewhere and click it

To copy to Atlas:
In Compass, click each collection and Collection->Export Collection
Select Export Full Collection and follow the rest of the prompts

Connect to Atlas, create the DB and collections and Collection->Import data

BETTER way to copy to atlas
mongodump --db ve-media2 --archive | mongorestore --drop --uri "mongodb+srv://dbUser:rT9qUue6Fo8bNQoa@cluster0.n08sy.mongodb.net/ve-media2?retryWrites=true&w=majority" --archive --ssl --nsExclude "admin.system.*"
*/

// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);
  // Decode token and get user info and exp
  const decoded = jwt_decode(token);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded)); // Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser()); // Redirect to login
    window.location.href = "./login";
  }
}

function App() {
  var getUrl = window.location;
  var baseUrl = (getUrl.protocol + "//" + getUrl.host).replace(
    "3000",
    process.env.PORT || "5000"
  );
  axios.defaults.baseURL = baseUrl;

  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route path="/login" />
          <Route path="/DesktopModules"></Route>
          <Route path="/PreviewSlide"></Route>
          <Route exact path="/" component={Navbar} />
        </Switch>
        <Switch>
          <Route exact path="/" component={Landing} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/resetpassword" component={ResetPassword} />
          <Route path="/DesktopModules" component={DisplayChannel}></Route>
          <Route path="/PreviewSlide" component={PreviewSlide}></Route>
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <PrivateRoute
            exact
            path="/contentlibrary/"
            component={ContentLibrary}
          />
          <PrivateRoute
            exact
            path="/contentlibrary/admin"
            component={ContentLibraryAdmin}
          />
          <PrivateRoute
            path="/contentlibrary/image/"
            component={ContentLibraryImage}
          />
          <PrivateRoute
            path="/contentlibrary/video/"
            component={ContentLibraryVideo}
          />
          <Route component={Landing} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
