import React from "react";

function SlideActions() {
  return (
    <div>
      <h2>Slide Actions</h2>
      <p>
        On the main screen, under Actions, there are some things you can do with
        slides by clicking the icons. In order from left to right:
      </p>
      <ol>
        <li>
          Archive: Removes the slide from the main screen. You can restore it
          later by clicking on View Archive.
        </li>
        <li>Edit: Lets you change slide properties.</li>
        <li>Preview: Shows you what the slide looks like.</li>{" "}
        <li>
          Toggle on all channels: Turns the slide on for channels where it’s off
          and vice versa.
        </li>
      </ol>
    </div>
  );
}

export default SlideActions;
