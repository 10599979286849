import React, { useState, useEffect, useCallback } from "react";

export function RSSSlide({ slide, width, height }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const noTransition = slide.rss_type === "notransition";
  const mainContent = slide.rss_type === "Main Content";

  const slideStyles = {
    fontFamily: "arial",
    backgroundColor: slide.rss_backgroundcolour,
    background: `url(${slide.rss_image_background})`,
    color: slide.rss_text_colour,
    padding: noTransition
      ? "70px 0px 0px 31px"
      : mainContent
      ? "20px 30px 0px"
      : "50px 30px 0",
    boxSizing: "border-box",
    height,
    width,
    fontSize: "30px",
  };

  const headerStyles = {
    noTransition: {
      marginBottom: "0px",
      fontSize: "27px",
      color: "#ffffcc",
    },
    mainContent: {
      color: "#F9BA49",
      fontSize: "50px",
      fontWeight: "bold",
      letterSpacing: "-1px",
      marginBottom: "40px",
      marginTop: "0px",
    },
  };

  const paragraphStyles = {
    noTransition: { fontSize: "22px" },
    mainContent: {
      color: "#FFFFFF",
      fontSize: "30px",
      height: "500px",
      lineHeight: "50px",
      overflow: "hidden",
      paddingRight: "130px",
    },
  };
  const activeStyles = {
    transition: "opacity 0.5s",
    opacity: "1",
    position: !noTransition ? "absolute" : "relative",
    padding: noTransition ? "10px 30px 0px 0px" : null,
  };
  const inactiveStyles = { ...activeStyles, opacity: 0 };

  const parser = new DOMParser();
  //RSS url will have been replaced with the actual RSS content
  const xmlDoc = parser.parseFromString(slide.rss_url, `text/xml`);

  const entries = Array.from(xmlDoc.getElementsByTagName(`item`))
    .slice(0, slide.rss_items_to_display)
    .map((item) => {
      if (noTransition || mainContent) {
        const title = item.getElementsByTagName(`title`)[0].textContent;
        let description =
          item.getElementsByTagName(`description`)[0].textContent;

        description = description.replace(/(<([^>]+)>)/gi, "");
        if (description.split("<p>")[1] && description.textContent) {
          description = description.textContent
            .split("<p>")[1]
            .replace("</p>", "");
        }
        return (
          <>
            <h3
              style={
                mainContent
                  ? headerStyles.mainContent
                  : headerStyles.noTransition
              }
            >
              {title}
            </h3>
            <p
              style={
                mainContent
                  ? paragraphStyles.mainContent
                  : paragraphStyles.noTransition
              }
            >
              {description}
            </p>
          </>
        );
      } else {
        return item.getElementsByTagName(`title`)[0].textContent;
      }
    });

  const slideDuration = slide.rss_transition_delay * 1000;
  //once loaded, set the timer to switch slides.
  const switchSlides = useCallback(
    (current) => {
      const next =
        current + 1 > slide.rss_items_to_display - 1 ? 0 : current + 1;
      setActiveIndex(next);
      setTimeout(() => switchSlides(next), slideDuration);
    },
    [slide.rss_items_to_display, slideDuration]
  );
  useEffect(() => {
    if (slide.rss_type !== "notransition")
      setTimeout(() => switchSlides(0), slideDuration);
  }, [slide.rss_type, slideDuration, switchSlides]);

  return (
    <div style={slideStyles}>
      {entries.map((item, idx) => {
        return (
          <div
            key={idx}
            style={
              idx === activeIndex || slide.rss_type === "notransition"
                ? activeStyles
                : inactiveStyles
            }
          >
            {item}
          </div>
        );
      })}
    </div>
  );
}

export default RSSSlide;
