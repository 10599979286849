import React, { useEffect, useState } from "react";
import { RTESlide } from "./RTESlide";
import { ImageSlide } from "./ImageSlide";
import { WeatherSlide } from "./WeatherSlide";
import { RSSSlide } from "./RSSSlide";
import { FlashSlide } from "./FlashSlide";
import { VideoSlide } from "./VideoSlide";
import InterestRateSlide from "./InterestRateSlide";
import TimeSlide from "./TimeSlide";
import TemplateSlide from "./TemplateSlide";
import WPRSSSlide from "./WPRSSSlide";
import { getNextSlideIndex, slideTypes } from "../../utils/slideHelpers";

export function Slide({
  slide,
  area,
  areaIndex,
  slideIndex,
  channel,
  currentSlide,
  active,
  preview,
}) {
  const [isActive, setIsActive] = useState(active || false);

  useEffect(() => {
    if (currentSlide) {
      //check if this is the current slide for this area
      if (currentSlide[areaIndex] === undefined) return <></>;
      setIsActive(currentSlide[areaIndex]._id === slide._id);
    }
  }, [currentSlide, areaIndex, slide._id]);

  if (!slide || !channel || !area) return "loading...";
  //if the current slide for this area is this one

  //active = true; //DEBUG: show all slides

  const areaWidth = area.responsive ? "100%" : area.width;
  const areaHeight = area.responsive ? "100%" : area.height;

  let innerComponent = <></>;
  let activeStyles = {
    height: "100%",
    width: areaWidth,
  };
  let inactiveStyles = {
    height: areaHeight,
    width: areaWidth,
  };
  let nextSlideStyles = {};
  if (!preview) {
    const transitionDuration = slide.transitionDuration
      ? slide.transitionDuration + "s"
      : "1s";
    const transitionTimingFunction = slide.transitionTimingFunction || "ease";

    activeStyles = {
      position: "relative",
      top: "0px",
      left: "0px",
      transitionProperty: `opacity, top, left`,
      transitionDuration,
      transitionTimingFunction,
      height: areaHeight,
      width: areaWidth,
    };
    inactiveStyles = {
      ...activeStyles,
      position: "absolute",
      transitionDelay: "0s",
      zIndex: "-1",
    };

    nextSlideStyles = {
      ...inactiveStyles,
      visibility: "hidden",
    };
    switch (channel.selectedSlides[areaIndex].AreaID.transition) {
      case "fade":
        inactiveStyles.opacity = 0;
        nextSlideStyles.opacity = 0;
        break;
      case "slide":
        //activeStyles.transitionDelay = "0.5s";
        inactiveStyles.top = "100%";
        nextSlideStyles.top = "-100%";
        break;
      case "slidehorizontal":
        //activeStyles.transitionDelay = "0.5s";
        inactiveStyles.left = "-100%";
        nextSlideStyles.left = "100%";
        break;
      default:
        break;
    }
  }
  switch (slide.type) {
    case slideTypes.rte.index:
      innerComponent = (
        <RTESlide
          slide={slide}
          active={isActive}
          height={areaHeight}
          width={areaWidth}
        ></RTESlide>
      );
      break;
    case slideTypes.weather.index:
      innerComponent = (
        <WeatherSlide
          slide={slide}
          active={isActive}
          height={areaHeight}
          width={areaWidth}
        ></WeatherSlide>
      );
      break;
    case slideTypes.image.index:
      //image slide
      innerComponent = (
        <ImageSlide
          slide={slide}
          active={isActive}
          height={areaHeight}
          width={areaWidth}
        ></ImageSlide>
      );
      break;
    case slideTypes.rss.index:
      //rss slide
      innerComponent = (
        <RSSSlide
          slide={slide}
          active={isActive}
          height={areaHeight}
          width={areaWidth}
        />
      );
      break;
    case slideTypes.flash.index:
      //flash slide
      innerComponent = (
        <FlashSlide
          slide={slide}
          active={isActive}
          height={areaHeight}
          width={areaWidth}
        />
      );
      break;
    case slideTypes.video.index:
      innerComponent = (
        <VideoSlide
          slide={slide}
          active={isActive}
          height={areaHeight}
          width={areaWidth}
        />
      );
      break;
    case slideTypes.interestrate.index:
      innerComponent = (
        <InterestRateSlide
          slide={slide}
          active={isActive}
          height={areaHeight}
          width={areaWidth}
        />
      );
      break;
    case slideTypes.time.index:
      innerComponent = (
        <TimeSlide
          slide={slide}
          active={isActive}
          height={areaHeight}
          width={areaWidth}
        />
      );
      break;
    case slideTypes.template.index:
      innerComponent = (
        <TemplateSlide
          slide={slide}
          active={isActive}
          height={areaHeight}
          width={areaWidth}
        />
      );
      break;
    case slideTypes.wpRss.index:
      innerComponent = (
        <WPRSSSlide
          slide={slide}
          active={isActive}
          height={areaHeight}
          width={areaWidth}
        />
      );
      break;
    default:
      innerComponent = <div>{slide.type + " " + slide.name}</div>;
      break;
  }
  let styles;
  if (isActive) {
    styles = activeStyles;
  } else if (
    currentSlide &&
    currentSlide[areaIndex] &&
    getNextSlideIndex(channel, areaIndex, currentSlide[areaIndex].index) ===
      slideIndex
  ) {
    styles = nextSlideStyles;
  } else styles = inactiveStyles;
  return (
    <div className="Slide" style={styles} key={slide._id}>
      {innerComponent}
    </div>
  );
}

export default Slide;
