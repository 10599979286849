import React, { useState, useEffect } from "react";
import axios from "axios";
import Slide from "./Slide";
import * as QueryString from "query-string";
import { authorize } from "passport";

export function PreviewSlide(props) {
  let [slide, setSlide] = useState(undefined);
  let [channel, setChannel] = useState(undefined);
  let [area, setArea] = useState(undefined);
  let [areaIndex] = useState(undefined);

  const params = QueryString.parse(props.location.search);
  useEffect(() => {
    axios
      .post("/api/slides/getslidebyid", {
        PortalId: params.portal,
        _id: params.slide,
      })
      .then((res) => {
        let slide = res.data;
        if (window.location.href.includes("localhost")) {
          slide.image_path = "https://ve-media.com" + slide.image_path;
          slide.video_path = "https://ve-media.com" + slide.video_path;
          slide.interestrate_path =
            "https://ve-media.com" + slide.interestrate_path;
          slide.template_image1_path =
            "https://ve-media.com" + slide.template_image1_path;
          slide.template_image2_path =
            "https://ve-media.com" + slide.template_image2_path;
          slide.template_image3_path =
            "https://ve-media.com" + slide.template_image3_path;
          slide.template_image4_path =
            "https://ve-media.com" + slide.template_image4_path;
          slide.template_image_background =
            "https://ve-media.com" + slide.template_image_background;
        }
        setSlide(slide);

        //get the channel for this preview
        axios
          .post("/api/channels/getchannelfordisplaybyid", {
            _id: params.channel,
          })
          .then((res) => {
            let channel = res.data;
            setChannel(channel);

            axios
              .post("/api/areas/getareabyid", {
                _id: params.area,
              })
              .then((res) => {
                let area = res.data;
                setArea(area);
              });
          });
      })

      .catch((err) => console.log(err));
  }, [params.area, params.channel, params.portal, params.slide]);

  return (
    <div
      className="PreviewSlide"
      style={
        channel
          ? {
              backgroundImage: `url(${channel.backgroundimage})`,
              width: channel.width
                ? `${Math.min(channel.width, 100)}${
                    channel.responsive ? "vw" : "px"
                  }`
                : "100vw",
              height: channel.height
                ? `${Math.min(channel.height, 100)}${
                    channel.responsive ? "vh" : "px"
                  }`
                : "100vh",
              overflow: "hidden",
              backgroundSize: channel.responsive ? "100% 100%" : "auto",
            }
          : null
      }
    >
      <div
        className="PreviewSlideInner"
        style={
          area
            ? {
                position: "absolute",
                top: area.responsive ? `${area.y}%` : area.y,
                left: area.responsive ? `${area.x}%` : area.x,
                width: area.responsive
                  ? area.widthResponsive
                    ? area.widthResponsive
                    : `${Math.min(area.width, 100)}%`
                  : area.width,
                height: area.responsive
                  ? area.heightResponsive
                    ? area.heightResponsive
                    : `${Math.min(area.height, 100)}%`
                  : area.height,
                overflow: "hidden",
              }
            : null
        }
      >
        {slide ? (
          <Slide
            slide={slide}
            channel={channel}
            area={area}
            areaIndex={areaIndex}
            active={true}
            preview={true}
          ></Slide>
        ) : null}
      </div>
    </div>
  );
}

export default PreviewSlide;
