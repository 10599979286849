export const getStatus = (date) => {
  const now = Date.now();
  if (!date || date.getFullYear() === 0) return "Never used";
  else {
    const hours = Math.abs(now - date.getTime()) / (60 * 60 * 1000);
    if (hours < 48.0) {
      return "Good";
    } else if (hours >= 48.0 && hours < 72.0) {
      return "Warning";
    } else {
      return `Failed`;
    }
  }
};
