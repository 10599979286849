import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import ContentLibraryHeader from "./ContentLibraryHeader";
import axios from "axios";
import { Link } from "react-router-dom";
import { setSelectedSlide } from "../../actions/selectionActions";

function ContentLibraryVideo() {
  let location = window.location.pathname;
  let split = location.split("/");
  let videoId = split[split.length - 1];

  const dispatch = useDispatch();
  const [video, setVideo] = useState(null);

  useEffect(() => {
    axios.post("/api/videos/find", { _id: videoId }).then((res) => {
      setVideo(res.data[0]);
      console.log(res.data[0]);
    });
  }, [videoId]);

  return (
    <div>
      <ContentLibraryHeader />
      <div
        style={{
          maxWidth: "1000px",
          margin: "38px auto",
        }}
      >
        {video ? (
          <>
            <h1>{video.name}</h1>
            <video id={video._id} autoPlay muted loop>
              <source
                src={video.path}
                type={`video/${video.path.match(/[^/.]+$/, "")}`}
              />
              Your browser does not support the video tag.
            </video>
            <div className="cl-buttons">
              <div>
                <a class="cl-button" a href={video.path} download>
                  Download
                </a>
              </div>

              <div>
                <Link
                  to="/dashboard/EditSlide"
                  onClick={() => {
                    dispatch(
                      setSelectedSlide({
                        name: video.name,
                        type: 5,
                        video_path: video.path,
                      })
                    );
                  }}
                >
                  Create Slide
                </Link>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}

export default ContentLibraryVideo;
