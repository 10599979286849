import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { slideTypes } from "../../utils/slideHelpers";
import {
  setSelectedSlideType,
  setSelectedSlide,
} from "../../actions/selectionActions";

function ChooseSlideType() {
  const selectedArea = useSelector((state) => state.selection.area);
  const dispatch = useDispatch();
  dispatch(setSelectedSlide(null));

  //we need to know what area this slide should go in so redirect to dashboard if we don't have one selected.
  return selectedArea ? (
    <div>
      <span className="addslide_header">Choose Slide Type</span>
      <table
        id="SlideTable"
        className="SlideTable"
        style={{ borderCollapse: "collapse" }}
        cellSpacing="0"
      >
        <tbody>
          <tr>
            <td>
              <Link
                to="/dashboard/EditSlide"
                onClick={() => dispatch(setSelectedSlideType(slideTypes.image))}
              >
                Add as Image
              </Link>
            </td>
            <td>
              Upload a sized image format as a slide. Formats accepted: .bmp,
              .gif, .jpg, .jpeg, .png
            </td>
          </tr>
          {/*<tr>
            <td>
              <Link
                to="/dashboard/EditSlide"
                onClick={() =>
                  dispatch(setSelectedSlideType(slideTypes.template))
                }
              >
                Use a Template
              </Link>
            </td>
            <td>Enter your content using a selection of templates.</td>
              </tr>*/}
          <tr>
            <td>
              <Link
                to="/dashboard/EditSlide"
                onClick={() => dispatch(setSelectedSlideType(slideTypes.rte))}
              >
                Rich Text Editor
              </Link>
            </td>
            <td>
              A rich text editor gives you complete control over the HTML of
              your slide. Includes an image uploader to simplify adding a
              background image. Similar to microsoft word tools.
            </td>
          </tr>
          <tr style={{ display: "none" }}>
            <td>
              <Link
                to="/dashboard/EditSlide"
                onClick={() => dispatch(setSelectedSlideType(slideTypes.flash))}
              >
                Flash Slide
              </Link>
            </td>
            <td>
              Upload a slide in flash format. Formats accepted: .swf, .flv
            </td>
          </tr>
          <tr>
            <td>
              <Link
                to="/dashboard/EditSlide"
                onClick={() => dispatch(setSelectedSlideType(slideTypes.video))}
              >
                Video Slide
              </Link>
            </td>
            <td>
              Upload a video. Formats accepted: .wmv, .mpg, .mp4, .ogg, .ogv,
              .webm
            </td>
          </tr>
          <tr>
            <td>
              <Link
                to="/dashboard/EditSlide"
                onClick={() =>
                  dispatch(setSelectedSlideType(slideTypes.weather))
                }
              >
                Weather
              </Link>
            </td>
            <td>
              Display a weather forecast in full size or compact top/bottom bar
              format.
            </td>
          </tr>
          <tr>
            <td>
              <Link
                to="/dashboard/EditSlide"
                onClick={() =>
                  dispatch(setSelectedSlideType(slideTypes.interestrate))
                }
              >
                Interest Rate
              </Link>
            </td>
            <td>
              Link to a live set of interest rates, upload an XML file
              containing them or create one on the spot.
            </td>
          </tr>
          <tr>
            <td>
              <Link
                to="/dashboard/EditSlide"
                onClick={() => dispatch(setSelectedSlideType(slideTypes.rss))}
              >
                RSS
              </Link>
            </td>
            <td>
              Display a news feed with or without the included images, with the
              possibility of showing more than one item at once, or
              transitioning between items.
            </td>
          </tr>
          {/*<tr>
            <td>
              <Link
                to="/dashboard/EditSlide"
                onClick={() => dispatch(setSelectedSlideType(slideTypes.time))}
              >
                Time
              </Link>
            </td>
            <td>
              Display the current time and/or date for a timezone of your
              choice.
            </td>
              </tr>*/}
          <tr>
            <td>
              <Link
                to="/dashboard/EditSlide"
                onClick={() => dispatch(setSelectedSlideType(slideTypes.wpRss))}
              >
                Ve-Branch XML Slide
              </Link>
            </td>
            <td>
              Connect to the Ve-Branch Connector wordpress plugin to display
              posts.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  ) : (
    <Redirect to="/dashboard" />
  );
}

export default ChooseSlideType;
