import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import Header from "../dashboard/Header";
import { Link } from "react-router-dom";

function ContentLibraryHeader() {
  const [categories, setCategories] = useState([]);
  const auth = useSelector((state) => state.auth);
  let categoriesRendered = 0;

  useEffect(() => {
    if (categories.length === 0)
      axios.get("/api/categories").then((res) => {
        setCategories(res.data);
      });
  }, []);

  const renderMenuCategory = (category, idx) => {
    if (category.hideFromMenu) return null;

    categoriesRendered++;
    const children = categories.filter(
      (cat) => cat.parentCategory === category._id
    );

    return (
      <li
        className="menu_category"
        key={idx}
        style={{
          borderLeft: idx > 0 ? "1px solid #fff" : "none",
        }}
      >
        {category.childrenOnly ? (
          <span>{category.name}</span>
        ) : (
          <Link to={`/contentlibrary?category=${category._id}`}>
            {category.name}
          </Link>
        )}

        {children.length > 0 ? (
          <ul className="menu_subcategories">
            {children.map((cat, idx) => {
              return (
                <li key={idx} className="menu_category">
                  <Link to={`/contentlibrary?category=${cat._id}`}>
                    {cat.name}
                  </Link>
                </li>
              );
            })}
          </ul>
        ) : null}
      </li>
    );
  };
  return (
    <div>
      <Header />
      <div className="header_out">
        <div className="header_in">
          <ul
            className="contentlibrary_menu"
            style={{
              listStyle: "none",
              margin: "0 auto",
              display: "block",
              textAlign: "center",
              padding: "0",
            }}
          >
            {categories.map((cat, idx) => {
              if (cat.parentCategory == null)
                return renderMenuCategory(cat, categoriesRendered);
              else return null;
            })}
            {auth.user.isSuperUser ? (
              <li style={{ borderLeft: "1px solid #fff" }}>
                <Link to="/contentlibrary/admin">Admin</Link>
              </li>
            ) : null}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ContentLibraryHeader;
