import React from "react";
import ColorPicker from "../../utils/ColorPicker";

function WpRssProperties(props) {
  const styleOptions = [
    "Main Content",
    "Main Content (No Transition)",
    "Bottom Content",
  ];

  return (
    <div>
      <div className="addslidesectiontopbar">Style</div>
      <div className="addslidesection grid-fit-2">
        <label>Display Type:</label>
        <select
          value={props.slide.wp_rss_type}
          onChange={(e) => {
            props.updateSlide("wp_rss_type", e.target.value);
          }}
        >
          {styleOptions.map((item) => {
            return (
              <option key={item} value={item}>
                {item}
              </option>
            );
          })}
        </select>
        <label>Font colour:</label>
        <ColorPicker
          color={props.slide.wp_rss_text_colour}
          onChange={(color, e) =>
            props.updateSlide("wp_rss_text_colour", color.hex)
          }
        />
      </div>
      <div className="addslidesectiontopbar">Content</div>
      <div className="addslidesection grid-fit-2">
        <label>URL to RSS feed:</label>
        <input
          type="text"
          value={props.slide.wp_rss_url ? props.slide.wp_rss_url : ""}
          onChange={(e) => props.updateSlide("wp_rss_url", e.target.value)}
        />
        <label>Number of items to display:</label>
        <input
          type="text"
          value={
            props.slide.wp_rss_items_to_display
              ? props.slide.wp_rss_items_to_display
              : ""
          }
          onChange={(e) =>
            props.updateSlide("wp_rss_items_to_display", e.target.value)
          }
        />
        <label>
          Time between items in seconds (if left blank, will use total slide
          duration divided by number of items):
        </label>
        <input
          type="text"
          value={
            props.slide.wp_rss_transition_delay
              ? props.slide.wp_rss_transition_delay
              : ""
          }
          onChange={(e) =>
            props.updateSlide("wp_rss_transition_delay", e.target.value)
          }
        />
      </div>
    </div>
  );
}

export default WpRssProperties;
