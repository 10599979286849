import React, { useCallback, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import SortableSlide from "./SortableSlide";
import update from "immutability-helper";
import axios from "axios";

function SortSlides(props) {
  const channel = useSelector((state) => state.selection.channel);
  const area = useSelector((state) => state.selection.area);
  const [slides, setSlides] = useState([]);
  const [message, setMessage] = useState("");

  const [otherChannels, setOtherChannels] = useState(undefined);
  const [otherChannel, setOtherChannel] = useState(undefined);
  const [otherSlides, setOtherSlides] = useState([]);
  const [portal, setPortal] = useState(undefined);

  const moveSlide = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = slides[dragIndex];
      setSlides(
        update(slides, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        })
      );
    },
    [slides]
  );

  const moveOtherSlide = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = otherSlides[dragIndex];
      setOtherSlides(
        update(otherSlides, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        })
      );
    },
    [otherSlides]
  );

  useEffect(() => {
    if (channel) {
      //figure out which index of selectedSlides is for the selected area, and get the slides from it
      axios
        .post("/api/slides/getslidesbyids", {
          array: channel.selectedSlides.find((ss) => ss.AreaID === area._id)
            .SlideIDs,
          PortalId: channel.PortalId,
        })
        .then((res) => {
          setSlides(res.data.filter(filterSlides));
        });
      axios
        .post("/api/portals/getPortal", { PortalId: channel.PortalId })
        .then((res) => setPortal(res.data));
    }
  }, [channel, area]);

  //get the other channels that can be viewed alongside this one.
  useEffect(() => {
    console.log(`Other channels:`);
    console.log(channel);
    if (channel && !otherChannels) {
      axios
        .post("/api/channels/getchannelsinorder", {
          PortalId: channel.PortalId,
        })
        .then((r) => {
          let others = r.data.filter((c) => {
            return (
              //other channels should be channels that aren't the channel we're already editing
              c._id !== channel._id &&
              //and have slides in the selected area.
              c.selectedSlides.find((ss) => ss.AreaID === area._id) !==
                undefined
            );
          });
          console.log(others);
          setOtherChannels(others);
        });
    }
  }, [channel, otherChannels]);

  const filterSlides = (slide) => {
    //filter out slides that don't need to be sorted
    return !slide.archived;
  };

  const renderSlide = (slide, index, other = false) => {
    if (slide && slide.archived) return null;
    if (!slide) return null;
    return (
      <SortableSlide
        key={index}
        index={index}
        slide={slide}
        moveSlide={other ? moveOtherSlide : moveSlide}
        syncMode={otherChannel !== undefined}
      />
    );
  };

  const saveSlides = () => {
    channel.selectedSlides.find((ss) => ss.AreaID === area._id).SlideIDs =
      slides.map((slide) => slide._id);
    axios
      .patch("api/channels/updateChannel", {
        _id: channel._id,
        PortalId: channel.PortalId,
        update: {
          selectedSlides: channel.selectedSlides,
        },
      })
      .then((r) => setMessage("Save successful."))
      .catch((e) => {
        setMessage(e.response.data.error);
      });

    if (otherChannel) {
      otherChannel.selectedSlides.find(
        (ss) => ss.AreaID === area._id
      ).SlideIDs = otherSlides.map((slide) => slide._id);

      axios
        .patch("api/channels/updateChannel", {
          _id: otherChannel._id,
          PortalId: otherChannel.PortalId,
          update: {
            selectedSlides: otherChannel.selectedSlides,
          },
        })
        .then((r) => setMessage("Save successful."))
        .catch((e) => {
          setMessage(e.response.data.error);
        });
    }
  };

  const selectOtherChannel = (idx) => {
    if (idx >= 0) {
      let ch = otherChannels[idx];
      setOtherChannel(ch);
      axios
        .post("/api/slides/getslidesbyids", {
          array: ch.selectedSlides.find((ss) => ss.AreaID === area._id)
            .SlideIDs,
          PortalId: ch.PortalId,
        })
        .then((res) => {
          setOtherSlides(res.data.filter(filterSlides));
        });
    } else {
      setOtherChannel(undefined);
      setOtherSlides([]);
    }
  };

  const totalDurationReducer = (prev, slide) => prev + slide.duration;

  if (channel === undefined) {
    props.history.push("/dashboard");
    return null;
  } else {
    let slideDuration1 = slides.reduce(totalDurationReducer, 0);
    let slideDuration2 = otherSlides.reduce(totalDurationReducer, 0);
    let durationBalanceMessage = "";
    if (otherChannel) {
      if (slideDuration1 > slideDuration2) {
        let diff = slideDuration1 - slideDuration2;
        durationBalanceMessage = (
          <>
            add {diff} seconds of slides to <strong>{otherChannel.name}</strong>{" "}
            or remove {diff} seconds of slides from{" "}
            <strong>{channel.name}</strong>.
          </>
        );
      } else if (slideDuration2 > slideDuration1) {
        let diff = slideDuration2 - slideDuration1;
        durationBalanceMessage = (
          <>
            add {diff} seconds of slides to <strong>{channel.name}</strong> or
            remove {diff} seconds of slides from{" "}
            <strong>{otherChannel.name}</strong>.
          </>
        );
      }
    }

    return (
      <div>
        <div>
          {portal !== undefined &&
          portal.optionalFeatures.includes("synchronization") ? (
            <>
              <span>Edit alongside another channel:</span>
              <select
                onChange={(e) => {
                  selectOtherChannel(parseInt(e.target.value));
                }}
              >
                <option value="-1">None</option>
                {otherChannels
                  ? otherChannels.map((channel, idx) => (
                      <option value={idx}>{channel.name}</option>
                    ))
                  : null}
              </select>
            </>
          ) : null}

          {slides.length > 0 &&
          otherSlides.length > 0 &&
          slideDuration1 !== slideDuration2 ? (
            <div
              className="validationErrors"
              style={{
                border: "2px solid red",
                textAlign: "center",
                margin: "10px 0",
              }}
            >
              <img
                alt=""
                id="ValidationImage"
                src="/img/exclamation-point-icon.jpg"
                style={{
                  borderWidth: "0px",
                  width: "64px",
                  verticalAlign: "middle",
                }}
              ></img>
              <p
                style={{
                  display: "inline-block",
                  verticalAlign: "middle",
                }}
              >
                <strong>Warning</strong>: Total duration of slides is not equal
                on both channels, so they won't always be synchronized as shown.
                To make them equal, you will have to {durationBalanceMessage}
              </p>
            </div>
          ) : null}
        </div>
        <div
          style={{
            width: otherChannel !== undefined ? "50%" : "100%",
            display: "inline-block",
            verticalAlign: "top",
          }}
        >
          <DndProvider backend={HTML5Backend}>
            <span>
              Sorting slides in area <strong>{area.name}</strong> for channel{" "}
              <strong>{channel.name}</strong>
            </span>
            {slides.map((slide, idx) => renderSlide(slide, idx))}

            <input
              className="button_lightred"
              type="button"
              value="Save Changes"
              onClick={() => saveSlides()}
            />
            <input
              className="button_lightred"
              type="button"
              value="<< Back"
              onClick={() => props.history.push("/dashboard/")}
            />
            <span>{message}</span>
          </DndProvider>
        </div>
        {otherChannel === undefined ? null : (
          <div
            style={{
              width: "50%",
              display: "inline-block",
              verticalAlign: "top",
            }}
          >
            <DndProvider backend={HTML5Backend}>
              <span>
                Sorting slides in area <strong>{area.name}</strong> for channel{" "}
                <strong>{otherChannel.name}</strong>
              </span>
              {otherSlides.map((slide, idx) => renderSlide(slide, idx, true))}
            </DndProvider>
          </div>
        )}
      </div>
    );
  }
}

export default SortSlides;
