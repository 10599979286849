import React from "react";

export function RTESlide({ slide, width, height }) {
  const slideStyles = {
    fontFamily: "arial",
    backgroundColor: slide.rte_backgroundcolour,
    padding: "20px",
    height,
    width,
  };

  const htmlDecode = (input) => {
    //if it has more than 5 &, assume it's encoded in the database (some coming from the old vemedia are encoded and some aren't for some reason)
    if ((input.match(/&/g) || []).length >= 5) {
      var doc = new DOMParser().parseFromString(input, "text/html");
      return doc.documentElement.textContent;
    } else return input;
  };
  return (
    <div
      style={slideStyles}
      dangerouslySetInnerHTML={{ __html: htmlDecode(slide.rte_content) }}
    ></div>
  );
}

export default RTESlide;
