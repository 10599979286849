import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  canEditUserRole,
  hasRestrictedChannels,
  roleNames,
  canAssignRole,
} from "../../utils/roleHelpers";

import { DEFAULT_CHANNELS_PER_PAGE } from "../../utils/globalSettings";
import axios from "axios";
import CustomCheckbox from "../misc/CustomCheckbox";

function EditUser(props) {
  const auth = useSelector((state) => state.auth);
  const authUser = auth.user;
  const selectedPortal = useSelector((state) => state.selection.portal);

  const defaultUser = {
    PortalId: selectedPortal || authUser.PortalId,
    roleName: 3,
    channels: [],
  };

  console.log(authUser);
  const [user, setUser] = useState(
    useSelector((state) => state.selection.user || defaultUser)
  );

  const [channels, setChannels] = useState(undefined);
  const [message, setMessage] = useState("");

  const saveUser = () => {
    if (user._id) {
      axios
        .post("/api/users/update", {
          _id: user._id,
          PortalId: user.PortalId,
          update: {
            ...user,
          },
        })
        .then((r) => {
          if (r.data.error) setMessage(r.data.error);
          else setMessage("Save successful.");
        })
        .catch((e) => setMessage(e.response.data.error));
    } else {
      axios
        .post("/api/users/register", user)
        .then((r) => {
          console.log(r.data);
          if (r.data.error) setMessage(r.data.error);
          if (r.data.errors) setMessage(JSON.stringify(r.data.errors));
          else {
            setUser(r.data);
            setMessage("Save successful.");
          }
        })
        .catch((e) => {
          if (e.response.data.errors) {
            //convert errors object into array
            const obj = e.response.data.errors;
            let errors = [];
            for (var key in obj) {
              if (Object.prototype.hasOwnProperty.call(obj, key)) {
                var val = obj[key];
                // use val
                errors.push(val);
              }
            }
            setMessage(errors.map((err, idx) => <div key={idx}>{err}</div>));
          } else setMessage(e.response.data.error);
        });
    }
  };

  useEffect(() => {
    if (user && hasRestrictedChannels(user) && channels === undefined) {
      axios
        .post("/api/channels/getchannelsinorder", {
          PortalId: user.PortalId,
        })
        .then((channels) => {
          setChannels(channels.data);
        });
    }
  }, [user, channels]);

  const gridStyles = {
    padding: "10px",
    rowGap: "5px",
    columnGap: "5px",
    maxWidth: "970px",
  };

  if (user === undefined) {
    //if no channel is selected, go back to where they can select one.
    props.history.push("/dashboard/controlpanel");
    return null;
  } else {
    console.log(user);
    return (
      <div>
        <div className="controlpanelsectiontopbar">User Information</div>
        {user.id ? (
          <span>Contact your system admin to change this user's password.</span>
        ) : null}
        <div className="grid-fit-2" style={gridStyles}>
          <span>Username:</span>
          {user._id ? (
            <span>{user.userName}</span>
          ) : (
            <>
              <input
                value={user.userName}
                onChange={(e) => setUser({ ...user, userName: e.target.value })}
              />
              <span>Password:</span>
              <input
                type="password"
                value={user.password}
                onChange={(e) => setUser({ ...user, password: e.target.value })}
              />
              <span>Confirm Password:</span>
              <input
                type="password"
                value={user.password2}
                onChange={(e) =>
                  setUser({ ...user, password2: e.target.value })
                }
              />
            </>
          )}
          <span>Display Name:</span>
          <input
            value={user.displayName}
            onChange={(e) => setUser({ ...user, displayName: e.target.value })}
          />
          <span>Email:</span>
          <input
            value={user.email}
            onChange={(e) => setUser({ ...user, email: e.target.value })}
          />
          <label>Role Name:</label>
          <select
            value={user.roleName}
            onChange={(e) => {
              setUser({ ...user, roleName: parseInt(e.target.value) });
            }}
            disabled={!canEditUserRole(authUser, user)}
          >
            {roleNames.map((item, idx) => {
              return canAssignRole(authUser, idx) ? (
                <option key={idx} value={idx}>
                  {item}
                </option>
              ) : null;
            })}
          </select>
          <span>{`Channels Per Page (0 to use the default: currently ${DEFAULT_CHANNELS_PER_PAGE}):`}</span>
          <input
            value={user.channelsPerPage || 0}
            onChange={(e) =>
              setUser({
                ...user,
                channelsPerPage: parseInt(e.target.value) || 0,
              })
            }
            maxLength={2}
          />
        </div>
        {hasRestrictedChannels(user) ? (
          <>
            <div className="controlpanelsectiontopbar">Accessible Channels</div>
            <div className="grid-fit-2" style={gridStyles}>
              {channels
                ? channels.map((channel, idx) => {
                    return (
                      <div key={idx}>
                        <CustomCheckbox
                          id={`channel-${channel._id}`}
                          checked={
                            user.channels && user.channels.includes(channel._id)
                          }
                          onChange={(e) => {
                            let allowedChannels = user.channels || [];
                            if (!allowedChannels.includes(channel._id)) {
                              allowedChannels.push(channel._id);
                            } else {
                              allowedChannels = allowedChannels.filter(
                                (item) => {
                                  console.log(`${item._id} !== ${channel._id}`);
                                  return item !== channel._id;
                                }
                              );
                            }
                            setUser({
                              ...user,
                              channels: allowedChannels,
                            });
                          }}
                        />
                        <label htmlFor={`channel-${channel._id}`}>
                          {channel.name}
                        </label>
                      </div>
                    );
                  })
                : null}
            </div>
          </>
        ) : null}
        <input
          className="button_lightred"
          type="button"
          value="Save Changes"
          onClick={() => saveUser()}
        />
        <input
          className="button_lightred"
          type="button"
          value="<< Back"
          onClick={() => props.history.push("/dashboard/controlpanel")}
        />
        <span>{message}</span>
      </div>
    );
  }
}

export default EditUser;
