import React from "react";

function ChannelActions() {
  return (
    <div>
      <h2>Channel Actions</h2>
      <p>
        On the main screen next to each channel’s name there are some icons:
      </p>
      <ul>
        <li>Preview: Shows you what the channel looks like.</li>
        <li>Reorder: Lets you change the order the slides appear in.</li>
      </ul>
    </div>
  );
}

export default ChannelActions;
