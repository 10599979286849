import React from "react";
import { parseWeather, getConditionImageURL } from "../../utils/weatherHelpers";

export function WeatherSlide({ slide, height, width }) {
  const {
    currentCondition,
    currentTemperature,
    currentWind,
    currentHumidity,
    forecastDays,
  } = parseWeather(slide.weather_url);

  const responsive = true;

  /*slide.weather_type = "Bottom Content";
  slide.weather_font_size = "33vw";*/

  if (slide.weather_type === "Temperature Only") {
    return (
      <div style={{ fontSize: slide.weather_font_size }}>
        {currentTemperature || currentCondition}
      </div>
    );
  }

  const bottomContent = slide.weather_type === "Bottom Content";
  let weatherImageSuffix = bottomContent ? "_small" : "";
  const currentConditionImage = getConditionImageURL(
    currentCondition,
    weatherImageSuffix
  );

  const slideStyles = bottomContent
    ? { background: "#8aaee9", fontFamily: "arial" }
    : {
        backgroundImage: "url('/img/weather/Weather_BG.jpg')",
        backgroundRepeat: "no-repeat",
        backgroundSize: responsive ? "100vw 100vh" : "auto",
        fontFamily: "arial",
        height,
        width,
      };

  const currentConditionsStyles = bottomContent
    ? {
        position: "relative",
        top: "0px",
        left: "0px",
        background: "#507ac0",
        height: "250px",
        width: "302px",
        borderRight: "3px solid #0a2b62",
        color: "#ffffff",
      }
    : {
        position: "relative",
        top: responsive ? "23vh" : "160px",
        left: responsive ? "8vw" : "115px",
        color: "#000000",
      };

  const currentConditionsHeaderStyles = bottomContent
    ? {
        display: "block",
        background: "#0f3370",
        fontSize: "28px",
        textAlign: "center",
        fontWeight: "bold",
        letterSpacing: "-1px",
        height: "40px",
        borderBottom: "2px solid #0a2b62",
      }
    : {
        display: "block",
        fontSize: "37px",
        fontWeight: "bold",
        color: "#000000",
        marginBottom: "20px",
      };

  const currentConditionsImageStyles = bottomContent
    ? {
        width: "60px",
        height: "60px",
        border: "1px solid #a2a2a2",
        marginLeft: "18px",
        marginTop: "20px",
        display: "inline-block",
      }
    : {
        width: "125px",
        height: "125px",
        border: "1px solid #cccccc",
        marginLeft: "0px",
        overflow: "hidden",
        display: "inline-block",
      };

  const temperatureStyles = bottomContent
    ? {
        fontSize: "50px",
        letterSpacing: "-2px",
        fontWeight: "bold",
        color: "#ffffcc",
        marginLeft: "10px",
        marginTop: "10px",
      }
    : {
        width: "200px",
        marginLeft: "10px",
        fontSize: "71px",
        fontWeight: "bold",
        letterSpacing: "-2px",
        color: "#101848",
        display: "inline-block",
      };

  const conditionStyles = bottomContent
    ? {
        fontSize: "20px",
        letterSpacing: "0px",
        fontWeight: "bold",
        marginLeft: "10px",
      }
    : {
        marginTop: "-10px",
        fontSize: "21px",
        width: "205px",
        marginLeft: "10px",
        color: "#333333",
      };

  const windStyles = bottomContent
    ? {
        fontSize: "16px",
        letterSpacing: "-1px",
        marginLeft: "90px",
      }
    : {
        marginTop: "70px",
        fontSize: "30px",
        width: "350px",
        marginLeft: "10px",
        color: "#333333",
      };

  const humidityStyles = bottomContent
    ? {
        fontSize: "18px",
        letterSpacing: "-1px",
        marginLeft: "90px",
      }
    : {
        fontSize: "30px",
        width: "405px",
        marginLeft: "10px",
        color: "#333333",
      };

  const outlookMainStyles = bottomContent
    ? { position: "absolute", top: "0px", left: "305px" }
    : {
        position: "absolute",
        top: responsive ? "23vh" : "160px",
        left: responsive ? "38vw" : "510px",
        color: "#000000",
      };

  const outlookCommonStyles = bottomContent
    ? {
        width: "175px",
        height: "250px",
        borderRight: "1px solid #0a2b62",
        fontSize: "19px",
        fontWeight: "bold",
        letterSpacing: "-1px",
        color: "#ffffff",
        textAlign: "center",
        position: "absolute",
      }
    : {
        width: "14vw",
        height: "400px",
        letterSpacing: "-1px",
        color: "#000000",
        fontWeight: "bold",
        textAlign: "center",
        fontSize: "19px",
        borderRight: "1px solid #cccccc",
        position: "absolute",
      };

  const outlook1Styles = {
    ...outlookCommonStyles,
    top: "0px",
    left: "0px",
  };

  const outlook2Styles = bottomContent
    ? {
        ...outlookCommonStyles,
        left: "176px",
      }
    : {
        ...outlookCommonStyles,
        marginLeft: "20px",
        top: "0px",
        left: "12.5vw",
      };

  const outlook3Styles = bottomContent
    ? {
        ...outlookCommonStyles,
        left: "352px",
      }
    : {
        ...outlookCommonStyles,
        marginLeft: "40px",
        left: "25vw",
      };

  const dayStyles = bottomContent
    ? {
        borderRight: "1px solid #0a2b62",
        background: "#1b4386",
        fontSize: "26px",
        textAlign: "center",
        letterSpacing: "-1px",
        color: "#ffffff",
        height: "40px",
        borderBottom: "2px solid #0a2b62",
        width: "175px",
      }
    : {
        background: "#1b4386",
        fontSize: "32px",
        textAlign: "center",
        letterSpacing: "-1px",
        color: "#ffffff",
        height: "44px",
        borderBottom: "1px solid #cccccc",
        borderTop: "1px solid #cccccc",
        borderRight: "1px solid #cccccc",
        borderLeft: "1px solid #cccccc",
        width: "14vw",
      };

  const outlookWeatherIconStyles = bottomContent
    ? { width: "80px", height: "80px", margin: "15px 0px 10px 46px" }
    : {
        marginTop: "30px",
        width: "120px",
        height: "120px",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
        overflow: "hidden",
        marginLeft: "auto",
        marginRight: "auto",
      };

  const outlookConditionStyles = bottomContent
    ? {}
    : {
        marginTop: "10px",
        fontSize: "20px",
        fontWeight: "normal",
        color: "#333333",
      };

  const outlookHighLowStyles = bottomContent
    ? { display: "none" }
    : {
        marginTop: "10px",
        fontSize: "20px",
        fontWeight: "normal",
        color: "#333333",
      };

  const outlookImage = [
    getConditionImageURL(forecastDays[0].condition, weatherImageSuffix),
    getConditionImageURL(forecastDays[1].condition, weatherImageSuffix),
    getConditionImageURL(forecastDays[2].condition, weatherImageSuffix),
  ];
  return (
    <div className="WeatherSlide" style={slideStyles}>
      <div className="CurrentConditions" style={currentConditionsStyles}>
        <span style={currentConditionsHeaderStyles}>Current Conditions</span>
        <div>
          <div style={currentConditionsImageStyles}>
            <img src={currentConditionImage} alt="" />
          </div>
          <div style={{ display: "inline-block", verticalAlign: "top" }}>
            <div style={temperatureStyles}>{currentTemperature}</div>
            <div style={conditionStyles}>{currentCondition}</div>
          </div>

          <div style={windStyles}>
            {currentWind ? `Wind: ${currentWind}` : ""}
          </div>
          <div style={humidityStyles}>
            {currentHumidity ? `Humidity: ${currentHumidity}` : ""}
          </div>
        </div>
      </div>
      <div style={outlookMainStyles}>
        <div style={outlook1Styles}>
          <div style={dayStyles}>{forecastDays[0].name}</div>
          <div style={outlookWeatherIconStyles}>
            <img width="100%" src={outlookImage[0]} alt="" />
          </div>
          <div style={outlookConditionStyles}>{forecastDays[0].condition}</div>
          <div style={outlookHighLowStyles}>{`${forecastDays[0].high}${
            forecastDays[0].pop ? " " + forecastDays[0].pop : ""
          }`}</div>
        </div>
        <div style={outlook2Styles}>
          <div style={dayStyles}>{forecastDays[1].name}</div>
          <div style={outlookWeatherIconStyles}>
            <img width="100%" src={outlookImage[1]} alt="" />
          </div>
          <div style={outlookConditionStyles}>{forecastDays[1].condition}</div>
          <div style={outlookHighLowStyles}>{`${forecastDays[1].high}${
            forecastDays[1].pop ? " " + forecastDays[1].pop : ""
          }`}</div>
        </div>
        <div style={outlook3Styles}>
          <div style={dayStyles}>{forecastDays[2].name}</div>
          <div style={outlookWeatherIconStyles}>
            <img width="100%" src={outlookImage[2]} alt="" />
          </div>
          <div style={outlookConditionStyles}>{forecastDays[2].condition}</div>
          <div style={outlookHighLowStyles}>{`${forecastDays[2].high}${
            forecastDays[2].pop ? " " + forecastDays[2].pop : ""
          }`}</div>
        </div>
      </div>
    </div>
  );
}

export default WeatherSlide;
