import React from "react";
import ColorPicker from "../../utils/ColorPicker";
import moment from "moment-timezone";
import { getAllIanaWindowsMap } from "windows-iana";
import CustomCheckbox from "../misc/CustomCheckbox";

function TimeProperties(props) {
  const timeZones = Array.from(getAllIanaWindowsMap(), ([name, value]) => ({
    name,
    value,
  }))
    .map((item, idx) => {
      return {
        name: item.name,
        value: item.value,
        offset: moment.tz(item.name).format("Z"),
        currentTime: moment.tz(item.name).format("LTS"),
      };
    })
    .sort((a, b) => {
      if (a.offset < b.offset) return -1;
      if (a.offset > b.offset) return 1;
      return 0;
    });

  return (
    <div>
      <div className="addslidesectiontopbar">Style</div>
      <div className="addslidesection grid-fit-2">
        <label>Background Colour:</label>
        <ColorPicker
          color={props.slide.time_background_colour}
          onChange={(color, e) =>
            props.updateSlide("time_background_colour", color.hex)
          }
        />

        <label>Font Colour:</label>
        <ColorPicker
          color={props.slide.time_font_colour}
          onChange={(color, e) =>
            props.updateSlide("time_font_colour", color.hex)
          }
        />
        <label>Font Size</label>
        <input
          type="text"
          value={props.slide.time_font_size}
          onChange={(e) => props.updateSlide("time_font_size", e.target.value)}
        />
      </div>
      <div className="addslidesectiontopbar">Content</div>
      <div className="grid-fit-2">
        <label>Time Zone:</label>
        <select
          value={props.slide.time_zone_info_id}
          onChange={(e) => {
            props.updateSlide("time_zone_info_id", e.target.value);
          }}
        >
          {timeZones.map((item, idx) => {
            return (
              <option
                value={item.value}
              >{`(GMT ${item.offset}) ${item.value} - ${item.currentTime}`}</option>
            );
          })}
        </select>
      </div>
      <div className="addslidesection grid-fit-4">
        <label>Show Day:</label>
        <CustomCheckbox
          checked={
            props.slide.time_show_day !== undefined
              ? props.slide.time_show_day
              : false
          }
          onChange={() =>
            props.updateSlide("time_show_day", !props.slide.time_show_day)
          }
        />

        <label>Short Form (e.g. Sun):</label>
        <CustomCheckbox
          checked={
            props.slide.time_day_short !== undefined
              ? props.slide.time_day_short
              : false
          }
          onChange={() =>
            props.updateSlide("time_day_short", !props.slide.time_day_short)
          }
        />

        <label>Show Year:</label>
        <CustomCheckbox
          checked={
            props.slide.time_show_year !== undefined
              ? props.slide.time_show_year
              : false
          }
          onChange={() =>
            props.updateSlide("time_show_year", !props.slide.time_show_year)
          }
        />
        <div></div>
        <div></div>

        <label>Show Month:</label>
        <CustomCheckbox
          checked={
            props.slide.time_show_month !== undefined
              ? props.slide.time_show_month
              : false
          }
          onChange={() =>
            props.updateSlide("time_show_month", !props.slide.time_show_month)
          }
        />

        <label>Short Form (e.g. Jan):</label>
        <CustomCheckbox
          checked={
            props.slide.time_month_short !== undefined
              ? props.slide.time_month_short
              : false
          }
          onChange={() =>
            props.updateSlide("time_month_short", !props.slide.time_month_short)
          }
        />

        <label>Show Numeric Day:</label>
        <CustomCheckbox
          checked={
            props.slide.time_show_numeric_day !== undefined
              ? props.slide.time_show_numeric_day
              : false
          }
          onChange={() =>
            props.updateSlide(
              "time_show_numeric_day",
              !props.slide.time_show_numeric_day
            )
          }
        />
        <div></div>
        <div></div>

        <label>Show Time:</label>
        <CustomCheckbox
          checked={
            props.slide.time_show_time !== undefined
              ? props.slide.time_show_time
              : false
          }
          onChange={() =>
            props.updateSlide("time_show_time", !props.slide.time_show_time)
          }
        />

        <label>24 Hour Clock:</label>
        <CustomCheckbox
          checked={
            props.slide.time_24_hour_clock !== undefined
              ? props.slide.time_24_hour_clock
              : false
          }
          onChange={() =>
            props.updateSlide(
              "time_24_hour_clock",
              !props.slide.time_24_hour_clock
            )
          }
        />

        <label>Don't Show Seconds:</label>
        <CustomCheckbox
          checked={
            props.slide.time_remove_seconds !== undefined
              ? props.slide.time_remove_seconds
              : false
          }
          onChange={() =>
            props.updateSlide(
              "time_remove_seconds",
              !props.slide.time_remove_seconds
            )
          }
        />
      </div>
    </div>
  );
}

export default TimeProperties;
