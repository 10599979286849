import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  setSelectedArea,
  setSelectedSlide,
  setSelectedChannel,
} from "../../actions/selectionActions";
import axios from "axios";
import { getFriendlyName } from "../../utils/slideHelpers";
import { canArchiveSlide, canEditSlide } from "../../utils/roleHelpers";
import { DEFAULT_CHANNELS_PER_PAGE } from "../../utils/globalSettings";
import CustomCheckbox from "../misc/CustomCheckbox";
import Modal from "react-modal";

function Matrix(props) {
  const auth = useSelector((state) => state.auth);
  const { user } = auth;
  const selectedPortal = useSelector(
    (state) => state.selection.portal || user.PortalId
  );
  const [slides, setSlides] = useState([]);
  const [channels, setChannels] = useState([]);
  const [areas, setAreas] = useState([]);

  const [page, setPage] = useState(0);
  const [sortProperty, setSortProperty] = useState("name");
  const [sortDirection, setSortDirection] = useState(1);

  const [loaded, setLoaded] = useState(false);

  const selectedArea = useSelector((state) => state.selection.area);
  const [useSlidesFromArea, setUseSlidesFromArea] = useState(null);
  const dispatch = useDispatch();

  const [jumpModalIsOpen, setJumpModalIsOpen] = useState(false);

  const sortSlides = (property) => {
    //if already sorted by this property, reverse sort direction
    const direction = sortProperty === property ? sortDirection * -1 : 1;
    setSortProperty(property);
    setSortDirection(direction);
  };

  const sortedSlides = useCallback(
    (slides) => {
      if (sortProperty === "type") {
        return slides.sort((a, b) => {
          return (
            getFriendlyName(a.type).localeCompare(getFriendlyName(b.type)) *
            sortDirection
          );
        });
      }
      return slides.sort((a, b) => {
        return a[sortProperty].localeCompare(b[sortProperty]) * sortDirection;
      });
    },
    [sortDirection, sortProperty]
  );

  const slidesToDisplay = useCallback(() => {
    if (!selectedArea) return [];
    let areaToUse =
      selectedArea.useSlidesFrom &&
      selectedArea.useSlidesFrom !== "AAAAAAAAAAAAAAAAAAAAAA=="
        ? useSlidesFromArea
        : selectedArea;
    console.log(areaToUse);
    return sortedSlides(slides).filter((slide) => {
      return areaToUse && areaToUse.UsableSlideIDs
        ? areaToUse.UsableSlideIDs.includes(slide._id)
        : false;
    });
  }, [selectedArea, slides, sortedSlides]);

  const jumpModalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const getUseSlidesFromArea = (area) => {
    const useFrom = areas.find((a) => a._id === area.useSlidesFrom);
    return useFrom;
  };

  const sortMainFirst = (a, b) => {
    const regex = /\bmain\b/i;
    const aContainsMain = regex.test(a.name);
    const bContainsMain = regex.test(b.name);

    if (aContainsMain && !bContainsMain) {
      return -1; // a comes first
    } else if (!aContainsMain && bContainsMain) {
      return 1; // b comes first
    } else if (aContainsMain && bContainsMain) {
      return a.name.length - b.name.length; // sort by name length
    } else {
      return 0; // maintain the original order
    }
  };

  useEffect(() => {
    console.log("Main useEffect!");
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    //only use the selected portal if user is authorized to view other portals
    const portal = user.isSuperUser ? selectedPortal : user.PortalId;
    console.log(portal);
    axios
      .post(
        "/api/channels/getchannelsinorder",
        {
          PortalId: portal,
        },
        { cancelToken: source.token }
      )
      .then((channels) => {
        setChannels(channels.data);
        if (portal === undefined) {
          setLoaded(true);
          return;
        }
        axios
          .post(
            "/api/areas/getareas",
            { PortalId: portal },
            { cancelToken: source.token }
          )
          .then((areas) => {
            areas.data.sort(sortMainFirst);
            setAreas(areas.data);
            dispatch(setSelectedArea(areas.data[0]));
            if (
              areas.data[0].useSlidesFrom &&
              areas.data[0].useSlidesFrom !== "AAAAAAAAAAAAAAAAAAAAAA=="
            ) {
              setUseSlidesFromArea(getUseSlidesFromArea(areas.data[0]));
            }

            axios
              .post(
                "/api/slides/getslides",
                {
                  PortalId: portal,
                  archived: false,
                },
                { cancelToken: source.token }
              )
              .then((slides) => {
                setSlides(slides.data);
                setLoaded(true);
              })
              .catch((err) => {
                if (axios.isCancel(err)) {
                  return "axios request cancelled";
                }
                return err;
              });
          })
          .catch((err) => {
            if (axios.isCancel(err)) {
              return "axios request cancelled";
            }
            return err;
          });
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          return "axios request cancelled";
        }
        return err;
      });

    return () => source.cancel("axios request cancelled");
  }, [dispatch, selectedPortal, user.PortalId, user.isSuperUser]);

  const style = { archiveColumn: { width: "100px" } };
  const perPage = user.channelsPerPage || DEFAULT_CHANNELS_PER_PAGE;
  const channelsInArea = useCallback(() => {
    if (!selectedArea) return [];
    return channels.filter((channel) =>
      channel.selectedAreas.includes(selectedArea._id)
    );
  }, [selectedArea, channels]);

  const getVisibleChannels = () => {
    const start = 0 + perPage * page;
    const end = perPage + perPage * page;
    return channelsInArea()
      .map((channel, idx) => {
        if (idx === 0) channel.first = true;
        if (idx === channelsInArea().length - 1) channel.last = true;
        return channel;
      })
      .slice(start, end);
  };

  const isSlideSelected = (channel, slide) => {
    if (channel.selectedSlides) {
      const selectedSlides = channel.selectedSlides.find(
        (ss) => ss.AreaID === selectedArea._id
      );
      const selectedSlideIDs = selectedSlides ? selectedSlides.SlideIDs : [];
      if (selectedSlideIDs.find((id) => id === slide._id)) return true;
    }
    return false;
  };

  const toggleSlide = (channel, slide) => {
    axios
      .patch("/api/channels/toggleSlide", {
        channel,
        area: selectedArea,
        slide,
      })
      .then((response) => {
        const resChannel = response.data;
        console.log(resChannel);
        const newChannels = [...channels].map((c) => {
          if (c._id === resChannel._id) {
            return resChannel;
          }
          return c;
        });

        setChannels(newChannels);
      })
      .catch((error) => alert("error: " + error.response.data));
  };

  const getScheduleIcon = (slide) => {
    const now = new Date();
    const start = new Date(slide.scheduleStart);
    const end = new Date(slide.scheduleEnd);
    if (slide.alwaysDisplay)
      return (
        <i
          title="This slide is set to always display."
          className="material-icons noselect"
        >
          calendar_today
        </i>
      );
    if (slide.alwaysDisplayAfterStartDate && now >= start)
      return (
        <i
          title="This slide is displaying because it is past the set start date and set to always display after that."
          className="material-icons noselect"
        >
          date_range
        </i>
      );
    if (now >= start && now <= end)
      return (
        <i
          title="This slide is displaying because it is between the set start date and end date."
          className="material-icons noselect"
        >
          today
        </i>
      );
    return (
      <i
        title="This slide is not displaying because it is outside of the scheduled time."
        className="material-icons noselect"
      >
        event_busy
      </i>
    );
  };

  const ArchiveSlide = (slide) => {
    if (window.confirm("Do you really want to archive this slide?")) {
      axios
        .patch("/api/slides/archiveSlide", {
          _id: slide._id,
          PortalId: slide.PortalId,
          update: {
            archived: true,
          },
        })
        .then(() => {
          //remove slide from view now that it is archived.
          setSlides(
            slides.filter((s) => {
              return slide._id !== s._id;
            })
          );
        });
    }
  };

  //this toggles on all channels, rather than only adding. It might be better if it turns on all channels if any are off and only turns off if they're all on.
  //TODO add some loading indicator since this takes about a second even on local machine.
  const AddToAllChannels = (slide) => {
    const channelsInArea = channels.filter((channel) =>
      channel.selectedAreas.includes(selectedArea._id)
    );

    axios
      .patch("/api/channels/addSlideToAllChannels", {
        channels: channelsInArea,
        area: selectedArea,
        slide,
      })
      .then((res) => {
        setChannels(res.data);
      });
  };

  const goToSlideSort = (channel) => {
    dispatch(setSelectedChannel(channel));
    dispatch(setSelectedArea(selectedArea));
    props.history.push("/dashboard/sortslides");
  };

  const pairChannel = (channel) => {
    let pairCode = prompt("Please enter your pair code");
    if (pairCode) {
      axios
        .post("/pairDevice", { pairCode: pairCode, channel: channel })
        .then((res) => {
          console.log(res);
        });
    }
  };

  return (
    <div className="container valign-wrapper">
      <div>
        <div>
          {loaded && areas.length > 0 ? (
            <>
              <div id="matrixtopbuttons">
                {selectedArea ? (
                  <Link to="/dashboard/chooseslidetype">
                    <input
                      type="submit"
                      name="btnCreateSlide"
                      value="Create Slide"
                      id="btnCreateSlide"
                      className="button_green largebutton"
                    />
                  </Link>
                ) : (
                  <div>You must add an area before you can create slides.</div>
                )}
              </div>
              <div id="AreaSelect">
                <p>Please choose your screen area:</p>
                <select
                  onChange={(e) => {
                    setPage(0);
                    const newArea = areas.find(
                      (area) => area._id === e.target.value
                    );
                    dispatch(setSelectedArea(newArea));
                    if (
                      newArea.useSlidesFrom &&
                      newArea.useSlidesFrom !== "AAAAAAAAAAAAAAAAAAAAAA=="
                    ) {
                      setUseSlidesFromArea(getUseSlidesFromArea(newArea));
                    }
                  }}
                >
                  {areas.map((area) => {
                    return (
                      <option key={area._id} value={area._id}>
                        {area.name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <span>
                <strong>Note:</strong> Changes on this page are now saved
                instantly. As soon as you see the checkmark appear/disappear, it
                is done.
              </span>
              <div id="MatrixContainer">
                <table id="MatrixTable">
                  <thead
                    style={{
                      display: "table",
                      width: "100%",
                      tableLayout: "fixed",
                      height: "56px",
                    }}
                  >
                    <tr>
                      <th style={style.archiveColumn}>Actions</th>
                      <th
                        onClick={() => {
                          sortSlides("name");
                        }}
                      >
                        Slide Name
                      </th>
                      <th
                        onClick={() => {
                          sortSlides("lastModified");
                        }}
                      >
                        Last Modified
                      </th>
                      <th
                        onClick={() => {
                          sortSlides("type");
                        }}
                      >
                        Type
                      </th>
                      {getVisibleChannels().map((channel, idx) => (
                        <th key={idx}>
                          {
                            /*if this is the first visible channel but not the first channel for this area, display the prev page button*/
                            !channel.first && idx === 0 ? (
                              <i
                                onClick={() => setPage(page - 1)}
                                className="material-icons noselect"
                              >
                                arrow_left
                              </i>
                            ) : (
                              ""
                            )
                          }
                          <p style={{ display: "inline-block" }}>
                            <span
                              className="channel-name"
                              title={`Short ID: ${channel.shortID}`}
                            >
                              {channel.name}
                            </span>
                            <Link
                              style={{ color: "white" }}
                              target="_blank"
                              to={`/DesktopModules/RGI.VeMedia2/channels/${channel.PortalId}/${channel.originalName}/Default.aspx?TestMode=true`}
                            >
                              <i
                                title={`Preview ${channel.name}`}
                                className="material-icons noselect clickable"
                              >
                                preview
                              </i>
                            </Link>
                            {slidesToDisplay().length > 0 ? (
                              <i
                                title={`Change Slide Order in ${channel.name}`}
                                className="material-icons noselect clickable"
                                onClick={() => goToSlideSort(channel)}
                              >
                                sort
                              </i>
                            ) : null}
                            <i
                              title={`Pair ${channel.name} to Fire TV app`}
                              className="material-icons noselect clickable"
                              onClick={() => pairChannel(channel)}
                              style={{ display: "none" }}
                            >
                              link
                            </i>
                          </p>

                          {
                            /*if this is the last visible channel but not the last channel for this area, display the next page button*/
                            !channel.last &&
                            idx === getVisibleChannels().length - 1 ? (
                              <i
                                onClick={() => setPage(page + 1)}
                                className="material-icons noselect"
                              >
                                arrow_right
                              </i>
                            ) : (
                              ""
                            )
                          }
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody
                    style={{
                      display: "block",
                      height: "calc(100vh - 440px)",
                      minHeight: "165px",
                      overflow: "auto",
                    }}
                  >
                    {slidesToDisplay().map((slide, idx) => (
                      <tr
                        key={idx}
                        style={{
                          display: "table",
                          width: "100%",
                          tableLayout: "fixed",
                        }}
                      >
                        <td style={style.archiveColumn}>
                          {canArchiveSlide(user, slide) ? (
                            <i
                              title={`Archive slide ${slide.name}`}
                              className="material-icons noselect clickable"
                              onClick={() => ArchiveSlide(slide)}
                            >
                              archive
                            </i>
                          ) : null}

                          {canEditSlide(user, slide) ? (
                            <Link
                              to="/dashboard/EditSlide"
                              onClick={() => {
                                dispatch(setSelectedSlide(slide));
                              }}
                            >
                              <i
                                title={`Edit slide ${slide.name}`}
                                className="material-icons noselect clickable"
                              >
                                edit
                              </i>
                            </Link>
                          ) : null}
                          {getVisibleChannels()[0] ? (
                            <Link
                              target="_blank"
                              to={
                                "/PreviewSlide?portal=" +
                                encodeURIComponent(
                                  user.isSuperUser
                                    ? selectedPortal
                                    : user.PortalId
                                ) +
                                "&slide=" +
                                encodeURIComponent(slide._id) +
                                "&channel=" +
                                encodeURIComponent(
                                  getVisibleChannels()[0]._id
                                ) +
                                "&area=" +
                                encodeURIComponent(selectedArea._id)
                              }
                            >
                              <i
                                title={`Preview slide ${slide.name}`}
                                className="material-icons noselect clickable"
                              >
                                preview
                              </i>
                            </Link>
                          ) : (
                            <i
                              title="You must add this area to a channel to preview its slides."
                              className="material-icons noselect icon-disabled"
                            >
                              preview
                            </i>
                          )}

                          <i
                            title={`Toggle slide ${slide.name} on all channels`}
                            className="material-icons noselect clickable"
                            onClick={() => AddToAllChannels(slide)}
                          >
                            add_task
                          </i>
                        </td>
                        <td>
                          {getScheduleIcon(slide)}
                          {slide.name}
                        </td>
                        <td>{new Date(slide.lastModified).toLocaleString()}</td>
                        <td>{getFriendlyName(slide.type)}</td>

                        {getVisibleChannels().map((channel, idx) => (
                          <td key={idx}>
                            <CustomCheckbox
                              onChange={() => toggleSlide(channel, slide)}
                              checked={isSlideSelected(channel, slide)}
                            />
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          ) : loaded ? (
            <>
              You don't have access to any areas. Contact us if you believe this
              to be in error.
            </>
          ) : null}
        </div>
      </div>
      <div className="MatrixBottomButtons">
        {selectedArea && channelsInArea().length > perPage ? (
          <>
            <input
              type="button"
              onClick={() => setJumpModalIsOpen(true)}
              value="Jump to Channel"
            />
            <Link to="/dashboard/archive">View Archive</Link>
          </>
        ) : null}
      </div>

      <Modal
        isOpen={jumpModalIsOpen}
        contentLabel="Example Modal"
        style={jumpModalStyles}
        onRequestClose={() => setJumpModalIsOpen(false)}
        shouldCloseOnOverlayClick={true}
      >
        <h1>Jump to Channel</h1>
        <div className={`grid-fit-${perPage}`}>
          {selectedArea
            ? channels.map((channel, idx) => (
                <input
                  key={idx}
                  type="button"
                  onClick={() => {
                    setPage(Math.floor(idx / perPage));
                    setJumpModalIsOpen(false);
                  }}
                  value={channel.name}
                  style={{
                    border: "none",
                    background: "transparent",
                    color: "#A21917",
                    cursor: "pointer",
                  }}
                />
              ))
            : null}
        </div>
      </Modal>
    </div>
  );
}

export default Matrix;
