import React, { useEffect, useState } from "react";

export function VideoSlide({ slide, height, width, active }) {
  const [slideContent, setSlideContent] = useState(null);
  const [noExt] = useState(slide.video_path.replace(/\.[^/.]+$/, ""));
  const [ext] = useState(slide.video_path.match(/[^/.]+$/, ""));
  console.log(slide.video_path);

  useEffect(() => {
    //only place the video when the slide is active, so it will only play at that time.
    if (!active) setSlideContent(null);
    else if (slide.video_html5) {
      setSlideContent(
        <video id={slide._id} width={width} height={height} autoPlay muted loop>
          <source src={slide.video_path} type={`video/${ext}`} />
          {false && ext !== "mp4" ? (
            <source src={`${noExt}.mp4`} type="video/mp4" />
          ) : null}
          {false && ext !== "webm" ? (
            <source src={`${noExt}.webm`} type="video/webm" />
          ) : null}
          {false && ext !== "ogg" ? (
            <source src={`${noExt}.ogg`} type="application/ogg" />
          ) : null}
          {false && ext !== "ogv" ? (
            <source src={`${noExt}.ogv`} type="video/ogg" />
          ) : null}
          Your browser does not support the video tag.
        </video>
      );
    } else
      setSlideContent(
        <object
          id="MediaPlayer"
          width={width}
          height={height}
          classID="CLSID:22D6F312-B0F6-11D0-94AB-0080C74C7E95"
          standby="Loading Windows Media Player components..."
          type="application/x-oleobject\"
        >
          <param name="FileName" value={slide.video_path} />
          <param name="autostart" value="true" />
          <param name="ShowControls" value="false" />
          <param name="ShowStatusBar" value="false" />
          <param name="ShowDisplay" value="false" />
          <embed
            type="application/x-mplayer2"
            src={slide.video_path}
            name="MediaPlayer"
            width={width}
            height={height}
            showcontrols="1"
            showstatusbar="0"
            showdisplay="0"
            autostart="0"
          ></embed>
        </object>
      );
  }, [
    active,
    height,
    noExt,
    ext,
    slide._id,
    slide.video_html5,
    width,
    slide.video_path,
  ]);

  return <div style={{ height, width }}>{slideContent}</div>;
}

export default VideoSlide;
