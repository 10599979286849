import React, { useState, useEffect, useCallback } from "react";
import moment from "moment-timezone";
import { findOneIana } from "windows-iana";

export function TimeSlide({ slide, height, width }) {
  const [time, setTime] = useState("");
  const slideStyles = {
    float: "right",
    fontSize: slide.time_font_size || "30px",
    fontWeight: "bold",
    marginRight: "10px",
    letterSpacing: "-1px",
    color: slide.time_font_colour,
  };

  const getFormatString = useCallback(() => {
    if (slide.time_format_string) return slide.time_format_string;
    let formatString = "";
    if (slide.time_show_day) {
      if (slide.time_day_short) {
        formatString += "ddd, ";
      } else formatString += "dddd, ";
    }

    if (slide.time_show_month) {
      if (slide.time_month_short) {
        formatString += "MMM ";
      } else formatString += "MMMM ";
    }

    if (slide.time_show_numeric_day) {
      formatString += "DD ";
    }

    if (slide.time_show_year) {
      formatString += "YYYY ";
    }

    if (slide.time_show_time) {
      if (slide.time_24_hour_clock) {
        formatString += `H:mm${slide.time_remove_seconds ? "" : ":ss"}`;
      } else formatString += `h:mm${slide.time_remove_seconds ? "" : ":ss"} A`;
    }
    return formatString;
  }, [slide]);
  useEffect(() => {
    const id = setInterval(
      () =>
        setTime(
          moment()
            .tz(findOneIana(slide.time_zone_info_id))
            .format(getFormatString())
        ),
      1000
    );
    return () => clearInterval(id);
  }, [getFormatString, slide.time_zone_info_id]);
  return <div style={slideStyles}>{time}</div>;
}

export default TimeSlide;
