import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { isNotInPast, getDate } from "../../utils/dateFunctions";
import CustomCheckbox from "../misc/CustomCheckbox";

function GlobalProperties(props) {
  return (
    <div>
      <div className="addslidesectiontopbar">General Information</div>
      <div className="addslidesection">
        <input
          type="text"
          placeholder="Name of the slide"
          value={props.slide.name !== undefined ? props.slide.name : ""}
          onChange={(e) => {
            props.updateSlide("name", e.target.value);
          }}
        />
        <input
          type="text"
          placeholder="Duration in seconds"
          value={props.slide.duration !== undefined ? props.slide.duration : ""}
          onChange={(e) => {
            let value = parseInt(e.target.value, 10) || 0;
            props.updateSlide("duration", value);
          }}
        />
      </div>
      <div className="addslidesectiontopbar">Schedule this slide</div>
      <div className="addslidesection schedule">
        <div className="note">
          *Filling in both start and end date will automatically add and remove
          the slide on selected dates.
        </div>
        <div className="main">
          <div>
            <DatePicker
              selected={getDate(props.slide.scheduleStart)}
              onChange={(date) => {
                props.updateSlide("scheduleStart", date);
              }}
              placeholderText="Choose start date"
              showYearDropdown
              showMonthDropdown
              startDate={Date.now()}
              filterDate={isNotInPast}
            />
            <i
              title="Clear"
              className="material-icons noselect clickable"
              onClick={() => {
                props.updateSlide("scheduleStart", null);
              }}
            >
              clear
            </i>
          </div>
          <div>
            <CustomCheckbox
              id="chkSlide_ScheduleAlwaysDisplayAfterStartDate"
              name="chkSlide_ScheduleAlwaysDisplayAfterStartDate"
              checked={
                props.slide.alwaysDisplayAfterStartDate !== undefined
                  ? props.slide.alwaysDisplayAfterStartDate
                  : false
              }
              onChange={() =>
                props.updateSlide(
                  "alwaysDisplayAfterStartDate",
                  !props.slide.alwaysDisplayAfterStartDate
                )
              }
            />
            <label
              htmlFor="chkSlide_ScheduleAlwaysDisplayAfterStartDate"
              id="lblSlide_ScheduleAlwaysDisplayAfterStartDate"
            >
              Display from start date: Until manually removed
            </label>
          </div>
          <div>
            <DatePicker
              selected={getDate(props.slide.scheduleEnd)}
              onChange={(date) => {
                props.updateSlide("scheduleEnd", date);
              }}
              placeholderText="Choose end date"
              showYearDropdown
              showMonthDropdown
              filterDate={isNotInPast}
            />
            <i
              title="Clear"
              className="material-icons noselect clickable"
              onClick={() => {
                props.updateSlide("scheduleEnd", null);
              }}
            >
              clear
            </i>
          </div>
          <div>
            <CustomCheckbox
              id="chkSlide_ScheduleAlwaysDisplay"
              name="chkSlide_ScheduleAlwaysDisplay"
              checked={
                props.slide.alwaysDisplay !== undefined
                  ? props.slide.alwaysDisplay
                  : false
              }
              onChange={() =>
                props.updateSlide("alwaysDisplay", !props.slide.alwaysDisplay)
              }
            />
            <label
              htmlFor="chkSlide_ScheduleAlwaysDisplay"
              id="lblSlide_ScheduleAlwaysDisplay"
            >
              Always display: Until manually removed
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GlobalProperties;
