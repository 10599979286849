import React from "react";

function ContentLibraryInstructions() {
  return (
    <div>
      <h2>Content Library</h2>
      <p>
        You can find images to use for your slides here. It is accessed through
        a link in the header.
      </p>
    </div>
  );
}

export default ContentLibraryInstructions;
