import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setSelectedPortal } from "../../actions/selectionActions";
import axios from "axios";

function PortalSwitcher() {
  const auth = useSelector((state) => state.auth);
  const { user } = auth;
  const selectedPortal = useSelector(
    (state) => state.selection.portal || user.PortalId
  );
  const dispatch = useDispatch();
  const [portals, setPortals] = useState([]);

  useEffect(() => {
    const cancelToken = axios.CancelToken;
    const source = cancelToken.source();
    axios
      .get("/api/portals/getportals", { cancelToken: source.token })
      .then((result) => {
        setPortals(
          result.data.sort((a, b) => a.PortalName.localeCompare(b.PortalName))
        );
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          return "axios request cancelled";
        }
        return err;
      });
    return () => source.cancel("axios request cancelled");
  }, [user]);

  return (
    <div>
      {user.isSuperUser ? (
        <>
          <span>Quick portal select (superuser only):</span>
          <select
            value={selectedPortal || user.PortalId}
            onChange={(e) => dispatch(setSelectedPortal(e.target.value))}
          >
            {portals.map((item, idx) => (
              <option key={idx} value={item.PortalId}>
                {item.PortalName}
              </option>
            ))}
          </select>
        </>
      ) : null}
    </div>
  );
}

export default PortalSwitcher;
