import React, { useState, useCallback, useEffect } from "react";

export function WPRSSSlide({ slide, width, height }) {
  const [activeIndex, setActiveIndex] = useState(0);
  var parser = new DOMParser();
  var htmlDoc = parser.parseFromString(slide.wp_rss_url, "text/html");

  const nodes = Array.from(htmlDoc.getElementsByTagName("body")[0].childNodes);
  const divs = nodes.slice(1, slide.wp_rss_items_to_display + 1);

  const activeStyles = {
    position: "absolute",
    opacity: "1",
    transition: "opacity 0.5s",
    width: "inherit",
    height: "inherit",
  };

  const inactiveStyles = { ...activeStyles, opacity: "0" };

  const slideDuration = slide.wp_rss_transition_delay
    ? slide.wp_rss_transition_delay * 1000
    : (slide.duration / divs.length) * 1000;

  //once loaded, set the timer to switch slides.
  const switchSlides = useCallback(
    (current) => {
      const next =
        current + 1 > slide.wp_rss_items_to_display - 1 ? 0 : current + 1;
      setActiveIndex(next);
      setTimeout(() => switchSlides(next), slideDuration);
    },
    [slide.wp_rss_items_to_display, slideDuration]
  );
  useEffect(() => {
    console.log(divs);
    if (slide.rss_type !== "notransition")
      setTimeout(() => switchSlides(0), slideDuration);
  }, [slide.rss_type, slideDuration, switchSlides]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {divs.map((item, idx) => {
        return (
          <div
            style={idx === activeIndex ? activeStyles : inactiveStyles}
            key={idx}
            dangerouslySetInnerHTML={{ __html: item.outerHTML }}
          ></div>
        );
      })}
    </div>
  );
}

export default WPRSSSlide;
