import React, { useEffect } from "react";
import { SketchPicker } from "react-color";
import Modal from "react-modal";
Modal.setAppElement("#root");
function ColorPicker(props) {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  //set default text color to black for everything, unless another color is passed in
  useEffect(() => {
    if (!props.color) {
      props.onChange({ hex: props.defaultColor || "#000" }, null);
    }
  }, [props]);
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const closeModal = () => setIsOpen(false);

  const height = "24px";

  return (
    <div
      style={{
        border: "1px solid black",
        width: "60px",
        height: height,
        backgroundColor: "#eee",
        cursor: "pointer",
      }}
    >
      <div onClick={() => setIsOpen(true)}>
        <div
          style={{
            backgroundColor: props.color,
            height: height,
            width: "40px",
            display: "inline-block",
            verticalAlign: "top",
          }}
        ></div>
        <div
          style={{
            height: height,
            width: "20px",
            lineHeight: height,
            fontSize: "16px",
            display: "inline-block",
            verticalAlign: "top",
          }}
        >
          ▼
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        contentLabel="Example Modal"
        style={customStyles}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
      >
        <h1>Select Colour</h1>
        <SketchPicker
          disableAlpha={true}
          color={props.color}
          onChange={props.onChange}
        />
      </Modal>
    </div>
  );
}

export default ColorPicker;
