import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getFriendlyName } from "../../utils/slideHelpers";
import axios from "axios";
const isEmpty = require("is-empty");

function Archive(props) {
  const auth = useSelector((state) => state.auth);
  const { user } = auth;
  const selectedPortal = useSelector((state) => state.selection.portal);
  const portal =
    user.isSuperUser && selectedPortal && selectedPortal !== undefined
      ? selectedPortal
      : user.PortalId;

  const [slides, setSlides] = useState([]);
  const [filter, setFilter] = useState("");

  const headerStyles = {
    backgroundColor: "#A01A17",
    color: "white",
    textAlign: "center",
    padding: "5px",
  };
  const cellStyles = {
    borderBottom: "1px solid black",
    borderRight: "1px solid black",
    padding: "2px",
  };

  const restoreSlide = (slide) => {
    axios
      .patch("/api/slides/updateSlide", {
        _id: slide._id,
        PortalId: portal,
        update: { archived: false },
      })
      .then(() => {
        //remove slide from view now that it is not archived.
        setSlides(
          slides.filter((s) => {
            return slide._id !== s._id;
          })
        );
      })
      .catch((e) => alert(e.response.data.error));
  };

  const deleteSlide = (slide) => {
    if (window.confirm("Do you really want to delete this slide?")) {
      axios
        .post("/api/slides/deleteSlide", {
          _id: slide._id,
          PortalId: portal,
        })
        .then(() => {
          //remove slide from view now that it is deleted.
          setSlides(
            slides.filter((s) => {
              return slide._id !== s._id;
            })
          );
        })
        .catch((e) => alert(e.response.data.error));
    }
  };

  useEffect(() => {
    axios
      .post("/api/slides/getslides", { PortalId: portal, archived: true })
      .then((res) => {
        setSlides(res.data);
      });
  }, [portal]);
  return (
    <>
      <table style={{ borderCollapse: "collapse" }}>
        <thead
          style={{
            display: "table",
            width: "100%",
            tableLayout: "fixed",
          }}
        >
          <tr>
            <th style={headerStyles}>Name</th>
            <th style={headerStyles}>Type</th>
            <th style={headerStyles}>Restore</th>
            <th style={headerStyles}>Delete</th>
          </tr>
        </thead>

        <tbody
          style={{
            display: "block",
            overflowY: "auto",
            height: "calc(100vh - 300px)",
            minHeight: "165px",
          }}
        >
          {slides
            .filter((s) => {
              if (isEmpty(filter)) {
                return true;
              }
              const regex = new RegExp(filter, "i");
              return (
                s.name.match(regex) || getFriendlyName(s.type).match(regex)
              );
            })
            .map((slide, idx) => (
              <tr
                key={idx}
                style={{
                  display: "table",
                  width: "99%",
                  tableLayout: "fixed",
                }}
              >
                <td style={{ ...cellStyles, borderLeft: "1px solid black" }}>
                  {slide.name}
                </td>
                <td style={cellStyles}>{getFriendlyName(slide.type)}</td>
                <td style={cellStyles}>
                  <input
                    type="button"
                    value="Restore"
                    className="button_lightred"
                    onClick={() => restoreSlide(slide)}
                  />
                </td>
                <td style={{ ...cellStyles, borderRight: "none" }}>
                  <input
                    type="button"
                    value="Delete"
                    className="button_red"
                    onClick={() => deleteSlide(slide)}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      <input
        className="button_lightred"
        type="button"
        value="<< Back"
        onClick={() => props.history.push("/dashboard")}
      />
      <span>Filter:</span>
      <input
        type="text"
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
      />
    </>
  );
}

export default Archive;
