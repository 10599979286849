import React from "react";
import ImageUploader from "./ImageUploader";

function ImageProperties(props) {
  return (
    <div>
      <div className="addslidesectiontopbar">Upload file</div>
      <div className="addslidesection">
        <ImageUploader
          slide={props.slide}
          updateSlide={props.updateSlide}
          property="image_path"
          setMessage={props.setMessage}
        ></ImageUploader>
      </div>
    </div>
  );
}

export default ImageProperties;
