import React from "react";
import axios from "axios";

function XMLUploader(props) {
  const onChangeHandler = (e) => {
    const data = new FormData();
    data.append("file", e.target.files[0]);
    axios
      .post(`/uploadXML/${props.slide.PortalId}`, data, {})
      .then((res) => {
        const path = res.data.location;
        props.updateSlide(props.property, path);
        alert("Upload successful!");
      })
      .catch((err) => {
        props.setMessage(err.response.data);
      });
  };
  return (
    <div>
      <div>
        <input type="file" name="file" onChange={onChangeHandler} />
      </div>
    </div>
  );
}

export default XMLUploader;
