import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import CustomCheckbox from "../misc/CustomCheckbox";
import axios from "axios";

function EditArea(props) {
  const auth = useSelector((state) => state.auth);
  const { user } = auth;
  const selectedPortal = useSelector((state) => state.selection.portal);

  const defaultArea = {
    PortalId: selectedPortal || user.PortalId,
    x: 0,
    y: 0,
    z: 0,
    width: 100,
    height: 100,
    responsive: true,
    transition: "fade",
    hide: false,
    useSlidesFrom: null,
  };

  const [area, setArea] = useState(
    useSelector((state) => state.selection.area) || defaultArea
  );
  const [areas, setAreas] = useState([]);

  const [message, setMessage] = useState("");

  const gridStyles = {
    padding: "10px",
    rowGap: "5px",
    columnGap: "5px",
    maxWidth: "970px",
  };

  const transitions = [
    { name: "Fade", value: "fade" },
    { name: "Slide", value: "slide" },
    { name: "Slide Horizontal", value: "slidehorizontal" },
    { name: "None", value: "none" },
  ];

  const saveArea = () => {
    if (area._id) {
      axios
        .patch("api/areas/updateArea", {
          _id: area._id,
          PortalId: area.PortalId,
          update: {
            ...area,
          },
        })
        .then((r) => setMessage("Save successful."))
        .catch((e) => setMessage(e.response.data.error));
    } else {
      console.log(area);
      axios
        .post("api/areas/add", { area })
        .then((r) => {
          setMessage("Save successful.");
          setArea(r.data);
        })
        .catch((e) => setMessage(e.response.data.error));
    }
  };

  useEffect(() => {
    axios
      .post("/api/areas/getareas", { PortalId: area.PortalId })
      .then((areas) => {
        setAreas(areas.data);
      });
  }, [area.PortalId]);

  if (area === undefined) {
    //if no area is selected, go back to where they can select one.
    props.history.push("/dashboard/controlpanel");
    return null;
  } else {
    return (
      <>
        <div className="controlpanelsectiontopbar">Area Information</div>
        <div className="grid-fit-2" style={gridStyles}>
          <label>Area Name:</label>
          <input
            type="text"
            value={area.name}
            onChange={(e) => setArea({ ...area, name: e.target.value })}
          />
          <label>X position:</label>
          <input
            type="text"
            value={area.x}
            onChange={(e) => setArea({ ...area, x: parseInt(e.target.value) })}
          />
          <label>Y position:</label>
          <input
            type="text"
            value={area.y}
            onChange={(e) => setArea({ ...area, y: parseInt(e.target.value) })}
          />
          <label>Width (% in responsive mode, otherwise pixels):</label>
          <input
            type="text"
            value={area.width}
            onChange={(e) =>
              setArea({ ...area, width: parseInt(e.target.value) })
            }
          />
          <label>Height:</label>
          <input
            type="text"
            value={area.height}
            onChange={(e) =>
              setArea({ ...area, height: parseInt(e.target.value) })
            }
          />
          <label>Responsive:</label>
          <CustomCheckbox
            checked={area.responsive}
            onChange={(e) => setArea({ ...area, responsive: !area.responsive })}
          />
          <label>
            Z index (area with higher value will be on top if 2 areas overlap):
          </label>
          <input
            type="text"
            value={area.z}
            onChange={(e) => setArea({ ...area, z: parseInt(e.target.value) })}
          />
          <label>Transition between slides:</label>
          <select
            value={area.transition}
            onChange={(e) => setArea({ ...area, transition: e.target.value })}
          >
            {transitions.map((t, idx) => (
              <option key={idx} value={t.value}>
                {t.name}
              </option>
            ))}
          </select>
          <label>Hide:</label>
          <CustomCheckbox
            checked={area.hide}
            onChange={(e) => setArea({ ...area, hide: !area.hide })}
          />
          <label>Use slides from:</label>
          <select
            value={area.useSlidesFrom}
            onChange={(e) =>
              setArea({ ...area, useSlidesFrom: e.target.value })
            }
          >
            <option value={null}>This area</option>
            {areas.map((item, idx) => {
              return (
                <option key={idx} value={item._id}>
                  {item.name}
                </option>
              );
            })}
          </select>
        </div>
        <input
          className="button_lightred"
          type="button"
          value="Save Changes"
          onClick={() => saveArea()}
        />
        <input
          className="button_lightred"
          type="button"
          value="<< Back"
          onClick={() => props.history.push("/dashboard/controlpanel")}
        />
        <span>{message}</span>
      </>
    );
  }
}

export default EditArea;
