import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import CustomCheckbox from "../../misc/CustomCheckbox";
import { templateTypes } from "../../../utils/templateHelpers";

function EditPortal(props) {
  const defaultPortal = {
    PortalName: "",
    subscriptionLevel: 0,
    proTemplates: null,
    customTemplates: null,
    deleted: false,
  };

  const [portal, setPortal] = useState(
    useSelector((state) => state.selection.portal) || defaultPortal
  );

  const [templates, setTemplates] = useState([]);
  const [message, setMessage] = useState("");
  const subscriptionLevels = ["Basic", "Pro"];

  useEffect(() => {
    axios.get("/api/templates/").then((result) => setTemplates(result.data));
  }, [portal._id]);

  const proTemplates = templates.filter(
    (template) => templateTypes[template.type] === "Pro"
  );
  const customTemplates = templates.filter(
    (template) => templateTypes[template.type] === "Custom"
  );

  console.log(portal);

  const savePortal = () => {
    if (portal._id) {
      axios
        .post("/api/portals/updatePortal", {
          _id: portal._id,
          update: {
            ...portal,
          },
        })
        .then((r) => setMessage("Save successful."))
        .catch((e) => setMessage(e.response.data.error));
    } else {
      axios
        .post("/api/portals/addPortal", { portal })
        .then((r) => {
          setPortal(r.data);
          setMessage("Save successful.");
        })
        .catch((e) => setMessage(e.response.data.error));
    }
  };

  return (
    <>
      <div className="grid-fit-2">
        <label>Portal Name:</label>
        <input
          type="text"
          value={portal.PortalName}
          onChange={(e) => setPortal({ ...portal, PortalName: e.target.value })}
        />
        {/*<label>Subscription Level:</label>
        <select
          value={portal.subscriptionLevel}
          onChange={(e) =>
            setPortal({
              ...portal,
              subscriptionLevel: parseInt(e.target.value),
            })
          }
        >
          {subscriptionLevels.map((item, idx) => (
            <option key={idx} value={idx}>
              {item}
            </option>
          ))}
          </select>*/}
        {/*<h2>Pro Templates</h2>
        <div></div>*/}
        {/*proTemplates.length > 0 ? (
          <>
            <div>Name</div>
            <div>Purchased</div>
            {proTemplates.map((template, idx) => (
              <React.Fragment key={idx}>
                <div key={idx}>{template.name}</div>
                <CustomCheckbox
                  checked={
                    portal.proTemplates &&
                    portal.proTemplates.includes(template._id)
                  }
                  onChange={() => {
                    let proT = portal.proTemplates
                      ? [...portal.proTemplates]
                      : [];
                    if (!proT.includes(template._id)) {
                      proT.push(template._id);
                    } else proT = proT.filter((t) => t !== template._id);
                    setPortal({ ...portal, proTemplates: proT });
                  }}
                />
              </React.Fragment>
            ))}
          </>
        ) : (
          <>
            <div>None available.</div>
            <div></div>
          </>
        )*/}
        {/*<h2>Custom Templates</h2>
        <div></div>*/}
        {/*customTemplates.length > 0 ? (
          <>
            <div>Name</div>
            <div>Purchased</div>
            {customTemplates.map((template, idx) => (
              <React.Fragment key={idx}>
                <div key={idx}>{template.name}</div>
                <CustomCheckbox
                  checked={
                    portal.customTemplates &&
                    portal.customTemplates.includes(template._id)
                  }
                  onChange={() => {
                    let proT = portal.customTemplates
                      ? [...portal.customTemplates]
                      : [];
                    if (!proT.includes(template._id)) {
                      proT.push(template._id);
                    } else proT = proT.filter((t) => t !== template._id);
                    setPortal({ ...portal, customTemplates: proT });
                  }}
                />
              </React.Fragment>
            ))}
          </>
        ) : (
          <>
            <div>None available.</div>
            <div></div>
          </>
        )*/}
      </div>
      <input type="button" value="Save" onClick={savePortal} />
      <input
        type="button"
        value="Back"
        onClick={() => props.history.push("/dashboard/webmaster/")}
      />
      {message}
    </>
  );
}

export default EditPortal;
