import React, { useRef, useState, useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";

function RTEProperties(props) {
  const styleOptions = ["Main Content", "Bottom Content", "Side Content"];
  const editorRef = useRef(null);
  const [dirty, setDirty] = useState(false);

  const htmlDecode = (input) => {
    var doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  };

  const htmlEncode = (input) => {
    return input.replace(/[\u00A0-\u9999<>&]/g, function (i) {
      return "&#" + i.charCodeAt(0) + ";";
    });
  };

  //if it has more than 5 &, assume it's encoded in the database (some coming from the old vemedia are encoded and some aren't for some reason)
  const [initialValue, setInitialValue] = useState(
    ((props.slide.rte_content && props.slide.rte_content.match(/&/g)) || [])
      .length >= 5
      ? htmlDecode(props.slide.rte_content)
      : props.slide.rte_content
  );

  const save = () => {
    if (editorRef.current) {
      const content = editorRef.current.getContent();
      setDirty(false);
      editorRef.current.setDirty(false);
      props.updateSlide("rte_content", content);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      save();
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
  }, [props.slide]);

  return (
    <div>
      <div className="addslidesectiontopbar">Style</div>
      <div className="addslidesection">
        <select
          value={props.slide.rte_type}
          onChange={(e) => {
            props.updateSlide("rte_type", e.target.value);
          }}
        >
          {styleOptions.map((item) => {
            return (
              <option key={item} value={item}>
                {item}
              </option>
            );
          })}
        </select>
      </div>

      <div className="addslidesectiontopbar">Content</div>
      <div>
        <Editor
          tinymceScriptSrc="/tinymce/tinymce.min.js"
          licenseKey="gpl"
          onInit={(evt, editor) => (editorRef.current = editor)}
          onDirty={() => setDirty(true)}
          initialValue={initialValue}
          init={{
            height: 500,
            menubar: true,
            plugins:
              "advlist autolink lists link image charmap print preview anchor searchreplace visualblocks fullscreen insertdatetime media table paste help wordcount code",
            toolbar:
              "code | undo redo | formatselect | bold italic backcolor |  alignleft aligncenter alignright alignjustify |  bullist numlist outdent indent | removeformat | help | image",
            images_upload_url: `/upload/${props.slide.PortalId}`,
            promotion: false,
          }}
          onEditorChange={(content) => {
            //props.updateSlide("rte_content", content);
          }}
        />
        {dirty && <p>You have unsaved content (autosaves every 1 second).</p>}
      </div>
    </div>
  );
}

export default RTEProperties;
