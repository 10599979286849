import React, { useState } from "react";
import { useSelector } from "react-redux";
import CustomCheckbox from "../../misc/CustomCheckbox";
import axios from "axios";
import FileUploader from "./FileUploader";
import ImageUploader from "../../SlideEditor/ImageUploader";

function EditTemplate(props) {
  const defaultTemplate = {
    name: "",
    css_path: "",
    icon_path: "",
    background_path: "",
    num_images: 0,
    num_titles: 0,
    num_textareas: 0,
    title1_maxlength: 0,
    title2_maxlength: 0,
    title3_maxlength: 0,
    title4_maxlength: 0,
    textarea1_maxlength: 0,
    textarea2_maxlength: 0,
    textarea3_maxlength: 0,
    textarea4_maxlength: 0,
    type: 0,
    outdoor: false,
  };

  const [template, setTemplate] = useState(
    useSelector((state) => state.selection.template) || defaultTemplate
  );
  const [message, setMessage] = useState("");

  const templateTypesArray = ["Basic", "Pro", "Custom"];

  const saveTemplate = () => {
    if (template._id) {
      axios
        .patch("api/templates/updateTemplate", {
          _id: template._id,
          update: {
            ...template,
          },
        })
        .then((r) => setMessage("Save successful."))
        .catch((e) => setMessage(e.response.data.error));
    } else {
      axios
        .post("api/templates/add", { template })
        .then((r) => {
          setMessage("Save successful.");
          setTemplate(r.data);
        })
        .catch((e) => setMessage(e.response.data.error));
    }
  };

  return (
    <div>
      <div className="controlpanelsectiontopbar">
        {template._id ? "Edit" : "New"} Template
      </div>
      <div className="grid-fit-2">
        <label>Name:</label>
        <input
          type="text"
          value={template.name}
          onChange={(e) => setTemplate({ ...template, name: e.target.value })}
        />
        <label>CSS File:</label>
        <FileUploader
          item={template}
          update={(property, path) => {
            setTemplate({ ...template, css_path: path });
          }}
          property="css_path"
          customPath="DesktopModules\RGI.VeMedia2\templates"
          customName={template.name}
        />
        <label>Template Preview Icon:</label>
        <ImageUploader
          slide={template}
          updateSlide={(property, path) =>
            setTemplate({ ...template, icon_path: path })
          }
          property="icon_path"
          setMessage={setMessage}
          customPath="DesktopModules\RGI.VeMedia2\templates"
          customName={template.name}
        ></ImageUploader>
        <label>Template Background Image:</label>
        <ImageUploader
          slide={template}
          updateSlide={(property, path) =>
            setTemplate({ ...template, background_path: path })
          }
          property="background_path"
          setMessage={setMessage}
          customPath="DesktopModules\RGI.VeMedia2\templates\backgrounds"
        ></ImageUploader>
        <label>Number of Titles</label>
        <select
          value={template.num_titles}
          onChange={(e) =>
            setTemplate({ ...template, num_titles: e.target.value })
          }
        >
          {[0, 1, 2, 3, 4].map((item, idx) => (
            <option key={idx} value={item}>
              {item}
            </option>
          ))}
        </select>
        {[1, 2, 3, 4].map((item, idx) => {
          const objKey = `title${item}_maxlength`;
          return template.num_titles >= item ? (
            <React.Fragment key={idx}>
              <label>Title {item} Max Length</label>
              <input
                type="text"
                value={template[objKey]}
                onChange={(e) =>
                  setTemplate({ ...template, [objKey]: e.target.value })
                }
              />
            </React.Fragment>
          ) : null;
        })}

        <label>Number of Images</label>
        <select
          value={template.num_images}
          onChange={(e) =>
            setTemplate({ ...template, num_images: e.target.value })
          }
        >
          {[0, 1, 2, 3, 4].map((item, idx) => (
            <option key={idx} value={item}>
              {item}
            </option>
          ))}
        </select>

        <label>Number of Text Areas</label>
        <select
          value={template.num_textareas}
          onChange={(e) =>
            setTemplate({ ...template, num_textareas: e.target.value })
          }
        >
          {[0, 1, 2, 3, 4].map((item, idx) => (
            <option key={idx} value={item}>
              {item}
            </option>
          ))}
        </select>

        {[1, 2, 3, 4].map((item, idx) => {
          const objKey = `textarea${item}_maxlength`;
          return template.num_textareas >= item ? (
            <React.Fragment key={idx}>
              <label>Textarea {item} Max Length</label>
              <input
                type="text"
                value={template[objKey]}
                onChange={(e) =>
                  setTemplate({ ...template, [objKey]: e.target.value })
                }
              />
            </React.Fragment>
          ) : null;
        })}

        <label>Type</label>
        <select
          value={template.type}
          onChange={(e) => setTemplate({ ...template, type: e.target.value })}
        >
          {templateTypesArray.map((item, idx) => (
            <option key={idx} value={idx}>
              {item}
            </option>
          ))}
        </select>
        <label>Outdoor Template:</label>
        <CustomCheckbox
          checked={template.outdoor}
          onChange={() =>
            setTemplate({ ...template, outdoor: !template.outdoor })
          }
        />
      </div>
      <input type="button" value="Save" onClick={saveTemplate} />
      <input
        type="button"
        value="Back"
        onClick={() => props.history.push("/dashboard/webmaster/templates")}
      />
      {message}
    </div>
  );
}

export default EditTemplate;
