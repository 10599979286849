import React from "react";

function Changelog() {
  return (
    <div>
      <h1>Changelog</h1>
      <h2>Version 3.0</h2>
      <ul>
        <li>
          Rewrote everything in a new tech stack. Everything should run much
          faster now.
        </li>
        <li>
          Preview and sort buttons on the main page are now icons next to the
          channel name. <i className="material-icons noselect">preview</i>
          <i className="material-icons noselect">sort</i>
        </li>
        <li>
          The archive column is now Actions, which contains icons for Archive
          <i className="material-icons noselect">archive</i>, Edit
          <i className="material-icons noselect">edit</i>, and Preview
          <i className="material-icons noselect">preview</i>.
        </li>
        <li>
          There is also a new function that toggles the slide on all of your
          channels at once <i className="material-icons noselect">add_task</i>
        </li>
        <li>
          All changes on the main page (archiving slide, activating/deactivating
          a slide) are now saved instantly, so there's no more need for a Save
          Changes button.
        </li>
        <li>
          The feature that allows you to jump directly to the page a selected
          channel is on is now a button at the bottom of the page.
        </li>
        <li>
          Flash is{" "}
          <a href="https://www.adobe.com/ca/products/flashplayer/end-of-life.html">
            no longer supported
          </a>
          , so Flash slides can no longer be created/edited.
        </li>
        <li>Your username is now displayed next to the log out link.</li>
        <li>
          Users can now have a display name which will be displayed next to the
          log out link if it is set and may be used in other places in the
          future.
        </li>
      </ul>
    </div>
  );
}

export default Changelog;
