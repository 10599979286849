import {
  SET_SELECTED_AREA,
  SET_SELECTED_SLIDE_TYPE,
  SET_SELECTED_SLIDE,
  SET_SELECTED_PORTAL,
  SET_SELECTED_CHANNEL,
  SET_SELECTED_USER,
  SET_SELECTED_TEMPLATE,
  SET_SELECTED_CL_CATEGORY,
} from "../actions/types";

export const setSelectedArea = (area) => {
  return {
    type: SET_SELECTED_AREA,
    payload: area,
  };
};

export const setSelectedSlideType = (slideType) => {
  return {
    type: SET_SELECTED_SLIDE_TYPE,
    payload: slideType,
  };
};

export const setSelectedSlide = (slide) => {
  return {
    type: SET_SELECTED_SLIDE,
    payload: slide,
  };
};

export const setSelectedPortal = (portal) => {
  return {
    type: SET_SELECTED_PORTAL,
    payload: portal,
  };
};

export const setSelectedChannel = (channel) => {
  return {
    type: SET_SELECTED_CHANNEL,
    payload: channel,
  };
};

export const setSelectedUser = (user) => {
  return {
    type: SET_SELECTED_USER,
    payload: user,
  };
};

export const setSelectedTemplate = (template) => {
  return {
    type: SET_SELECTED_TEMPLATE,
    payload: template,
  };
};

export const setSelectedContentLibraryCategory = (category) => {
  return {
    type: SET_SELECTED_CL_CATEGORY,
    payload: category,
  };
};
