import React from "react";

function LoginContainer(props) {
  return (
    <div className="LoginContainer">
      <div className="LoginBanner">
        <img
          id="imgLogo"
          src="/img/VeMedia_logo.png"
          style={{ height: "160px" }}
          alt=""
        />
      </div>
      {props.children}
    </div>
  );
}

export default LoginContainer;
