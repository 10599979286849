import React from "react";

function Functions() {
  return (
    <div>
      Miscellaneous functions can be added here to solve rare/one time issues.
    </div>
  );
}

export default Functions;
