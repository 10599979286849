import React from "react";

function CustomCheckbox({ checked, onChange }) {
  return (
    <>
      <img
        alt="checkbox"
        role="checkbox"
        aria-checked={checked}
        style={{ cursor: "pointer", width: "20px", height: "21px" }}
        src={checked ? "/img/checkboxChecked.png" : "/img/checkbox.png"}
        onClick={onChange}
        //this allows focus and the following event allows spacebar to activate it like a normal checkbox
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.keyCode === 32) {
            e.preventDefault();
            onChange();
          }
        }}
      />
    </>
  );
}

export default CustomCheckbox;
