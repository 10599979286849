import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import ContentLibraryHeader from "./ContentLibraryHeader";
import ImageUploader from "../SlideEditor/ImageUploader";
import VideoUploader from "../SlideEditor/VideoUploader";
import CustomCheckbox from "../misc/CustomCheckbox";

function ContentLibraryAdmin() {
  //category
  const [name, setName] = useState("");
  const [parentCategory, setParentCategory] = useState(null);
  const [childrenOnly, setChildrenOnly] = useState(false);
  const [hideFromMenu, setHideFromMenu] = useState(false);
  const [editCategory, setEditCategory] = useState(null);

  const [categories, setCategories] = useState([]);

  //image
  const [imageName, setImageName] = useState("");
  const [imagePath, setImagePath] = useState("");
  const [imageCategory, setImageCategory] = useState(null);

  //video
  const [videoName, setVideoName] = useState("");
  const [videoPath, setVideoPath] = useState("");
  const [videoCategory, setVideoCategory] = useState(null);

  useEffect(() => {
    axios.get("/api/categories").then((res) => {
      setCategories(res.data);
      setImageCategory(res.data[0]);
    });
  }, []);

  useEffect(() => {
    if (editCategory) {
      setName(editCategory.name);
      setParentCategory(editCategory.parentCategory);
      setChildrenOnly(editCategory.childrenOnly);
      setHideFromMenu(editCategory.hideFromMenu);
    } else {
      setName("");
      setParentCategory(null);
      setChildrenOnly(false);
      setHideFromMenu(false);
    }
  }, [editCategory]);

  const submitCategory = () => {
    if (editCategory) {
      //overwrite selected category
      axios
        .patch("/api/categories/update", {
          id: editCategory._id,
          update: {
            name: name,
            parentCategory: parentCategory,
            childrenOnly: childrenOnly,
            hideFromMenu: hideFromMenu,
          },
        })
        .then((res) => {
          setCategories([...categories, res.data]);
          alert("Category updated");
        });
    } else {
      //save a new category
      axios
        .post("/api/categories/add", {
          name: name,
          parentCategory: parentCategory,
          childrenOnly: childrenOnly,
          hideFromMenu: hideFromMenu,
        })
        .then((res) => {
          setCategories([...categories, res.data]);
          alert("Category Added");
        });
    }
  };

  const submitImage = () => {
    axios
      .post("/api/images/add", {
        name: imageName,
        path: imagePath,
        category: imageCategory,
      })
      .then((res) => {
        console.log(res);
        //setCategories([...categories, res.data]);
        alert("Image Added");
      });
  };

  const submitVideo = () => {
    axios
      .post("/api/videos/add", {
        name: videoName,
        path: videoPath,
        category: videoCategory,
      })
      .then((res) => {
        console.log(res);
        //setCategories([...categories, res.data]);
        alert("Video Added");
      });
  };

  return (
    <div>
      <ContentLibraryHeader />
      <div
        style={{
          maxWidth: "1000px",
          margin: "38px auto",
        }}
      >
        <h1>Content Library Admin</h1>
        <h2>Add Category</h2>
        <div className="grid-fit-2">
          <label>Name:</label>
          <input
            type="text"
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
          <label>Parent Category:</label>
          <select
            value={parentCategory}
            onChange={(e) =>
              setParentCategory(e.target.value !== "" ? e.target.value : null)
            }
          >
            <option value={""}>None</option>
            {categories.map((cat, idx) => {
              return (
                <option key={idx} value={cat._id}>
                  {cat.name}
                </option>
              );
            })}
          </select>
          <label>
            Not Clickable (use if you want this to have subcategories and not be
            a category itself):
          </label>
          <CustomCheckbox
            checked={childrenOnly}
            onChange={() => setChildrenOnly(!childrenOnly)}
          />
          <label>Hide From Menu:</label>
          <CustomCheckbox
            checked={hideFromMenu}
            onChange={() => setHideFromMenu(!hideFromMenu)}
          />

          <input
            type="button"
            onClick={() => submitCategory()}
            value={editCategory ? "Save Changes" : "Submit"}
          />
        </div>

        <h2>Edit Category</h2>
        <p>
          Select a category and edit it using the form above. Click cancel
          editing to go back to adding a new category.
        </p>
        <div className="grid-fit-2">
          {categories.map((category) => {
            return (
              <a href="#" onClick={() => setEditCategory(category)}>
                {category.name}
              </a>
            );
          })}
          <input
            type="button"
            onClick={() => {
              setEditCategory(null);
            }}
            value={"Cancel Editing"}
          />
        </div>

        <h2>Add Image</h2>
        <div className="grid-fit-2">
          <label>Name:</label>
          <input
            type="text"
            onChange={(e) => setImageName(e.target.value)}
            value={imageName}
          />
          <label>Upload Image:</label>
          <ImageUploader
            slide={{ PortalId: "ContentLibrary", image: imagePath }}
            property="image"
            updateSlide={(property, path) => {
              setImagePath(path);
            }}
          />
          <label>Category:</label>
          <select
            value={imageCategory}
            onChange={(e) =>
              setImageCategory(e.target.value !== "" ? e.target.value : null)
            }
          >
            {categories.map((cat, idx) => {
              return (
                <option key={idx} value={cat._id}>
                  {cat.name}
                </option>
              );
            })}
          </select>
          <input type="button" onClick={() => submitImage()} value={"Submit"} />
        </div>

        <h2>Add Video</h2>
        <div className="grid-fit-2">
          <label>Name:</label>
          <input
            type="text"
            onChange={(e) => setVideoName(e.target.value)}
            value={videoName}
          />
          <label>Upload Video:</label>
          <VideoUploader
            slide={{ PortalId: "ContentLibrary", video_path: videoPath }}
            property="video"
            updateSlide={(property, path) => {
              setVideoPath(path);
            }}
          />
          <label>Category:</label>
          <select
            value={videoCategory}
            onChange={(e) =>
              setVideoCategory(e.target.value !== "" ? e.target.value : null)
            }
          >
            {categories.map((cat, idx) => {
              return (
                <option key={idx} value={cat._id}>
                  {cat.name}
                </option>
              );
            })}
          </select>
          <input type="button" onClick={() => submitVideo()} value={"Submit"} />
        </div>
      </div>
    </div>
  );
}

export default ContentLibraryAdmin;
