import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { loginUser, setCurrentUser } from "../../actions/authActions";
import LoginContainer from "./LoginContainer";
import { useAuth0 } from "@auth0/auth0-react";
import LogoutButton from "./LogoutButton";
import axios from "axios";
import setAuthToken from "../../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { GET_ERRORS } from "../../actions/types"; // Register User

function Login(props) {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const auth = useSelector((state) => state.auth);
  const errors = useSelector((state) => state.errors);
  const dispatch = useDispatch();

  const { user, isAuthenticated, loginWithRedirect, getAccessTokenSilently } =
    useAuth0();

  const getUserMetadata = async () => {
    const domain = "rgisolutions.us.auth0.com";

    try {
      console.log("trying getAccessTokenSilently");
      const accessToken = await getAccessTokenSilently({
        audience: `https://${domain}/api/v2/`,
        scope: "read:current_user",
      });

      console.log(accessToken);

      const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user.sub}`;

      axios
        .post("/api/users/getToken", { userDetailsByIdUrl, accessToken })
        .then((res) => {
          // Save to localStorage// Set token to localStorage
          const { token } = res.data;
          localStorage.setItem("jwtToken", token);
          // Set token to Auth header
          setAuthToken(token);
          // Decode token to get user data
          const decoded = jwt_decode(token);
          // Set current user
          console.log(decoded);
          dispatch(setCurrentUser(decoded));
        })
        .catch((err) =>
          dispatch({
            type: GET_ERRORS,
            payload: err.response.data,
          })
        );
    } catch (e) {
      if (e.error === "login_required") {
        //loginWithRedirect();
      }
      console.log(e.message);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      getUserMetadata();
    }
    if (auth.isAuthenticated) {
      props.history.push("/dashboard");
    }
  });

  const onSubmit = (e) => {
    e.preventDefault();
    const userData = {
      userName: userName,
      password: password,
    };

    dispatch(loginUser(userData));
  };

  return (
    <LoginContainer>
      <span className="LoginHeader">Welcome to Ve-Media!</span>
      <p className="LoginParagraph">
        Ve-Media is a web-based digital signage solution that enables businesses
        to schedule and manage all digital screens from any web browser.
      </p>

      <div id="LoginBox">
        <form noValidate onSubmit={onSubmit}>
          <span>{errors.loginerror || "Please sign in"}</span>
          <input
            onChange={(e) => setUserName(e.target.value)}
            value={userName}
            id="username"
            type="text"
            placeholder="Username"
            className={errors.loginerror ? "invalid" : ""}
          />
          <input
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            name="txtPassword"
            type="password"
            id="txtPassword"
            placeholder="Password"
            className={errors.loginerror ? "invalid" : ""}
          />
          <Link to="/resetpassword" className="btn-flat waves-effect">
            Forgot Password?
          </Link>
          <input
            type="submit"
            name="btnLogin"
            value="Login"
            id="btnLogin"
            className="LoginButton"
          />
        </form>
      </div>
      <button className="Auth0Button" onClick={() => loginWithRedirect()}>
        Log In With Auth0
      </button>
    </LoginContainer>
  );
}

export default Login;
