import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import ImageUploader from "../SlideEditor/ImageUploader";
import axios from "axios";
import CustomCheckbox from "../misc/CustomCheckbox";
const isEmpty = require("is-empty");

function EditChannel(props) {
  const auth = useSelector((state) => state.auth);
  const { user } = auth;
  const selectedPortal = useSelector((state) => state.selection.portal);

  const defaultChannel = {
    PortalId: selectedPortal || user.PortalId,
    selectedAreas: [],
    forcedRefreshCheckFrequencyMinutes: 2,
    refreshFrequencyMinutes: 60,
    responsive: true,
  };
  const [channel, setChannel] = useState(
    useSelector((state) => state.selection.channel) || defaultChannel
  );

  const [areas, setAreas] = useState(null);
  useEffect(() => {
    if (channel && !areas) {
      axios
        .post("/api/areas/getareas", { PortalId: channel.PortalId })
        .then((r) => {
          setAreas(r.data);
        });
    }
  }, [channel, areas]);

  const [otherChannels, setOtherChannels] = useState(null);
  const [syncGroup, setSyncGroup] = useState(undefined);

  useEffect(() => {
    if (channel && !otherChannels) {
      axios
        .post("/api/channels/getchannelsinorder", {
          PortalId: channel.PortalId,
        })
        .then((r) => {
          setOtherChannels(
            r.data.filter((c) => {
              return c._id !== channel._id;
            })
          );
        });
    }

    //get the syncGroup this channel is in on page load, if any.
    if (channel && syncGroup === undefined) {
      axios
        .post("/api/syncgroups/getsyncgroupforchannel", {
          _id: channel._id,
        })
        .then((r) => {
          if (!r.data) setSyncGroup({ PortalId: channel.PortalId });
          else setSyncGroup(r.data);
        });
    }
  }, [channel, otherChannels, syncGroup]);

  const [message, setMessage] = useState("");

  const getValidationErrors = () => {
    const validationErrors = [];
    if (isEmpty(channel.name))
      validationErrors.push("Channel must have a name.");
    if (channel.selectedAreas.length === 0)
      validationErrors.push("Channel must have at least 1 selected area.");
    return validationErrors;
  };

  const saveChannel = () => {
    const validationErrors = getValidationErrors();
    if (validationErrors.length === 0) {
      if (channel._id) {
        axios
          .patch("api/channels/updateChannel", {
            _id: channel._id,
            PortalId: channel.PortalId,
            update: {
              ...channel,
            },
          })
          .then((r) => setMessage("Save successful."))
          .catch((e) => {
            setMessage(e.response.data.error);
          });
      } else {
        axios
          .post("api/channels/add", { channel })
          .then((r) => {
            setMessage("Save successful.");
            setChannel(r.data);
          })
          .catch((e) => setMessage(e.response.data.error));
      }

      //syncGroup.channels.push(channel._id);
      //since the add function already checks for syncgroups that include one of these channels, and updates that syncgroup if it already exists, we shouldn't need a
      //separate update function in the API
      axios
        .post("api/syncgroups/add", syncGroup)
        .then((r) => {
          setMessage("Save successful.");
          setSyncGroup(r.data);
        })
        .catch((e) => setMessage(e.response.data.error));
    } else {
      setMessage(validationErrors.map((err) => <div>{err}</div>));
    }
  };

  const gridStyles = {
    padding: "10px",
    rowGap: "5px",
    columnGap: "5px",
    maxWidth: "970px",
  };

  const editorRef = useRef(null);
  const [dirty, setDirty] = useState(false);

  //if it has more than 5 &, assume it's encoded in the database (some coming from the old vemedia are encoded and some aren't for some reason)
  const [initialValue, setInitialValue] = useState(channel.description);

  const save = () => {
    if (editorRef.current) {
      const content = editorRef.current.getContent();
      setDirty(false);
      editorRef.current.setDirty(false);
      setChannel({ ...channel, description: content });
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      save();
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
  }, [channel]);

  if (channel === undefined) {
    //if no channel is selected, go back to where they can select one.
    props.history.push("/dashboard/controlpanel");
    return null;
  } else {
    //console.log(channel.selectedAreas);
    return (
      <>
        <div className="controlpanelsectiontopbar">General</div>
        <div className="grid-fit-4" style={gridStyles}>
          <label>Channel Name:</label>
          <input
            type="text"
            value={channel.name}
            onChange={(e) => setChannel({ ...channel, name: e.target.value })}
          />
          <span></span>
          <span></span>
          <label>Check for refresh request every:</label>
          <span>
            <input
              type="test"
              value={channel.forcedRefreshCheckFrequencyMinutes}
              onChange={(e) =>
                setChannel({
                  ...channel,
                  forcedRefreshCheckFrequencyMinutes: parseInt(e.target.value),
                })
              }
            />{" "}
            minutes
          </span>
          <span>
            <CustomCheckbox
              checked={channel.disableForceRefreshCheck}
              onChange={() =>
                setChannel({
                  ...channel,
                  disableForceRefreshCheck: !channel.disableForceRefreshCheck,
                })
              }
            />
            Disable (warning: you will not be able to remotely refresh)
          </span>
          <span></span>

          <label>Automatically refresh every:</label>
          <span>
            <input
              type="test"
              value={channel.refreshFrequencyMinutes}
              onChange={(e) =>
                setChannel({
                  ...channel,
                  refreshFrequencyMinutes: parseInt(e.target.value),
                })
              }
            />{" "}
            minutes
          </span>
          <span>
            <CustomCheckbox
              checked={channel.disableAutoRefresh}
              onChange={() =>
                setChannel({
                  ...channel,
                  disableAutoRefresh: !channel.disableAutoRefresh,
                })
              }
            />
            Disable automatic refresh
          </span>
          <span></span>
          <label>Address:</label>
          <input
            type="text"
            value={channel.address || undefined}
            onChange={(e) =>
              setChannel({ ...channel, address: e.target.value })
            }
          />

          <span></span>
          <span></span>
          <span>Description:</span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div>
          <Editor
            onInit={(evt, editor) => (editorRef.current = editor)}
            onDirty={() => setDirty(true)}
            tinymceScriptSrc="/tinymce/tinymce.min.js"
            licenseKey="gpl"
            initialValue={initialValue}
            init={{
              height: 200,
              menubar: true,
              plugins:
                "advlist autolink lists link image charmap print preview anchor searchreplace visualblocks fullscreen insertdatetime media table paste help wordcount",

              toolbar:
                "code | undo redo | formatselect | bold italic backcolor |  alignleft aligncenter alignright alignjustify |  bullist numlist outdent indent | removeformat | help | image",
              images_upload_url: `/upload/${channel.PortalId}`,
              promotion: false,
            }}
          />

          {dirty && <p>You have unsaved content (autosaves every 1 second).</p>}
        </div>
        <div>
          <label>Background image:</label>
          <div>
            <ImageUploader
              slide={channel}
              updateSlide={(property, path) =>
                setChannel({ ...channel, backgroundimage: path })
              }
              property="backgroundimage"
              setMessage={setMessage}
            ></ImageUploader>
          </div>
          <input
            type="button"
            onClick={() => setChannel({ ...channel, backgroundimage: "" })}
            value="Remove Background"
          />
        </div>
        <div className="controlpanelsectiontopbar">Sizing</div>
        <span>
          Note: It's usually best to leave this default so the channel fills the
          screen and set sizes on the areas instead. In responsive mode the
          height and width are a % of the screen. Otherwise it is a number of
          pixels. In either mode leaving them blank will default to 100% of the
          screen size.
        </span>
        <div className="grid-fr-4" style={gridStyles}>
          <label>Width:</label>
          <input
            type="text"
            value={channel.width || ""}
            onChange={(e) =>
              setChannel({ ...channel, width: parseInt(e.target.value) })
            }
          />
          <label>Height:</label>
          <input
            type="text"
            value={channel.height || ""}
            onChange={(e) =>
              setChannel({ ...channel, height: parseInt(e.target.value) })
            }
          />
          <label>Responsive:</label>
          <CustomCheckbox
            checked={channel.responsive}
            onChange={(e) =>
              setChannel({ ...channel, responsive: !channel.responsive })
            }
          />
        </div>
        <div className="controlpanelsectiontopbar">Areas</div>
        <div
          className="grid-fr-3"
          style={{
            ...gridStyles,
          }}
        >
          {areas
            ? areas.map((area, idx) => {
                return (
                  <div key={idx}>
                    <CustomCheckbox
                      id={`area-${area._id}`}
                      checked={
                        channel.selectedAreas &&
                        channel.selectedAreas.includes(area._id)
                      }
                      onChange={(e) => {
                        let selectedAreas = channel.selectedAreas || [];
                        if (!selectedAreas.includes(area._id)) {
                          selectedAreas.push(area._id);
                        } else {
                          console.log(
                            `Attempting to filter ${area._id} from array.`
                          );
                          selectedAreas = selectedAreas.filter((item) => {
                            console.log(`${item._id} !== ${area._id}`);
                            return item !== area._id;
                          });
                        }
                        setChannel({
                          ...channel,
                          selectedAreas: selectedAreas,
                        });
                      }}
                    />
                    <label htmlFor={`area-${area._id}`}>{area.name}</label>
                  </div>
                );
              })
            : "Loading areas..."}
        </div>
        {/*
        <div className="controlpanelsectiontopbar">Synchronize With:</div>
        <div
          className="grid-fr-3"
          style={{
            ...gridStyles,
          }}
        >
          {otherChannels
            ? otherChannels.map((otherChannel, idx) => {
                return (
                  <div key={idx}>
                    <CustomCheckbox
                      id={`otherChannel-${otherChannel._id}`}
                      checked={
                        syncGroup &&
                        syncGroup.channels &&
                        syncGroup.channels.includes(otherChannel._id)
                      }
                      onChange={(e) => {
                        let synchronizeChannels =
                          syncGroup && syncGroup.channels
                            ? syncGroup.channels
                            : [];
                        if (!synchronizeChannels.includes(otherChannel._id)) {
                          synchronizeChannels.push(otherChannel._id);
                        } else {
                          console.log(
                            `Attempting to filter ${otherChannel._id} from array.`
                          );
                          synchronizeChannels = synchronizeChannels.filter(
                            (item) => {
                              console.log(
                                `${item._id} !== ${otherChannel._id}`
                              );
                              return item !== otherChannel._id;
                            }
                          );
                        }
                        console.log(synchronizeChannels);
                        setSyncGroup({
                          ...syncGroup,
                          channels: synchronizeChannels,
                        });
                      }}
                    />
                    <label htmlFor={`otherChannel-${otherChannel._id}`}>
                      {otherChannel.name}
                    </label>
                  </div>
                );
              })
            : "Loading channels..."}
        </div>
            */}
        <input
          className="button_lightred"
          type="button"
          value="Save Changes"
          onClick={() => saveChannel()}
        />
        <input
          className="button_lightred"
          type="button"
          value="<< Back"
          onClick={() => props.history.push("/dashboard/controlpanel")}
        />
        <span>{message}</span>
      </>
    );
  }
}

export default EditChannel;
