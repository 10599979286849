import React from "react";
import CustomCheckbox from "../misc/CustomCheckbox";
import VideoUploader from "./VideoUploader";

function VideoProperties(props) {
  return (
    <div>
      <div className="addslidesectiontopbar">Content</div>
      <div className="addslidesection columns-2">
        <label>Upload Video</label>
        <VideoUploader
          slide={props.slide}
          updateSlide={props.updateSlide}
          property="video_path"
          setMessage={props.setMessage}
        />
        <label>HTML5:</label>
        <CustomCheckbox
          checked={
            props.slide.video_html5 === undefined
              ? true
              : props.slide.video_html5
          }
          onChange={(e) => {
            props.updateSlide("video_html5", !props.slide.video_html5);
          }}
        />
      </div>
    </div>
  );
}

export default VideoProperties;
