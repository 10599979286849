import React from "react";
import { useSelector } from "react-redux";
import PlayerStatus from "./PlayerStatus";
import Templates from "./Templates";
import EditTemplate from "./EditTemplate";
import EditPortal from "./EditPortal";
import Functions from "./Functions";

import { Switch, Link } from "react-router-dom";
import PrivateRoute from "../../private-route/PrivateRoute";

function WebmasterControlPanel(props) {
  const auth = useSelector((state) => state.auth);
  const { user } = auth;

  const selectedStyles = { color: "green", fontWeight: "bold" };
  const linkStyles = { color: "blue" };

  if (user.isSuperUser) {
    console.log(props.history.location.pathname);
    return (
      <>
        <div className="grid-fr-3">
          <Link
            to="/dashboard/webmaster"
            style={
              props.history.location.pathname === "/dashboard/webmaster"
                ? selectedStyles
                : linkStyles
            }
          >
            Player Status
          </Link>
          {/*<Link
            to="/dashboard/webmaster/templates"
            style={
              props.history.location.pathname ===
              "/dashboard/webmaster/templates"
                ? selectedStyles
                : linkStyles
            }
          >
            RTE Templates
          </Link>*/}
          <Link
            to="/dashboard/webmaster/functions"
            style={
              props.history.location.pathname ===
              "/dashboard/webmaster/functions"
                ? selectedStyles
                : linkStyles
            }
          >
            Functions
          </Link>
        </div>
        <Switch>
          <PrivateRoute
            exact
            path="/dashboard/webmaster/"
            component={PlayerStatus}
          />
          <PrivateRoute
            exact
            path="/dashboard/webmaster/templates"
            component={Templates}
          />

          <PrivateRoute
            exact
            path="/dashboard/webmaster/templates/edittemplate"
            component={EditTemplate}
          />
          <PrivateRoute
            exact
            path="/dashboard/webmaster/editportal"
            component={EditPortal}
          />

          <PrivateRoute
            exact
            path="/dashboard/webmaster/functions"
            component={Functions}
          />
        </Switch>
      </>
    );
  } else {
    props.history.push("/dashboard/");
    return null;
  }
}

export default WebmasterControlPanel;
