import React, { useState } from "react";
import axios from "axios";

function FileUploader(props) {
  const [filePath, setFilePath] = useState(props.item[props.property]);

  const onChangeHandler = (e) => {
    const data = new FormData();
    data.append("file", e.target.files[0]);
    if (props.customPath) data.append("path", props.customPath);
    if (props.customName) data.append("filename", props.customName);
    axios
      .post(`/uploadGeneric/`, data, {})
      .then((res) => {
        const path = res.data.location;
        props.update(props.property, path);
        setFilePath(path);
      })
      .catch((err) => {
        if (props.setMessage) props.setMessage(err.response.data);
      });
  };

  const link = filePath
    ? `${
        filePath.includes("http") ? filePath : `http://ve-media.com${filePath}`
      }`
    : "";

  return (
    <div>
      <div>
        <input type="file" name="file" onChange={onChangeHandler} />
      </div>
      <a href={link}>{link}</a>
    </div>
  );
}

export default FileUploader;
