import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import ContentLibraryHeader from "./ContentLibraryHeader";
import axios from "axios";
import { Link } from "react-router-dom";
import { setSelectedSlide } from "../../actions/selectionActions";

function ContentLibraryImage() {
  let location = window.location.pathname;
  let split = location.split("/");
  let imageId = split[split.length - 1];

  const dispatch = useDispatch();
  const [image, setImage] = useState(null);

  useEffect(() => {
    axios.post("/api/images/find", { _id: imageId }).then((res) => {
      setImage(res.data[0]);
      console.log(res.data[0]);
    });
  }, [imageId]);

  return (
    <div>
      <ContentLibraryHeader />
      <div
        style={{
          maxWidth: "1000px",
          margin: "38px auto",
        }}
      >
        {image ? (
          <>
            <h1>{image.name}</h1>
            <img
              alt={image.name}
              style={{ maxWidth: "100%" }}
              src={image.path}
            />
            <div className="cl-buttons">
              <div>
                <a class="cl-button" a href={image.path} download>
                  Download
                </a>
              </div>

              <div>
                <Link
                  to="/dashboard/EditSlide"
                  onClick={() => {
                    dispatch(
                      setSelectedSlide({
                        name: image.name,
                        type: 2,
                        image_path: image.path,
                      })
                    );
                  }}
                >
                  Create Slide
                </Link>
              </div>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}

export default ContentLibraryImage;
