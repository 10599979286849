import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { setSelectedTemplate } from "../../../actions/selectionActions";
import { templateTypes } from "../../../utils/templateHelpers";

function Templates(props) {
  const [templates, setTemplates] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    axios.get("/api/templates/").then((res) => setTemplates(res.data));
  }, []);

  return (
    <div>
      <div className="grid-fr-4">
        <div className="webmaster-grid-header">Name</div>
        <div className="webmaster-grid-header">Type</div>
        <div className="webmaster-grid-header">Creation Date</div>
        <div className="webmaster-grid-header">Edit</div>
        {templates.map((t) => {
          const date = t.creationDate ? new Date(t.creationDate) : null;
          return (
            <>
              <div>{t.name}</div>
              <div>{templateTypes[t.type]}</div>
              <div>
                {date
                  ? `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
                  : "Never"}
              </div>
              <div>
                <input
                  type="button"
                  value="Edit"
                  onClick={() => {
                    dispatch(setSelectedTemplate(t));
                    props.history.push(
                      "/dashboard/webmaster/templates/edittemplate"
                    );
                  }}
                />
              </div>
            </>
          );
        })}
      </div>
      <input
        type="button"
        value="Add New"
        onClick={() => {
          dispatch(setSelectedTemplate(null));
          props.history.push("/dashboard/webmaster/templates/edittemplate");
        }}
      />
    </div>
  );
}

export default Templates;
