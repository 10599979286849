import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { logoutUser } from "../../actions/authActions";
import { useAuth0 } from "@auth0/auth0-react";

function Header() {
  const auth = useSelector((state) => state.auth);

  const { logout } = useAuth0();

  const { user } = auth;
  const dispatch = useDispatch();
  const onLogoutClick = (e) => {
    e.preventDefault();
    dispatch(logoutUser());
    logout({ returnTo: window.location.origin });
  };

  return (
    <>
      <div style={{ position: "fixed", width: "100%", zIndex: 999 }}>
        <div className="VeMediaMenu">
          <div className="VeMediaMenuInner">
            <Link to="/dashboard">
              <img
                alt="Logo"
                src="/img/VeMedia_logo.png"
                style={{ height: "160px" }}
              />
            </Link>
            <div className="MenuRight">
              {user.isSuperUser ? (
                <Link
                  className="GreenLink"
                  to="/dashboard/webmaster"
                  style={{ marginRight: "10px" }}
                >
                  Webmaster Control Panel
                </Link>
              ) : null}
              <Link className="GreenLink" to="/dashboard/controlpanel">
                Control Panel
              </Link>
              <Link to="#" onClick={onLogoutClick}>
                Log out ({user.displayName ? user.displayName : user.name})
              </Link>
            </div>
            <div className="MenuBottom">
              <Link to="/dashboard">Home</Link>&nbsp;|&nbsp;
              <Link to="/dashboard/instructions">Instructions</Link>
              &nbsp;|&nbsp;
              <a href="https://now-ic.com/services/">Other Products</a>
              &nbsp;|&nbsp;
              <Link to="/contentlibrary">Content Library</Link>
              &nbsp;|&nbsp;<a href="https://now-ic.com/contact/">Contact</a>
            </div>
          </div>
        </div>
      </div>
      <div style={{ paddingTop: "168px" }}></div>
    </>
  );
}

export default Header;
