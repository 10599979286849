import React from "react";

export function FlashSlide({ slide, width, height }) {
  return (
    <object
      classID="clsid:d27cdb6e-ae6d-11cf-96b8-444553540000"
      codebase="http://download.macromedia.com/pub/shockwave/cabs/flash/swflash.cab#version=6,0,40,0"
      height={height}
      hspace="-10"
      vspace="0"
      width={width}
    >
      <param name="quality" value="high" />
      <param name="movie" value={slide.flash_path} />
      <embed
        height={height}
        hspace="0"
        pluginspage="http://www.macromedia.com/go/getflashplayer"
        quality="high"
        src={slide.flash_path}
        type="application/x-shockwave-flash"
        vspace="0"
        width={width}
      ></embed>
    </object>
  );
}

export default FlashSlide;
