import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "core-js/es/";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";
import * as serviceWorker from "./serviceWorker";

const redirectUri = `${window.location.origin}/login`;

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain="rgisolutions.us.auth0.com"
      clientId="ur9mFkimo5a6eBXFH9Bl3ec8lEYfORpQ"
      redirectUri={redirectUri}
      audience="https://rgisolutions.us.auth0.com/api/v2/"
      scope="read:current_user update:current_user_metadata"
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
