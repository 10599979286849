import { useEffect } from "react";
import { useSelector } from "react-redux";

function Landing(props) {
  const auth = useSelector((state) => state.auth);

  useEffect(() => {
    if (auth.isAuthenticated) {
      props.history.push("/dashboard");
    } else {
      props.history.push("/login");
    }
  });
  return null;
}
export default Landing;
