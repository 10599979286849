import React from "react";

export function TemplateSlide({ slide, height, width, active }) {
  const template = slide.template_selectedtemplate;
  const underscoredName = template.name.replace(/ /g, `_`);
  console.log(template.name);

  if (slide.template_textarea1)
    slide.template_textarea1 = slide.template_textarea1.replace("\n", "<br/>");
  if (slide.template_textarea2)
    slide.template_textarea2 = slide.template_textarea2.replace("\n", "<br/>");
  if (slide.template_textarea3)
    slide.template_textarea3 = slide.template_textarea3.replace("\n", "<br/>");
  if (slide.template_textarea4)
    slide.template_textarea4 = slide.template_textarea4.replace("\n", "<br/>");
  return (
    <>
      <link href={template.css_path} rel="stylesheet" />
      <div
        style={{
          background: slide.template_image_background
            ? `url(${slide.template_image_background})`
            : slide.template_background_colour,
          height,
          width,
        }}
      >
        {/*IMAGES*/}
        {slide.template_image1_path ? (
          <img
            id={`${underscoredName}_image_1`}
            className={`${underscoredName}_image`}
            src={slide.template_image1_path}
            alt=""
          />
        ) : null}
        {slide.template_image2_path ? (
          <img
            id={`${underscoredName}_image_2`}
            className={`${underscoredName}_image`}
            src={slide.template_image2_path}
            alt=""
          />
        ) : null}
        {slide.template_image3_path ? (
          <img
            id={`${underscoredName}_image_3`}
            className={`${underscoredName}_image`}
            src={slide.template_image3_path}
            alt=""
          />
        ) : null}
        {slide.template_image4_path ? (
          <img
            id={`${underscoredName}_image_4`}
            className={`${underscoredName}_image`}
            src={slide.template_image4_path}
            alt=""
          />
        ) : null}
        {/*TITLES*/}
        {slide.template_title1 ? (
          <div
            id={`${underscoredName}_title_1`}
            className={`${underscoredName}_title`}
            style={{ color: slide.template_colour1 }}
          >
            {slide.template_title1}
          </div>
        ) : null}
        {slide.template_title2 ? (
          <div
            id={`${underscoredName}_title_2`}
            className={`${underscoredName}_title`}
            style={{ color: slide.template_colour2 }}
          >
            {slide.template_title2}
          </div>
        ) : null}
        {slide.template_title3 ? (
          <div
            id={`${underscoredName}_title_3`}
            className={`${underscoredName}_title`}
            style={{ color: slide.template_colour3 }}
          >
            {slide.template_title3}
          </div>
        ) : null}
        {slide.template_title4 ? (
          <div
            id={`${underscoredName}_title_4`}
            className={`${underscoredName}_title`}
            style={{ color: slide.template_colour4 }}
          >
            {slide.template_title4}
          </div>
        ) : null}
        {/*TEXTAREAS*/}
        {slide.template_textarea1 ? (
          <div
            id={`${underscoredName}_textarea_1`}
            className={`${underscoredName}_textarea`}
            style={{ color: slide.template_textareacolour1 }}
            dangerouslySetInnerHTML={{ __html: slide.template_textarea1 }}
          ></div>
        ) : null}
        {slide.template_textarea2 ? (
          <div
            id={`${underscoredName}_textarea_2`}
            className={`${underscoredName}_textarea`}
            style={{ color: slide.template_textareacolour2 }}
            dangerouslySetInnerHTML={{ __html: slide.template_textarea2 }}
          ></div>
        ) : null}
        {slide.template_textarea3 ? (
          <div
            id={`${underscoredName}_textarea_3`}
            className={`${underscoredName}_textarea`}
            style={{ color: slide.template_textareacolour3 }}
            dangerouslySetInnerHTML={{ __html: slide.template_textarea3 }}
          ></div>
        ) : null}
        {slide.template_textarea4 ? (
          <div
            id={`${underscoredName}_textarea_4`}
            className={`${underscoredName}_textarea`}
            style={{ color: slide.template_textareacolour4 }}
            dangerouslySetInnerHTML={{ __html: slide.template_textarea4 }}
          ></div>
        ) : null}
      </div>
    </>
  );
}

export default TemplateSlide;
