const roles = {
  Webmaster: 0,
  Admin: 1,
  Editor: 2,
  Contributor: 3,
};

export const roleNames = ["Webmaster", "Admin", "Editor", "Contributor"];

export const canArchiveSlide = (user, slide) => {
  return (
    user.roleName === roles.Webmaster ||
    (user.roleName === roles.Admin && user.PortalID === slide.PortalID)
  );
};

export const canEditSlide = (user, slide) => {
  return (
    user.roleName === roles.Webmaster ||
    (user.PortalID === slide.PortalID &&
      (user.roleName === roles.Admin || user.roleName === roles.Editor)) ||
    (user.roleName === roles.Contributor && user.userName === slide.creator)
  );
};
export const canCreateUsers = (user) => {
  return user.roleName === roles.Webmaster || user.roleName === roles.Admin;
};

export const canEditUser = (user, user2) => {
  //user 1 is auth user that doesn't have all the same fields as database users, hence name vs userName and not comparing users by ID. Not sure how long that would take to change
  return (
    user.roleName === roles.Webmaster ||
    user.roleName < user2.roleName ||
    user.name === user2.userName
  );
};

export const canDeleteUser = (user, user2) => {
  //a webmaster can't delete itself so we can't accidentally delete them all
  return (
    !(user.name === user2.userName && user2.roleName === roles.Webmaster) &&
    (user.roleName === roles.Webmaster ||
      user.roleName < user2.roleName ||
      user.name === user2.userName)
  );
};

export const canEditUserRole = (user, user2) => {
  //as above webmaster can't un-webmaster itself so we can't accidentally run out of webmasters
  return (
    !(user.name === user2.userName && user2.roleName === roles.Webmaster) &&
    (user.roleName === roles.Webmaster ||
      user.roleName < user2.roleName ||
      user.name === user2.userName)
  );
};

export const canAssignRole = (user, role) => {
  return user.roleName <= role;
};

export const canEditAreas = (user) => {
  return user.roleName === roles.Webmaster;
};

export const canEditChannels = (user) => {
  return user.roleName === roles.Webmaster || user.roleName === roles.Admin;
};

export const canAddAndDeleteChannels = (user) => {
  return user.roleName === roles.Webmaster;
};

export const canSortChannels = (user) => {
  return (
    user.roleName === roles.Webmaster ||
    user.roleName === roles.Admin ||
    user.roleName === roles.Editor
  );
};

export const canViewHiddenAreas = (user) => {
  return user.roleName === roles.Webmaster;
};

export const hasRestrictedChannels = (user) => {
  return user.roleName === roles.Editor || user.roleName === roles.Contributor;
};
