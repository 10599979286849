import React from "react";
import axios from "axios";

function VideoUploader(props) {
  //const auth = useSelector((state) => state.auth);

  const noExt = props.slide.video_path
    ? props.slide.video_path.replace(/\.[^/.]+$/, "")
    : "";
  const ext = props.slide.video_path
    ? props.slide.video_path.match(/[^/.]+$/, "")
    : "";

  const onChangeHandler = (e) => {
    console.log("Attempting to upload video...");
    const data = new FormData();
    data.append("file", e.target.files[0]);
    axios
      .post(`/uploadVideo/${props.slide.PortalId}`, data, {})
      .then((res) => {
        const path = res.data.location;
        console.log(path);
        props.updateSlide(props.property, path);
      })
      .catch((err) => {
        props.setMessage(err.response.data);
      });
  };
  return (
    <div>
      <div>
        <input type="file" name="file" onChange={onChangeHandler} />
      </div>
      <div
        className="videopreview"
        style={{
          backgroundColor: "#dcdcdc",
          padding: "10px",
          width: "250px",
        }}
      >
        {props.slide.video_path ? (
          <video id={props.slide._id} width="250px" autoPlay muted loop>
            <source src={props.slide.video_path} type={`video/${ext}`} />
            {false && ext !== "mp4" ? (
              <source src={`${noExt}.mp4`} type="video/mp4" />
            ) : null}
            {false && ext !== "webm" ? (
              <source src={`${noExt}.webm`} type="video/webm" />
            ) : null}
            {false && ext !== "ogg" ? (
              <source src={`${noExt}.ogg`} type="application/ogg" />
            ) : null}
            {false && ext !== "ogv" ? (
              <source src={`${noExt}.ogv`} type="video/ogg" />
            ) : null}
            Your browser does not support the video tag.
          </video>
        ) : null}
      </div>
    </div>
  );
}

export default VideoUploader;
