export const getDate = (date) => {
  //the default/empty date from mongo wouldn't work with the datepicker, this solves it...
  if (date) {
    if (date instanceof Date) {
      return date;
    } else {
      date = new Date(date);
      if (date.getFullYear() === 0) {
        return null; //return null if this is the default/empty date from mongo
      } else return date;
    }
  } else return null;
};

export const isNotInPast = (date) => {
  let today = new Date();
  today.setDate(today.getDate() - 1); //subtract a day because the start of the current day would always be in the past and we want to be able to select the current day.
  return date.getTime() >= today.getTime();
};
