import {
  SET_SELECTED_AREA,
  SET_SELECTED_SLIDE_TYPE,
  SET_SELECTED_SLIDE,
  SET_SELECTED_PORTAL,
  SET_SELECTED_CHANNEL,
  SET_SELECTED_USER,
  SET_SELECTED_TEMPLATE,
  SET_SELECTED_CL_CATEGORY,
} from "../actions/types";
const initialState = {};
export default function (state = initialState, action) {
  switch (action.type) {
    case SET_SELECTED_AREA:
      return {
        ...state,
        area: action.payload,
      };
    case SET_SELECTED_SLIDE_TYPE:
      return {
        ...state,
        slideType: action.payload,
      };
    case SET_SELECTED_SLIDE:
      return {
        ...state,
        slide: action.payload,
      };
    case SET_SELECTED_PORTAL:
      return {
        ...state,
        portal: action.payload,
      };
    case SET_SELECTED_CHANNEL:
      return {
        ...state,
        channel: action.payload,
      };
    case SET_SELECTED_USER:
      return {
        ...state,
        user: action.payload,
      };
    case SET_SELECTED_TEMPLATE:
      return {
        ...state,
        template: action.payload,
      };
    case SET_SELECTED_CL_CATEGORY:
      return {
        ...state,
        category: action.payload,
      };
    default:
      return state;
  }
}
