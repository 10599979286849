import React from "react";
import { Switch } from "react-router-dom";
import Header from "./Header";
import Matrix from "./Matrix";
import Instructions from "./Instructions";
import ControlPanel from "./ControlPanel";

import PrivateRoute from "../private-route/PrivateRoute";
import EditChannel from "./EditChannel";
import EditUser from "./EditUser";
import EditArea from "./EditArea";
import SortSlides from "./SortSlides";
import Archive from "./Archive";
import ChooseSlideType from "../SlideEditor/ChooseSlideType";
import EditSlide from "../SlideEditor/EditSlide";
import PortalSwitcher from "../misc/PortalSwitcher";
import Changelog from "./Changelog";
import WebmasterControlPanel from "./Webmaster/WebmasterControlPanel";
import SortChannels from "./SortChannels";
import SortAreas from "./SortAreas";

function Dashboard() {
  return (
    <div className="container valign-wrapper">
      <Header></Header>
      <div style={{ padding: "10px" }}>
        <PrivateRoute exact path="/dashboard" component={PortalSwitcher} />
        <PrivateRoute
          exact
          path="/dashboard/controlpanel"
          component={PortalSwitcher}
        />
        <Switch>
          <PrivateRoute exact path="/dashboard" component={Matrix} />
          <PrivateRoute
            exact
            path="/dashboard/instructions"
            component={Instructions}
          />
          <PrivateRoute
            path="/dashboard/webmaster"
            component={WebmasterControlPanel}
          />
          <PrivateRoute
            exact
            path="/dashboard/controlpanel"
            component={ControlPanel}
          />
          <PrivateRoute
            exact
            path="/dashboard/controlpanel/editchannel"
            component={EditChannel}
          />
          <PrivateRoute
            exact
            path="/dashboard/controlpanel/edituser"
            component={EditUser}
          />
          <PrivateRoute
            exact
            path="/dashboard/controlpanel/editarea"
            component={EditArea}
          />

          <PrivateRoute
            exact
            path="/dashboard/controlpanel/sortchannels"
            component={SortChannels}
          />

          <PrivateRoute
            exact
            path="/dashboard/controlpanel/sortareas"
            component={SortAreas}
          />

          <PrivateRoute
            exact
            path="/dashboard/sortslides"
            component={SortSlides}
          />

          <PrivateRoute
            exact
            path="/dashboard/chooseslidetype"
            component={ChooseSlideType}
          />
          <PrivateRoute
            exact
            path="/dashboard/EditSlide"
            component={EditSlide}
          />
          <PrivateRoute exact path="/dashboard/archive" component={Archive} />

          <PrivateRoute
            exact
            path="/dashboard/changelog"
            component={Changelog}
          />
        </Switch>
      </div>
    </div>
  );
}

export default Dashboard;
