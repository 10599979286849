import React from "react";

function ActivatingASlide() {
  return (
    <div>
      <h2>Activating A Slide</h2>
      <p>
        On the main screen, look for the column representing the desired channel
        to add the slide to, and the row corresponding to the slide, and click
        the appropriate checkbox. A slide will never display on a channel if it
        is not checked for that channel.
      </p>
    </div>
  );
}

export default ActivatingASlide;
