import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ImageUploader from "./ImageUploader";
import axios from "axios";
import ColorPicker from "../../utils/ColorPicker";
const isEmpty = require("is-empty");

function TemplateProperties(props) {
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState({});

  const auth = useSelector((state) => state.auth);
  useEffect(() => {
    axios
      .post("/api/templates/gettemplatesbyportal", {
        PortalId: auth.user.PortalId,
      })
      .then((res) => setTemplates(res.data));
  }, [auth.user.PortalId]);

  useEffect(() => {
    if (props.slide.template_selectedtemplate) {
      const sel = templates.find(
        (item) => item._id === props.slide.template_selectedtemplate
      );
      console.log(sel);
      setSelectedTemplate(sel);
    }
  }, [templates, props.slide.template_selectedtemplate]);

  const backgroundTypeSelect = (e) => {
    console.log("BackgroundTypeSelect");
    console.log(props.slide);
    let newSlide = { ...props.slide };
    switch (e.target.value) {
      case "Transparent":
        newSlide.template_background_colour = "";
        newSlide.template_image_background = "";
        break;
      case "Colour":
        newSlide.template_image_background = "";
        break;
      case "Image":
        newSlide.template_background_colour = "";
        break;
      default:
        break;
    }
    newSlide.template_background_type = e.target.value;
    console.log(newSlide);
    props.setSlide(newSlide);
  };

  const colourChange = (color, e) => {
    props.updateSlide("template_background_colour", color.hex);
  };
  return (
    <div>
      <div className="addslidesectiontopbar">Background</div>
      <div className="addslidesection">
        <div>
          <label>
            <input
              id="rdoBackgroundType_Generic_0"
              type="radio"
              name="backgroundType"
              value="Transparent"
              checked={props.slide.template_background_type === "Transparent"}
              onChange={backgroundTypeSelect}
            />
            Transparent - Uses your Ve-Media default background
          </label>
        </div>
        <div>
          <label>
            <input
              id="rdoBackgroundType_Generic_1"
              type="radio"
              name="backgroundType"
              value="Colour"
              checked={
                props.slide.template_background_type === "Colour" ||
                !isEmpty(props.slide.template_background_colour)
              }
              onChange={backgroundTypeSelect}
            />
            Solid colour
          </label>
          {props.slide.template_background_type === "Colour" ||
          !isEmpty(props.slide.template_background_colour) ? (
            <ColorPicker
              color={props.slide.template_background_colour}
              onChange={colourChange}
            />
          ) : null}
        </div>

        <div>
          <label>
            <input
              id="rdoBackgroundType_Generic_2"
              type="radio"
              name="backgroundType"
              value="Image"
              checked={
                props.slide.template_background_type === "Image" ||
                !isEmpty(props.slide.template_image_background)
              }
              onChange={backgroundTypeSelect}
            />
            Use a slide background
          </label>
          {props.slide.template_background_type === "Image" ||
          props.slide.template_image_background ? (
            <ImageUploader
              slide={props.slide}
              updateSlide={props.updateSlide}
              property="template_image_background"
            ></ImageUploader>
          ) : null}
        </div>
      </div>
      <div className="addslidesectiontopbar">Choose a template</div>
      <div>Indoor Templates</div>
      <div className="addslidesection grid-fit-3">
        {templates.map((item) => {
          if (!item.outdoor)
            return (
              <>
                <img
                  alt={item.name}
                  src={item.icon_path}
                  height="160px"
                  width="300px"
                  className={
                    selectedTemplate === item ? "TemplateSelected" : "Template"
                  }
                  onClick={() => {
                    setSelectedTemplate(item);
                    props.updateSlide("template_selectedtemplate", item._id);
                  }}
                />
              </>
            );
          return null;
        })}
      </div>

      <div className="addslidesectiontopbar">Enter Template Information</div>
      <div className="addslidesection grid-fit-3">
        {selectedTemplate
          ? [...Array(selectedTemplate.num_images)].map((item, idx) => {
              const num = idx + 1;
              if (selectedTemplate && idx < selectedTemplate.num_images) {
                return (
                  <>
                    <label>Image {num}:</label>
                    <div>
                      <ImageUploader
                        slide={props.slide}
                        property={`template_image${num}_path`}
                        setMessage={props.setMessage}
                        updateSlide={props.updateSlide}
                      />
                    </div>
                    <div></div>
                  </>
                );
              } else return null;
            })
          : null}

        {selectedTemplate
          ? [...Array(selectedTemplate.num_titles)].map((item, idx) => {
              const num = idx + 1;
              if (selectedTemplate && idx < selectedTemplate.num_titles) {
                return (
                  <>
                    <label>Title {num}:</label>
                    <div>
                      <input
                        type="text"
                        value={props.slide[`template_title${num}`]}
                        onChange={(e) =>
                          props.updateSlide(
                            `template_title${num}`,
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <ColorPicker
                      color={props.slide[`template_colour${num}`] || "#000"}
                      onChange={(color, e) =>
                        props.updateSlide(`template_colour${num}`, color.hex)
                      }
                    />
                  </>
                );
              } else return null;
            })
          : null}

        {selectedTemplate
          ? [...Array(selectedTemplate.num_textareas)].map((item, idx) => {
              const num = idx + 1;
              if (selectedTemplate && idx < selectedTemplate.num_textareas) {
                return (
                  <>
                    <label>Textarea {num}:</label>
                    <div>
                      <textarea
                        value={props.slide[`template_textarea${num}`]}
                        onChange={(e) =>
                          props.updateSlide(
                            `template_textarea${num}`,
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <ColorPicker
                      color={
                        props.slide[`template_textareacolour${num}`] || "#000"
                      }
                      onChange={(color, e) =>
                        props.updateSlide(
                          `template_textareacolour${num}`,
                          color.hex
                        )
                      }
                    />
                  </>
                );
              } else return null;
            })
          : null}
      </div>
    </div>
  );
}

export default TemplateProperties;
