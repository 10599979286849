import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setSelectedChannel,
  setSelectedUser,
  setSelectedArea,
} from "../../actions/selectionActions";
import axios from "axios";
import { getStatus } from "../../utils/channelHelpers";
import {
  canCreateUsers,
  canEditUser,
  canDeleteUser,
  roleNames,
  canEditAreas,
} from "../../utils/roleHelpers";
import CustomCheckbox from "../misc/CustomCheckbox";

function ControlPanel(props) {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { user } = auth;
  const selectedPortal = useSelector((state) => state.selection.portal);
  const portal =
    user.isSuperUser && selectedPortal && selectedPortal !== undefined
      ? selectedPortal
      : user.PortalId;

  const [channels, setChannels] = useState([{}]);
  const [portalObj, setPortalObj] = useState(null);
  const [users, setUsers] = useState([{}]);
  const [areas, setAreas] = useState([{}]);
  useEffect(() => {
    axios
      .post("/api/portals/getControlPanelInfo", { PortalId: portal })
      .then((r) => {
        setChannels(r.data.channels);
        setPortalObj(r.data.portal);
        if (canCreateUsers(user)) {
          axios.post("/api/users/getusers", { PortalId: portal }).then((r) => {
            setUsers(r.data);

            if (canEditAreas(user)) {
              axios
                .post("/api/areas/getareas", { PortalId: portal })
                .then((r) => {
                  setAreas(r.data);
                });
            }
          });
        }
      });
  }, [portal, user]);

  const refreshChannel = (channelID) => {
    axios
      .patch("api/channels/updateChannel", {
        _id: channelID,
        PortalId: portal,
        update: {
          needsRefresh: true, //all channel updates trigger refresh now, but keeping this probably doesn't hurt.
        },
      })
      .then((r) => {
        //replace channel in state with result
        let channelsTemp = [...channels];
        channelsTemp = channelsTemp.map((item) =>
          //if it's not the channel being edited return the channel as is
          item._id !== r.data._id ? item : r.data
        );
        setChannels(channelsTemp);
      });
  };

  const refreshChannels = () => {
    axios
      .patch("api/channels/updatechannelsinportal", {
        PortalId: portal,
        update: { needsRefresh: true },
      })
      .then((r) => {
        setChannels(r.data);
      });
  };

  const editChannel = (channel) => {
    dispatch(setSelectedChannel(channel));
    props.history.push("/dashboard/controlpanel/editchannel");
  };

  const deleteChannel = (channel) => {
    if (window.confirm(`Really delete ${channel.name}?`)) {
      axios
        .post("api/channels/deleteChannel", {
          _id: channel._id,
          PortalId: portal,
        })
        .then((r) => {
          if (r.data.success) {
            let channelsTemp = [...channels];
            channelsTemp = channelsTemp.filter(
              (item) => item._id !== channel._id
            );
            setChannels(channelsTemp);
          } else alert(r.data.error);
        });
    }
  };

  const editUser = (user) => {
    dispatch(setSelectedUser(user));
    props.history.push("/dashboard/controlpanel/edituser");
  };

  const deleteUser = (user) => {
    if (window.confirm(`Really delete ${user.userName}?`)) {
      axios
        .post("api/users/deleteuser", {
          _id: user._id,
          PortalId: portal,
        })
        .then((r) => {
          if (r.data.success) {
            let usersTemp = [...users];
            usersTemp = usersTemp.filter((item) => item._id !== user._id);
            setUsers(usersTemp);
          } else alert(r.data.error);
        })
        .catch((e) => alert(e.response.data.error));
    }
  };
  const editArea = (area) => {
    dispatch(setSelectedArea(area));
    props.history.push("/dashboard/controlpanel/editarea");
  };
  const deleteArea = (area) => {
    if (window.confirm(`Really delete ${area.name}?`)) {
      axios
        .post("api/areas/delete", {
          _id: area._id,
          PortalId: portal,
        })
        .then((r) => {
          if (r.data.success) {
            let areasTemp = [...areas];
            areasTemp = areasTemp.filter((item) => item._id !== area._id);
            setAreas(areasTemp);
          } else alert(r.data.error);
        });
    }
  };

  const toggleOptOut = () => {
    axios
      .patch("/api/portals/toggleOptOut", {
        portal: portal,
      })
      .then((response) => {
        setPortalObj(response.data);
      })
      .catch((error) => alert("error: " + error));
  };

  const fontColor = { color: "#A21917" };
  const fontBold = { fontWeight: "bold" };
  const border = { border: "1px solid #AA2D2C" };

  const tableStyle = {
    display: "grid",
    padding: "5px",
  };
  const rowStyle = { borderBottom: "1px solid grey", padding: "3px 0px" };

  const greyButtonStyle = {
    border: "none",
    background: "none",
    color: "grey",
    cursor: "pointer",
  };

  const buttonDisabled = { opacity: "0.5", zIndex: -1, position: "relative" };

  const buttonAreaStyle = {
    textAlign: "left",
    padding: "4px 0 4px 6px",
  };

  return (
    <div>
      <h1
        style={{
          ...fontColor,
          fontSize: "24px",
          ...fontBold,
        }}
      >
        Control Panel
      </h1>
      <div
        className="grid-fr-2"
        style={{
          gap: "10px",
          ...fontColor,
        }}
      >
        <div>
          Player Status
          <div
            className="grid-1211"
            style={{
              ...border,
              ...tableStyle,
            }}
          >
            <span style={{ ...fontBold }}>Name</span>
            <span style={{ ...fontBold }}>Last Updated</span>
            <span style={{ ...fontBold }}>Status</span>
            <span style={{ ...fontBold }}></span>
            {channels.map((item, idx) => {
              const date = item.lastRefresh ? new Date(item.lastRefresh) : null;
              return (
                <React.Fragment key={idx}>
                  <span style={{ ...rowStyle }}>{item.name}</span>
                  <span style={{ ...rowStyle }}>
                    {date
                      ? `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
                      : "Never"}
                  </span>
                  <span style={{ ...rowStyle }}>{getStatus(date)}</span>
                  <span style={{ ...rowStyle }}>
                    <input
                      type="submit"
                      value={item.needsRefresh ? "Refreshing..." : "Refresh"}
                      className={`controlpanel_playerrefresh ${
                        item.needsRefresh ? "disabled" : ""
                      }`}
                      style={item.needsRefresh ? { ...buttonDisabled } : {}}
                      onClick={() => refreshChannel(item._id)}
                      disabled={item.needsRefresh}
                    />
                  </span>
                </React.Fragment>
              );
            })}
            {portalObj ? (
              <div>
                <label>Opt out of 'failed' channel alerts:</label>
                <CustomCheckbox
                  checked={portalObj.alertsOptOut}
                  onChange={() => toggleOptOut()}
                ></CustomCheckbox>
              </div>
            ) : null}
            <div style={{ ...buttonAreaStyle }}>
              <></>
              <input
                type="button"
                value="Refresh All"
                onClick={() => refreshChannels()}
                className="button_lightred"
                style={{ display: "inline-block" }}
              />
            </div>
          </div>
          <span>Note:</span>
          <span style={{ color: "black" }}>
            {" "}
            This page does not automatically update. It must be
            refreshed/revisited manually.
            <br />
            If player status reads "failed", you should manually restart the
            player (i.e. refresh the browser by pressing F5. or press the power
            button to turn it off then back on again).
          </span>
        </div>
        <div>
          Channels
          <div
            className="grid-311"
            style={{
              ...border,
              ...tableStyle,
            }}
          >
            <span style={{ ...fontBold }}>Name</span>
            <span style={{ ...fontBold }}></span>
            <span style={{ ...fontBold }}></span>
            {channels.map((item, idx) => {
              return (
                <React.Fragment key={idx}>
                  <span style={{ ...rowStyle }}>{item.name}</span>
                  <span style={{ ...rowStyle }}>
                    <input
                      type="submit"
                      style={{ ...greyButtonStyle }}
                      value="Edit"
                      onClick={() => editChannel(item)}
                    />
                  </span>
                  <span style={{ ...rowStyle }}>
                    <input
                      type="submit"
                      style={{ ...greyButtonStyle }}
                      value="Delete"
                      onClick={() => deleteChannel(item)}
                    />
                  </span>
                </React.Fragment>
              );
            })}
            <div style={{ ...buttonAreaStyle }}>
              <input
                type="button"
                value="Add New"
                onClick={() => {
                  dispatch(setSelectedChannel(null));
                  props.history.push("/dashboard/controlpanel/editchannel");
                }}
                className="button_lightred"
                style={{ display: "inline-block" }}
              />

              <input
                type="button"
                value="Sort Channels"
                onClick={() =>
                  props.history.push("/dashboard/controlpanel/sortchannels")
                }
                className="button_lightred"
                style={{ display: "inline-block" }}
              />
            </div>
          </div>
        </div>

        {canCreateUsers(user) ? (
          <div>
            Users
            <div
              className="grid-fr-4"
              style={{
                ...border,
                ...tableStyle,
              }}
            >
              <span style={{ ...fontBold }}>Name</span>
              <span style={{ ...fontBold }}>Role</span>
              <span style={{ ...fontBold }}></span>
              <span style={{ ...fontBold }}></span>
              {users.map((item, idx) => {
                return (
                  <React.Fragment key={idx}>
                    <span style={{ ...rowStyle }}>{item.userName}</span>
                    <span style={{ ...rowStyle }}>
                      {roleNames[item.roleName]}
                    </span>
                    <span style={{ ...rowStyle }}>
                      {canEditUser(user, item) ? (
                        <input
                          type="submit"
                          style={{ ...greyButtonStyle }}
                          value="Edit"
                          onClick={() => editUser(item)}
                        />
                      ) : null}
                    </span>
                    <span style={{ ...rowStyle }}>
                      {canDeleteUser(user, item) ? (
                        <input
                          type="submit"
                          style={{ ...greyButtonStyle }}
                          value="Delete"
                          onClick={() => deleteUser(item)}
                        />
                      ) : null}
                    </span>
                  </React.Fragment>
                );
              })}
              <div style={{ ...buttonAreaStyle }}>
                <input
                  type="button"
                  value="Add New"
                  onClick={() => {
                    dispatch(setSelectedUser(null));
                    props.history.push("/dashboard/controlpanel/edituser");
                  }}
                  className="button_lightred"
                />
              </div>
            </div>
          </div>
        ) : null}

        <div>
          Areas
          <div
            className="grid-311"
            style={{
              ...border,
              ...tableStyle,
            }}
          >
            {areas.map((area, idx) => {
              return (
                <React.Fragment key={idx}>
                  <span style={{ ...rowStyle }}>{area.name}</span>
                  <span style={{ ...rowStyle }}>
                    <input
                      type="submit"
                      style={{ ...greyButtonStyle }}
                      value="Edit"
                      onClick={() => editArea(area)}
                    />
                  </span>
                  <span style={{ ...rowStyle }}>
                    <input
                      type="submit"
                      style={{ ...greyButtonStyle }}
                      value="Delete"
                      onClick={() => deleteArea(area)}
                    />
                  </span>
                </React.Fragment>
              );
            })}
            <div style={{ ...buttonAreaStyle }}>
              <input
                type="button"
                value="Add New"
                onClick={() => {
                  dispatch(setSelectedArea(null));
                  props.history.push("/dashboard/controlpanel/editarea");
                }}
                className="button_lightred"
                style={{ display: "inline-block" }}
              />
              <input
                type="button"
                value="Sort Areas"
                onClick={() =>
                  props.history.push("/dashboard/controlpanel/sortareas")
                }
                className="button_lightred"
                style={{ display: "inline-block" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ControlPanel;
