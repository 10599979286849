import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";
import { getStatus } from "../../../utils/channelHelpers";
import { setSelectedPortal } from "../../../actions/selectionActions";

function PlayerStatus(props) {
  const [portals, setPortals] = useState([]);
  const dispatch = useDispatch();
  const refreshChannel = (portalID, channelID) => {
    axios
      .patch("api/channels/updateChannel", {
        _id: channelID,
        PortalId: portalID,
        update: {
          needsRefresh: true,
        },
      })
      .then((r) => {
        const newPortals = [...portals].map((item) => {
          const portal = item.portal;
          if (portal.PortalId === portalID)
            //if this is the portal whose channel we refreshed, replace the channel in its channels array with the result
            return {
              portal: item.portal,
              channels: item.channels.map((channel) =>
                channel._id !== r.data._id ? channel : r.data
              ),
            };
          else return item;
        });
        setPortals(newPortals);
      })
      .catch((err) => alert(err));
  };

  useEffect(() => {
    axios
      .get("/api/portals/getWebmasterControlPanel")
      .then((res) => setPortals(res.data));
  }, []);

  const buttonDisabled = { opacity: "0.5", position: "relative" };
  return (
    <div>
      <input
        type="button"
        value="Add New Portal"
        className="button_green"
        onClick={() => {
          dispatch(setSelectedPortal(null));
          props.history.push("/dashboard/webmaster/editportal");
        }}
      />
      {portals.length} portals.
      {portals.length > 0
        ? portals
            .sort((p1, p2) =>
              p1.portal.PortalName.localeCompare(p2.portal.PortalName)
            )
            .map((item, idx) => {
              const portalName = item.portal.PortalName;
              let active = 0;
              let inactive = 0;
              item.channels.forEach((channel) => {
                const date = channel.lastRefresh
                  ? new Date(channel.lastRefresh)
                  : null;
                const now = Date.now();

                if (date) {
                  const hours =
                    Math.abs(now - date.getTime()) / (60 * 60 * 1000);
                  if (hours < 720) active++;
                  else inactive++;
                } else inactive++;
              });
              return (
                <div>
                  {portalName}: {active} channels refreshed within 30 days,{" "}
                  {inactive} inactive channels
                </div>
              );
            })
        : null}
      {portals.length > 0 ? (
        portals
          .sort((p1, p2) =>
            p1.portal.PortalName.localeCompare(p2.portal.PortalName)
          )
          .map((item, idx) => (
            <div
              style={{ border: "1px solid black", marginBottom: "10px" }}
              key={idx}
            >
              <div className="controlpanel_header">
                {item.portal.PortalName}
              </div>
              <div className="grid-fr-5">
                <div className="webmaster-grid-header">Name</div>
                <div className="webmaster-grid-header">Slides</div>
                <div className="webmaster-grid-header">Last Refresh</div>
                <div className="webmaster-grid-header">Status</div>
                <div className="webmaster-grid-header">Refresh</div>
              </div>
              <div
                className="grid-fr-5"
                style={{
                  height: "165px",
                  overflow: "auto",
                  backgroundColor: "lightgrey",
                  rowGap: "5px",
                }}
              >
                {item.channels.map((channel, idx) => {
                  const date = channel.lastRefresh
                    ? new Date(channel.lastRefresh)
                    : null;
                  return (
                    <React.Fragment key={idx}>
                      <div>{channel.name}</div>
                      <div>
                        {channel.selectedSlides
                          ? channel.selectedSlides.reduce(
                              (acc, cur) => acc + cur.SlideIDs.length,
                              0
                            )
                          : 0}
                      </div>
                      <div>
                        {date
                          ? `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
                          : "Never"}
                      </div>
                      <div>{getStatus(date)}</div>
                      <div>
                        <input
                          type="submit"
                          value={
                            channel.needsRefresh ? "Refreshing..." : "Refresh"
                          }
                          className={`controlpanel_playerrefresh ${
                            channel.needsRefresh ? "disabled" : ""
                          }`}
                          style={
                            channel.needsRefresh ? { ...buttonDisabled } : {}
                          }
                          onClick={() =>
                            refreshChannel(channel.PortalId, channel._id)
                          }
                          disabled={channel.needsRefresh}
                        />
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
              <div style={{ padding: "5px", backgroundColor: "#999999" }}>
                <input
                  type="button"
                  value="Manage"
                  className="button_green"
                  onClick={() => {
                    dispatch(setSelectedPortal(item.portal));
                    props.history.push("/dashboard/webmaster/editportal");
                  }}
                />
              </div>
            </div>
          ))
      ) : (
        <div className="loader"></div>
      )}
    </div>
  );
}

export default PlayerStatus;
