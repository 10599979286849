import React from "react";

function WeatherProperties(props) {
  const styleOptions = ["Main Content", "Bottom Content", "Temperature Only"];
  return (
    <div>
      <div className="addslidesectiontopbar">Style</div>
      <div className="addslidesection grid-fit-2">
        <span>Display Type:</span>
        <select
          value={props.slide.weather_type}
          onChange={(e) => {
            props.updateSlide("weather_type", e.target.value);
          }}
        >
          {styleOptions.map((item) => {
            return (
              <option key={item} value={item}>
                {item}
              </option>
            );
          })}
        </select>
        <span>Font Size (temperature only):</span>
        <input
          type="text"
          placeholder="e.g. 450px"
          value={
            props.slide.weather_font_size !== undefined
              ? props.slide.weather_font_size
              : ""
          }
          onChange={(e) => {
            props.updateSlide("weather_font_size", e.target.value);
          }}
        />
      </div>

      <div className="addslidesectiontopbar">Content</div>
      <div className="addslidesection">
        <span>Weather RSS URL:</span>
        <input
          type="text"
          value={
            props.slide.weather_url !== undefined ? props.slide.weather_url : ""
          }
          onChange={(e) => {
            props.updateSlide("weather_url", e.target.value);
          }}
        />
        <a
          id="linkWeather"
          href="http://www.weatheroffice.gc.ca/canada_e.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          Look it up here.
        </a>
      </div>
    </div>
  );
}

export default WeatherProperties;
