import React, { useEffect, useState, useCallback } from "react";

export function InterestRateSlide({ slide, height, width }) {
  const [activePage, setActivePage] = useState(0);
  const [title, setTitle] = useState("");
  const [pagedEntries, setPagedEntries] = useState([]);
  const bottomContent = slide.interestrate_type === "Bottom Content";

  const slideStyles = {
    background: slide.interestrate_image_background
      ? `url(${slide.interestrate_image_background})`
      : slide.interestrate_background_colour,
    height,
    width,
    color: slide.interestrate_text_colour
      ? slide.interestrate_text_colour
      : "#000",
  };

  const interestRatesStyles = bottomContent
    ? {}
    : {
        position: "absolute",
        top: "65px",
        left: "50%",
        width: "30%",
      };

  const mainTitleStyles = bottomContent
    ? {}
    : {
        height: "60px",
        width: `${width / 2}px`,
        textAlign: "center",
        fontSize: "x-large",
        fontWeight: "bold",
        fontFamily: "Trebuchet MS",
      };

  const ratesItemStyles = bottomContent
    ? {
        width: `${width / 2}px`,
      }
    : {
        backgroundColor: "#C8CBDE",
        color: "#1B2675",
        width: `${width / 2}px`,
        height: "60px",
        marginBottom: "10px",
      };
  const activeStyles = {
    opacity: "1",
    transition: "opacity 0.5s",
    //position: "absolute",
  };
  const inactiveStyles = {
    ...activeStyles,
    opacity: "0",
  };

  const innerStyles = bottomContent
    ? { width: "100%", height: "40px" }
    : {
        width: "100%",
        height: "40px",
        fontSize: "xx-large",
      };

  const innerTitleStyles = bottomContent
    ? { display: "inline-block", position: "absolute" }
    : {
        display: "inline-block",
        //position: "absolute",
        left: "25px",
        float: "left",
      };

  const rateStyles = {
    display: "inline-block",
    //position: "absolute",
    right: "25px",
    float: "right",
  };

  let tempPagedEntries = [];
  let perPage = slide.interestrate_items_per_page
    ? slide.interestrate_items_per_page
    : 1;

  const getJSON = () => {
    let json = {};
    var xmlhttp = new XMLHttpRequest();
    xmlhttp.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        try {
          json = JSON.parse(this.responseText);
        } catch (e) {
          json = {
            title: "Error",
            item: [{ description: "", rate: e.toString() }],
          };
        }
      }
    };
    xmlhttp.open("GET", slide.interestrate_path, false);
    xmlhttp.send();
    return json;
  };

  const isXML = () => {
    return slide.interestrate_path.includes("xml version");
  };
  useEffect(() => {
    console.log(slide.interestrate_path.replace("https://ve-media.com", "")); //for some reason on my local machine it puts the URL in the XML
    if (isXML()) {
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(
        slide.interestrate_path.replace("https://ve-media.com", ""),
        `text/xml`
      );
      setTitle(
        Array.from(
          xmlDoc.getElementsByTagName(`channel`)
        )[0].getElementsByTagName(`title`)[0].textContent
      );

      const entries = Array.from(xmlDoc.getElementsByTagName(`item`)).slice(
        0,
        slide.interestrate_items_to_display
      );

      //split the entries into pages
      let remainingEntries = true;
      let i = 0;
      while (remainingEntries) {
        if (entries.slice(0 + i * perPage, perPage + i * perPage).length > 0) {
          tempPagedEntries[i] = entries.slice(
            0 + i * perPage,
            perPage + i * perPage
          );
          if (tempPagedEntries[i].length < perPage) remainingEntries = false; //if this is less than a full page there must not be anything left
        } else remainingEntries = false;
        i++;
      }
      setPagedEntries(tempPagedEntries);
    } else {
      const json = getJSON();

      setTitle(json.title);
      const entries = json.item.slice(0, slide.interestrate_items_to_display);
      console.log(entries);

      //split the entries into pages
      let remainingEntries = true;
      let i = 0;
      while (remainingEntries) {
        if (entries.slice(0 + i * perPage, perPage + i * perPage).length > 0) {
          tempPagedEntries[i] = entries.slice(
            0 + i * perPage,
            perPage + i * perPage
          );
          if (tempPagedEntries[i].length < perPage) remainingEntries = false; //if this is less than a full page there must not be anything left
        } else remainingEntries = false;
        i++;
      }
      setPagedEntries(tempPagedEntries);
    }
  }, []);

  const pageDuration = slide.interestrate_transition_delay * 1000;
  const switchPages = useCallback(
    (current) => {
      const next = current + 1 > pagedEntries.length - 1 ? 0 : current + 1;
      setActivePage(next);
      setTimeout(() => switchPages(next), pageDuration);
    },
    [pageDuration, pagedEntries.length]
  );

  const getElement = useCallback(
    (entry, property) => {
      if (isXML()) {
        const xmlProperties = { description: "title", rate: "description" };
        return entry.getElementsByTagName(xmlProperties[property])[0]
          .textContent;
      } else return entry[property];
    },
    [slide.interestrate_path, isXML]
  );

  useEffect(() => {
    setTimeout(() => switchPages(0), pageDuration);
  }, [switchPages, pageDuration]);

  return (
    <div style={slideStyles}>
      <div style={interestRatesStyles}>
        <div style={mainTitleStyles}>{title}</div>
        {pagedEntries.map((page, idx) => {
          return (
            <div
              style={idx === activePage ? activeStyles : inactiveStyles}
              key={idx}
            >
              {page.map((entry, idx) => {
                return (
                  <div key={idx} style={ratesItemStyles}>
                    <span style={innerStyles}>
                      <div style={innerTitleStyles}>
                        {getElement(entry, "description")}
                      </div>
                      <div style={rateStyles}>{getElement(entry, "rate")}</div>
                    </span>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default InterestRateSlide;
