import React from "react";

function ArchiveInstructions() {
  return (
    <div>
      <h2>Archive</h2>
      <p>
        This is long term storage for slides you aren’t using right now. By
        using the filter box you can quickly search your slides by name or type.
        It is accessed through a button on the bottom right of the main screen.
      </p>
    </div>
  );
}

export default ArchiveInstructions;
