import React, { useState } from "react";
import axios from "axios";

function ImageUploader(props) {
  //TODO rename slide stuff to be more generic as this can also be used for channel

  const [loading, setLoading] = useState(false);

  const onChangeHandler = (e) => {
    const data = new FormData();
    data.append("file", e.target.files[0]);
    if (props.customPath) data.append("path", props.customPath);
    if (props.customName) data.append("filename", props.customName);
    setLoading(true);
    axios
      .post(`/upload/${props.slide.PortalId}`, data, {})
      .then((res) => {
        const path = res.data.location;
        props.updateSlide(props.property, path);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        props.setMessage(err.response.data);
        setLoading(false);
      });
  };

  return (
    <div>
      <div>
        <input type="file" name="file" onChange={onChangeHandler} />
      </div>
      <div
        className="imagepreview"
        style={{
          backgroundColor: "#dcdcdc",
          padding: "10px",
          width: "250px",
        }}
      >
        {loading ? (
          <div className="loader"></div>
        ) : (
          <img
            alt="Preview"
            id="imgPreview"
            src={props.slide[props.property]}
            style={{ height: "150px", width: "250px" }}
          />
        )}
      </div>
    </div>
  );
}

export default ImageUploader;
