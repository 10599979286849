import React, { useCallback, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import SortableChannel from "./SortableChannel";
import update from "immutability-helper";
import axios from "axios";

function SortChannels(props) {
  const auth = useSelector((state) => state.auth);
  const { user } = auth;
  const selectedPortal = useSelector((state) => state.selection.portal);
  const portal =
    user.isSuperUser && selectedPortal && selectedPortal !== undefined
      ? selectedPortal
      : user.PortalId;

  const [channels, setChannels] = useState([{}]);
  const [portalObj, setPortalObj] = useState(null);
  useEffect(() => {
    axios
      .post("/api/portals/getControlPanelInfo", { PortalId: portal })
      .then((r) => {
        console.log(r.data);
        setChannels(r.data.channels);
        setPortalObj(r.data.portal);
      });
  }, [portal]);

  const [message, setMessage] = useState("");

  const moveChannel = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = channels[dragIndex];
      setChannels(
        update(channels, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        })
      );
    },
    [channels]
  );

  const renderChannel = (channel, index) => {
    if (channel && channel.archived) return null;
    if (!channel) return null;
    return (
      <SortableChannel
        key={index}
        index={index}
        channel={channel}
        moveChannel={moveChannel}
      />
    );
  };

  const saveChannels = () => {
    console.log(channels);
    let i = 1;
    let numSaved = 1;
    channels.forEach((channel) => {
      axios
        .patch("api/channels/updateChannel", {
          _id: channel._id,
          PortalId: channel.PortalId,
          update: {
            orderingNumber: i,
          },
        })
        .then(() => {
          setMessage(`Save successful (${numSaved}/${channels.length}).`);
          numSaved++;
        })
        .catch((e) => {
          setMessage(e.response.data.error);
        });
      i++;
    });
  };

  if (!portalObj) {
    return null;
  } else {
    console.log(portalObj);
    return (
      <DndProvider backend={HTML5Backend}>
        <span>
          Sorting channels in
          <strong> {portalObj.PortalName}</strong>
        </span>
        {channels.map((channel, idx) => renderChannel(channel, idx))}

        <input
          className="button_lightred"
          type="button"
          value="Save Changes"
          onClick={() => saveChannels()}
        />
        <input
          className="button_lightred"
          type="button"
          value="<< Back"
          onClick={() => props.history.push("/dashboard/")}
        />
        <span>{message}</span>
      </DndProvider>
    );
  }
}

export default SortChannels;
