import React, { useState, useEffect } from "react";
import ImageUploader from "./ImageUploader";
import ColorPicker from "../../utils/ColorPicker";
import axios from "axios";
import XMLUploader from "./XMLUploader";
const isEmpty = require("is-empty");

function InterestRateProperties(props) {
  const styleOptions = ["Main Content", "Bottom Content"];
  const sourceOptions = ["Input URL Directly", "Upload XML File", "Create New"];

  const [createNewTitle, setCreateNewTitle] = useState("");
  const [createNewDescription, setCreateNewDescription] = useState("");
  const [createNewRows, setCreateNewRows] = useState([
    { description: "", rate: "" },
  ]);

  const getJSON = () => {
    let json = {};
    var xmlhttp = new XMLHttpRequest();
    xmlhttp.onreadystatechange = function () {
      if (this.readyState === 4 && this.status === 200) {
        try {
          json = JSON.parse(this.responseText);
        } catch (e) {
          json = {
            title: "Error",
            item: [{ description: "", rate: e.toString() }],
          };
        }
      }
    };
    xmlhttp.open("GET", props.slide.interestrate_path, false);
    xmlhttp.send();
    return json;
  };

  useEffect(() => {
    if (props.slide.interestrate_source === sourceOptions[2]) {
      const parser = new DOMParser();
      if (props.slide.interestrate_path) {
        if (props.slide.interestrate_path.includes(".xml")) {
          axios
            .get(props.slide.interestrate_path)
            .then((res) => {
              const xmlDoc = parser.parseFromString(res.data, `text/xml`);
              const title = Array.from(
                xmlDoc.getElementsByTagName(`channel`)
              )[0].getElementsByTagName(`title`)[0].textContent;
              setCreateNewTitle(title);

              const description = Array.from(
                xmlDoc.getElementsByTagName(`channel`)
              )[0].getElementsByTagName(`description`)[0].textContent;
              setCreateNewDescription(description);

              const entries = Array.from(xmlDoc.getElementsByTagName(`item`));
              let arr = [];
              for (let entry in entries) {
                const entryTitle =
                  entries[entry].getElementsByTagName(`title`)[0].textContent;
                const entryDescription =
                  entries[entry].getElementsByTagName(`description`)[0]
                    .textContent;
                arr.push({ description: entryTitle, rate: entryDescription });
              }
              setCreateNewRows(arr);
            })

            .catch((err) => console.log(err));
        } else {
          const json = getJSON();
          setCreateNewTitle(json.title);
          setCreateNewDescription(json.description);
          setCreateNewRows(json.item);
        }
      }
    }
  }, [props.slide.interestrate_source, props.slide.interestrate_path]);

  const backgroundTypeSelect = (e) => {
    let newSlide = { ...props.slide };
    switch (e.target.value) {
      case "Transparent":
        newSlide.interestrate_background_colour = "";
        newSlide.interestrate_image_background = "";
        break;
      case "Colour":
        newSlide.interestrate_image_background = "";
        if (!newSlide.interestrate_background_colour)
          newSlide.interestrate_background_colour = "#ffffff";
        break;
      case "Image":
        newSlide.interestrate_background_colour = "";
        break;
      default:
        break;
    }
    newSlide.interestrate_background_type = e.target.value;
    props.setSlide(newSlide);
  };

  const colourChange = (color, e) => {
    props.updateSlide("interestrate_background_colour", color.hex);
  };

  const uploadXML = () => {
    axios
      .post("/api/slides/xmlwrite", {
        PortalId: props.slide.PortalId,
        title: createNewTitle,
        description: createNewDescription,
        rows: createNewRows,
      })
      .then((res) => {
        const path = res.data.path.replace("./public", "");
        props.updateSlide("interestrate_path", path);
        alert(`${path} uploaded successfully.`);
      });
  };

  return (
    <div>
      <div className="addslidesectiontopbar">Background</div>
      <div className="addslidesection">
        <div>
          <label>
            <input
              id="rdoBackgroundType_Generic_0"
              type="radio"
              name="backgroundType"
              value="Transparent"
              checked={
                isEmpty(props.slide.interestrate_background_colour) &&
                isEmpty(props.slide.interestrate_image_background) &&
                props.slide.interestrate_background_type !== "Image"
              }
              onChange={backgroundTypeSelect}
            />
            Transparent - Uses your Ve-Media default background
          </label>
        </div>
        <div>
          <label>
            <input
              id="rdoBackgroundType_Generic_1"
              type="radio"
              name="backgroundType"
              value="Colour"
              checked={
                props.slide.interestrate_background_type === "Colour" ||
                !isEmpty(props.slide.interestrate_background_colour)
              }
              onChange={backgroundTypeSelect}
            />
            Solid colour
          </label>
          {props.slide.interestrate_background_type === "Colour" ||
          !isEmpty(props.slide.interestrate_background_colour) ? (
            <ColorPicker
              color={props.slide.interestrate_background_colour}
              onChange={colourChange}
            />
          ) : null}
        </div>

        <div>
          <label>
            <input
              id="rdoBackgroundType_Generic_2"
              type="radio"
              name="backgroundType"
              value="Image"
              checked={
                props.slide.interestrate_background_type === "Image" ||
                !isEmpty(props.slide.interestrate_image_background)
              }
              onChange={backgroundTypeSelect}
            />
            Use a slide background
          </label>
          {props.slide.interestrate_background_type === "Image" ||
          props.slide.interestrate_image_background ? (
            <ImageUploader
              slide={props.slide}
              updateSlide={props.updateSlide}
              property="interestrate_image_background"
            ></ImageUploader>
          ) : null}
        </div>
      </div>

      <div className="addslidesectiontopbar">Style</div>
      <div className="addslidesection grid-fit-2">
        <label>Display Type:</label>
        <select
          value={props.slide.interestrate_type}
          onChange={(e) => {
            props.updateSlide("interestrate_type", e.target.value);
          }}
        >
          {styleOptions.map((item) => {
            return (
              <option key={item} value={item}>
                {item}
              </option>
            );
          })}
        </select>
        <label>Rate Background Colour:</label>
        <ColorPicker
          color={props.slide.interestrate_item_background_colour}
          defaultColor={"#fff"}
          onChange={(color, e) =>
            props.updateSlide("interestrate_item_background_colour", color.hex)
          }
        />
        <label>Rate Font Colour:</label>
        <ColorPicker
          color={props.slide.interestrate_item_font_colour}
          onChange={(color, e) =>
            props.updateSlide("interestrate_item_font_colour", color.hex)
          }
        />
      </div>
      <div className="addslidesectiontopbar">Content</div>
      <div className="addslidesection grid-fit-2">
        <label>Source of Interest Rates:</label>
        <select
          value={props.slide.interestrate_source}
          onChange={(e) => {
            props.updateSlide("interestrate_source", e.target.value);
          }}
        >
          {sourceOptions.map((item) => {
            return (
              <option key={item} value={item}>
                {item}
              </option>
            );
          })}
        </select>

        <label>Items to Display:</label>
        <input
          type="text"
          value={props.slide.interestrate_items_to_display}
          onChange={(e) => {
            props.updateSlide(
              "interestrate_items_to_display",
              parseInt(e.target.value, 10) || 0
            );
          }}
        />
        <label>Time Between Pages:</label>
        <input
          type="text"
          value={props.slide.interestrate_transition_delay}
          onChange={(e) => {
            props.updateSlide(
              "interestrate_transition_delay",
              parseInt(e.target.value, 10) || 0
            );
          }}
        />
        <label>Items per Page:</label>
        <input
          type="text"
          value={props.slide.interestrate_items_per_page}
          onChange={(e) => {
            props.updateSlide(
              "interestrate_items_per_page",
              parseInt(e.target.value, 10) || 0
            );
          }}
        />
      </div>

      {!props.slide.interestrate_source ||
      props.slide.interestrate_source === sourceOptions[0] ? (
        <div className="addslidesection grid-fit-2">
          <label>Feed URL:</label>
          <input
            type="text"
            value={
              props.slide.interestrate_path ? props.slide.interestrate_path : ""
            }
            onChange={(e) =>
              props.updateSlide("interestrate_path", e.target.value)
            }
          />
        </div>
      ) : null}

      {props.slide.interestrate_source === sourceOptions[1] ? (
        <div className="addslidesection grid-fit-2">
          <XMLUploader
            slide={props.slide}
            updateSlide={props.updateSlide}
            property="interestrate_path"
            setMessage={props.setMessage}
          />
        </div>
      ) : null}
      {props.slide.interestrate_source === sourceOptions[2] ? (
        <>
          <div className="addslidesection grid-fit-2">
            <label>Title (will be shown as the header of the slide):</label>
            <input
              type="text"
              value={createNewTitle}
              onChange={(e) => setCreateNewTitle(e.target.value)}
            />
            <label>Description:</label>
            <input
              type="text"
              value={createNewDescription}
              onChange={(e) => setCreateNewDescription(e.target.value)}
            />
          </div>
          <div>
            <div
              className="grid-fit-3"
              style={{
                columnGap: "10px",
                rowGap: "5px",
                justifyItems: "center",
              }}
            >
              <strong>Description</strong>
              <strong>Rate</strong>
              <strong>Delete</strong>

              {createNewRows.map((item, idx) => {
                return (
                  <React.Fragment key={idx}>
                    <input
                      type="text"
                      value={item.description}
                      onChange={(e) => {
                        let newRows = [...createNewRows];
                        newRows[idx].description = e.target.value;
                        setCreateNewRows(newRows);
                      }}
                    />

                    <input
                      type="text"
                      value={item.rate}
                      onChange={(e) => {
                        let newRows = [...createNewRows];
                        newRows[idx].rate = e.target.value;
                        setCreateNewRows(newRows);
                      }}
                    />

                    <input
                      type="button"
                      value="Delete"
                      onClick={(e) => {
                        let newRows = [...createNewRows];
                        newRows.splice(idx, 1);
                        setCreateNewRows(newRows);
                      }}
                    />
                  </React.Fragment>
                );
              })}
            </div>

            <input
              type="button"
              value="Add Row"
              onClick={(e) => {
                let newRows = [...createNewRows];
                newRows.push({ description: "", rate: "" });
                setCreateNewRows(newRows);
              }}
            />
            <input
              type="button"
              value="Finish and Upload"
              onClick={uploadXML}
            />
          </div>
        </>
      ) : null}
    </div>
  );
}

export default InterestRateProperties;
