import React, { useCallback, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import SortableArea from "./SortableArea";
import update from "immutability-helper";
import axios from "axios";

function SortAreas(props) {
  const auth = useSelector((state) => state.auth);
  const { user } = auth;
  const selectedPortal = useSelector((state) => state.selection.portal);
  const portal =
    user.isSuperUser && selectedPortal && selectedPortal !== undefined
      ? selectedPortal
      : user.PortalId;

  const [areas, setAreas] = useState([{}]);
  useEffect(() => {
    axios.post("/api/areas/getareas", { PortalId: portal }).then((r) => {
      setAreas(r.data);
    });
  }, [portal]);

  const [message, setMessage] = useState("");

  const moveArea = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = areas[dragIndex];
      setAreas(
        update(areas, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragCard],
          ],
        })
      );
    },
    [areas]
  );

  const renderArea = (area, index) => {
    if (area && area.archived) return null;
    if (!area) return null;
    return (
      <SortableArea key={index} index={index} area={area} moveArea={moveArea} />
    );
  };

  const saveAreas = () => {
    console.log(areas);
    let i = 1;
    let numSaved = 1;
    areas.forEach((area) => {
      axios
        .patch("api/areas/updateArea", {
          _id: area._id,
          PortalId: area.PortalId,
          update: {
            orderingNumber: i,
          },
        })
        .then(() => {
          setMessage(`Save successful (${numSaved}/${areas.length}).`);
          numSaved++;
        })
        .catch((e) => {
          setMessage(e.response.data.error);
        });
      i++;
    });
  };

  if (!areas) {
    return null;
  } else {
    return (
      <DndProvider backend={HTML5Backend}>
        <span>Sorting areas.</span>
        {areas.map((area, idx) => renderArea(area, idx))}

        <input
          className="button_lightred"
          type="button"
          value="Save Changes"
          onClick={() => saveAreas()}
        />
        <input
          className="button_lightred"
          type="button"
          value="<< Back"
          onClick={() => props.history.push("/dashboard/")}
        />
        <span>{message}</span>
      </DndProvider>
    );
  }
}

export default SortAreas;
