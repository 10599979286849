import React from "react";
import ColorPicker from "../../utils/ColorPicker";
import ImageUploader from "./ImageUploader";
const isEmpty = require("is-empty");

function RSSProperties(props) {
  const styleOptions = [
    "Main Content",
    "Main Content (No Transition)",
    "Bottom Content",
    "Get Content From Link",
  ];

  const itemsToDisplayOptions = [];
  for (let i = 1; i < 9; i++) {
    itemsToDisplayOptions.push(i);
  }

  const backgroundTypeSelect = (e) => {
    console.log("BackgroundTypeSelect");
    console.log(props.slide);
    let newSlide = { ...props.slide };
    switch (e.target.value) {
      case "Transparent":
        newSlide.rss_background_color = "";
        newSlide.rss_image_background = "";
        break;
      case "Colour":
        newSlide.rss_image_background = "";
        break;
      case "Image":
        newSlide.rss_background_color = "";
        break;
      default:
        break;
    }
    newSlide.rss_background_type = e.target.value;
    console.log(newSlide);
    props.setSlide(newSlide);
  };

  const colourChange = (color, e) => {
    props.updateSlide("rss_background_color", color.hex);
  };
  const textColourChange = (color, e) => {
    props.updateSlide("rss_text_colour", color.hex);
  };

  return (
    <div>
      <div className="addslidesectiontopbar">Background</div>
      <div className="addslidesection">
        <div>
          <label>
            <input
              id="rdoBackgroundType_Generic_0"
              type="radio"
              name="backgroundType"
              value="Transparent"
              checked={props.slide.rss_background_type === "Transparent"}
              onChange={backgroundTypeSelect}
            />
            Transparent - Uses your Ve-Media default background
          </label>
        </div>
        <div>
          <label>
            <input
              id="rdoBackgroundType_Generic_1"
              type="radio"
              name="backgroundType"
              value="Colour"
              checked={
                props.slide.rss_background_type === "Colour" ||
                !isEmpty(props.slide.rss_background_color)
              }
              onChange={backgroundTypeSelect}
            />
            Solid colour
          </label>
          {props.slide.rss_background_type === "Colour" ||
          !isEmpty(props.slide.rss_background_color) ? (
            <ColorPicker
              color={props.slide.rss_background_color}
              onChange={colourChange}
            />
          ) : null}
        </div>

        <div>
          <label>
            <input
              id="rdoBackgroundType_Generic_2"
              type="radio"
              name="backgroundType"
              value="Image"
              checked={
                props.slide.rss_background_type === "Image" ||
                !isEmpty(props.slide.rss_image_background)
              }
              onChange={backgroundTypeSelect}
            />
            Use a slide background
          </label>
          {props.slide.rss_background_type === "Image" ||
          props.slide.rss_image_background ? (
            <ImageUploader
              slide={props.slide}
              updateSlide={props.updateSlide}
              property="rss_image_background"
            ></ImageUploader>
          ) : null}
        </div>
      </div>
      <div className="addslidesectiontopbar">Style</div>
      <div className="addslidesection grid-fit-2">
        <label>Display Type:</label>
        <select
          value={props.slide.rss_type}
          onChange={(e) => {
            props.updateSlide("rss_type", e.target.value);
          }}
        >
          {styleOptions.map((item) => {
            return (
              <option key={item} value={item}>
                {item}
              </option>
            );
          })}
        </select>
        <label>Font colour:</label>
        <ColorPicker
          color={props.slide.rss_text_colour}
          onChange={textColourChange}
        />
      </div>

      <div className="addslidesectiontopbar">Content</div>
      <div className="addslidesection grid-fit-2">
        <label>RSS Feed:</label>
        <input
          type="text"
          value={props.slide.rss_url}
          onChange={(e) => props.updateSlide("rss_url", e.target.value)}
        />

        <label>Number of items to display:</label>
        <select
          value={props.slide.rss_items_to_display}
          onChange={(e) => {
            props.updateSlide("rss_items_to_display", e.target.value);
          }}
        >
          {itemsToDisplayOptions.map((item) => {
            return (
              <option key={item} value={item}>
                {item}
              </option>
            );
          })}
        </select>

        <label>Time between items:</label>
        <input
          type="text"
          value={props.slide.rss_transition_delay}
          onChange={(e) =>
            props.updateSlide("rss_transition_delay", e.target.value)
          }
        />
      </div>
    </div>
  );
}

export default RSSProperties;
