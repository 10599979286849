import React, { useState } from "react";
import SlideActions from "./Instructions/SlideActions";
import ChannelActions from "./Instructions/ChannelActions";
import ContentLibraryInstructions from "./Instructions/ContentLibraryInstructions";
import ArchiveInstructions from "./Instructions/ArchiveInstructions";
import ActivatingASlide from "./Instructions/ActivatingASlide";

function Instructions() {
  const [selected, setSelected] = useState("Slide");
  const linkStyles = {
    color: "#7B2822",
    display: "block",
    marginBottom: "10px",
    border: "none",
    background: "transparent",
    cursor: "pointer",
  };

  const selectedLinkStyles = { ...linkStyles, color: "#FF6C66" };
  return (
    <div>
      <table id="VeMediaInstructions">
        <tbody>
          <tr>
            <td className="left" style={{ verticalAlign: "top" }}>
              <input
                type="button"
                onClick={() => {
                  setSelected("Slide");
                }}
                style={selected === "Slide" ? selectedLinkStyles : linkStyles}
                value="Adding a Slide"
              />

              <input
                type="button"
                onClick={() => {
                  setSelected("ActivatingASlide");
                }}
                style={
                  selected === "ActivatingASlide"
                    ? selectedLinkStyles
                    : linkStyles
                }
                value="Activating a Slide"
              />

              <input
                type="button"
                onClick={() => {
                  setSelected("ControlPanel");
                }}
                style={
                  selected === "ControlPanel" ? selectedLinkStyles : linkStyles
                }
                value="Control Panel"
              />

              <input
                type="button"
                onClick={() => {
                  setSelected("SlideActions");
                }}
                style={
                  selected === "SlideActions" ? selectedLinkStyles : linkStyles
                }
                value="Slide Actions"
              />

              <input
                type="button"
                onClick={() => {
                  setSelected("ChannelActions");
                }}
                style={
                  selected === "ChannelActions"
                    ? selectedLinkStyles
                    : linkStyles
                }
                value="Channel Actions"
              />

              <input
                type="button"
                onClick={() => {
                  setSelected("Archive");
                }}
                style={selected === "Archive" ? selectedLinkStyles : linkStyles}
                value="Archive"
              />

              <input
                type="button"
                onClick={() => {
                  setSelected("ContentLibrary");
                }}
                style={
                  selected === "ContentLibrary"
                    ? selectedLinkStyles
                    : linkStyles
                }
                value="Content Library"
              />

              <input
                type="button"
                onClick={() => {
                  setSelected("PlayerSetupWin10");
                }}
                style={
                  selected === "PlayerSetupWin10"
                    ? selectedLinkStyles
                    : linkStyles
                }
                value="Player Setup (Windows 10 - NOTE: This Information for IT Department)"
              />
            </td>
            <td className="right" style={{ verticalAlign: "top" }}>
              <div style={{ display: selected === "Slide" ? "block" : "none" }}>
                <h2>Adding a slide</h2>
                <ul>
                  <li>
                    Main screen - make sure{" "}
                    <strong>"area" is set to the screen</strong> you wish to add
                    the slide to
                  </li>
                  <li>
                    Right side of screen - <strong>choose Create slide</strong>
                  </li>
                  <li>Choose a slide type - new screen opens</li>
                  <li>
                    Add the <strong>Title &amp; Duration</strong> - time you
                    want it to hold the slide on the screen in seconds, and
                    <strong> check always display</strong> if there are no start
                    and end dates.
                  </li>
                  <li>
                    If you want this slide to start at a later date, and end on
                    a certain day, <strong>do not check always display</strong>{" "}
                    and enter your dates.
                  </li>
                  <li>
                    <strong>Always display after start date</strong> is for when
                    you have a set date for the slide to begin, but no date for
                    it to end, you will check this box and enter start date.
                  </li>
                  <li>
                    Fill in the fields specific to the type of slide you are
                    creating. Specific help per slide type may be added as it is
                    needed.
                  </li>
                  <li>
                    <strong>Save slide and go back</strong> - to save and return
                    to the main screen
                  </li>
                  <li>
                    <strong>Save slide and add another</strong> to add another
                    slide of the same type. All information from the previous
                    slide will be kept in order to save you time when creating
                    multiple similar slides.
                  </li>
                </ul>
                <p>
                  If you're going to use an image from the content library: When
                  you go into the content library and pick an image you will see
                  a new button "Create Slide". If you click that it will start
                  creating a slide with the title and image already filled out
                  based on the chosen content library image.
                </p>
              </div>
              <div
                style={{
                  display: selected === "ControlPanel" ? "block" : "none",
                }}
              >
                <h2>Player Status</h2>
                <p>
                  Here you can see the last time each player refreshed and send
                  a command to refresh a player. In the "Status" column:
                  <ul>
                    <li>
                      <strong>Good</strong> means it has refreshed in the past
                      48 hours.
                    </li>
                    <li>
                      <strong>Warning</strong> means the last refresh was 48-72
                      hours ago.
                    </li>
                    <li>
                      <strong>Failed</strong> means the last refresh was over 72
                      hours ago.
                    </li>
                  </ul>
                  These cutoff numbers are arbitrary but hopefully help you to
                  quickly get an idea of whether the players are displaying
                  up-to-date content, while the "Last Updated" column tells you
                  exactly when it last refreshed.
                </p>
              </div>
              <div
                style={{
                  display: selected === "PlayerSetupWin10" ? "block" : "none",
                }}
              >
                <h2>Player Setup (Windows 10)</h2>
                <ul>
                  <li>
                    If the PC is being used for the first time, go through the
                    initial setup process as prompted until you get to the
                    internet step. Say you don't have internet for now / leave
                    the Ethernet cable unplugged so you aren't forced to use a
                    microsoft account. Create an account with no password so you
                    can log in automatically then say no to a bunch of features
                    you don't need.
                  </li>
                  <li>
                    When you get to the desktop, set up the internet by plugging
                    in the Ethernet cable or for wifi press Windows+I then click
                    "Network & Internet" then "Show available networks".
                  </li>
                  <li>
                    If the time display in the bottom right is incorrect, right
                    click it, select adjust date/time, and set the time zone.
                  </li>
                  <li>
                    <strong>Set active hours:</strong> Press Windows+I and
                    search the settings page for "Windows update settings"
                    (under Update & Security if the search doesn't work) Click
                    change active hours and set the start/end time during which
                    you do not want Windows to update.
                  </li>
                  <li>
                    <strong>Disable all Notifications:</strong> Press Windows+I
                    and search the settings page for "Notifications and actions
                    settings" (under System). Turn "Get notifications from apps
                    and other senders" off (and it wouldn't hurt to turn off
                    everything else on the page).
                  </li>
                  <li>
                    Still in Settings, search for "Power and Sleep" (under
                    System) and turn both to never
                  </li>
                  <li>
                    Still on the above settings page, click "Additional power
                    settings" on the right, then "Choose what the power buttons
                    do" and make sure the Power button is set to Shut Down or
                    Restart (it won't automatically pass the home screen after
                    waking from sleep)
                  </li>
                  <li>
                    Still in Settings, search for "Sign-in options" and disable
                    "Use my sign-in info to automatically finish setting up...".
                    This should prevent an extra browser window from opening if
                    you restart with it still open.
                  </li>
                  <li>
                    Right click the taskbar, go to settings and turn on auto
                    hide.
                  </li>
                  <li>
                    Add kiosk mode browser to startup: Install your browser of
                    choice (or use Chrome as the instructions will assume you
                    do). Instructions for installing Chrome (including download
                    link) can be found{" "}
                    <a href="https://support.google.com/chrome/answer/95346?co=GENIE.Platform%3DDesktop&hl=en-GB">
                      here
                    </a>
                  </li>
                  <li>
                    Press Windows+R and run "shell:startup". This opens the
                    startup folder.
                  </li>
                  <li>
                    Download and install{" "}
                    <a href="https://www.autohotkey.com/">AutoHotKey</a>.
                  </li>
                  <li>
                    Download{" "}
                    <a href="http://now-ic.com/scripts/chromeontop.ahk">
                      this script
                    </a>{" "}
                    and save it into the startup folder (right click the page
                    and "Save Page As" or similar if it doesn't automatically
                    download). If it saves as .txt, you should be able to get
                    around that by selecting "All Files" next to "Save as type",
                    then erase and re-add the "k" at the end of the file name.
                    Right click the script and edit it. On the line starting
                    with Run, replace "autohotkey.com" with your Ve-Media URL
                    (to find it, go to your main ve-media page, right click the
                    preview (eye) link next to the desired channel and copy the
                    link. You should remove the "?TestMode=true" part from the
                    end of the link. The "Default.aspx" part can also be skipped
                    to save time if you're typing it.). If the channel name has
                    spaces you must put the URL in quotation marks.
                  </li>
                  <li>
                    Press Windows+I, go to default apps, set the desired browser
                    as default (so it doesn't ask you to when you restart).
                  </li>
                </ul>
                <p>
                  Now when you restart, it should launch your browser in kiosk
                  mode automatically, and thanks to AutoHotKey, even if another
                  another window is opened for any reason, yours should always
                  stay on top.
                </p>
              </div>
              {selected === "SlideActions" ? (
                <SlideActions></SlideActions>
              ) : null}
              {selected === "ChannelActions" ? (
                <ChannelActions></ChannelActions>
              ) : null}

              {selected === "Archive" ? (
                <ArchiveInstructions></ArchiveInstructions>
              ) : null}
              {selected === "ContentLibrary" ? (
                <ContentLibraryInstructions></ContentLibraryInstructions>
              ) : null}
              {selected === "ActivatingASlide" ? (
                <ActivatingASlide></ActivatingASlide>
              ) : null}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default Instructions;
